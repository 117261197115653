import { EnumDescriptor } from './enum-descriptor';

export enum StoryPart {
  None = 0,
  Background = 10,
  Problem = 30,
  Reason = 40,
  Impact = 50,
  Title = 60,
  ClientName = 70,
  Value = 80,
  Solution = 90,
  EngagementFeedback = 100,
  BenefitsRealisation = 110,
  Approach = 120,
  Summary = 130,
  SharingConditions = 140,
}

// tslint:disable:max-line-length
export const storyPartDescriptor = new EnumDescriptor<{
  AllowMarkdown: boolean;
}>(StoryPart);
storyPartDescriptor[StoryPart.Background] = {
  Label: 'Background',
  Description:
    "<strong>What was the context behind the story?</strong> Provide an overview of the customer's business as well as their business context leading up to the initiative. Focus on giving a concise story that explains the key prerequisites.",
  Metadata: {
    AllowMarkdown: true,
  },
};
storyPartDescriptor[StoryPart.Problem] = {
  Label: 'Problem',
  Description:
    "<strong>What were the customer's symptoms?</strong> Provide a concise description of the customer identified issue(s) they faced and/or the opportunity/ies to improve their business.",
  Metadata: {
    AllowMarkdown: true,
  },
};
storyPartDescriptor[StoryPart.Reason] = {
  Label: 'Reason',
  Description:
    '<strong>What was our diagnosis of the reason behind the customer problem?</strong> This may or may not align with the originally identified problem.',
  Metadata: {
    AllowMarkdown: true,
  },
};
storyPartDescriptor[StoryPart.Impact] = {
  Label: 'Impact',
  Description:
    "<strong>What is the driver for change?</strong> Provide a concise description of how the customer's business is impacted by the identified issue(s) and/or would be impacted by the identified opportunity/ies.",
  Metadata: {
    AllowMarkdown: true,
  },
};
storyPartDescriptor[StoryPart.Title] = { Label: 'Title' };
storyPartDescriptor[StoryPart.ClientName] = {
  Label: 'Client Name',
};
storyPartDescriptor[StoryPart.Value] = { Label: 'Value' };
storyPartDescriptor[StoryPart.Solution] = {
  Label: 'Solution',
  Description:
    '<strong>How did we solve the problem?</strong> Provide details about the solution we provided to the customer from a business perspective and a technical perspective. This should be filled in or reviewed by the delivery team. <em>Ensure relevant technologies are tagged against the story.</em>',
  Metadata: {
    AllowMarkdown: true,
  },
};
storyPartDescriptor[StoryPart.EngagementFeedback] = {
  Label: 'Engagement Feedback',
  Description:
    "<strong>What did the customer think of our solution?</strong> Describe the customer's satisfaction at engagement closure. Provide any stats or quotes that you have to hand.",
  Metadata: {
    AllowMarkdown: true,
  },
};
storyPartDescriptor[StoryPart.BenefitsRealisation] = {
  Label: 'Benefits realisation',
  Description:
    '<strong>Did we help the customer realise expected benefits?</strong> A few months after delivery (ideally 3+ months) circle back with the customer and extract any metrics, quotes or other feedback about the business impact of the solution, particularly against any originally expected benefits. It amplifies the story dramatically by exposing the business benefits with concrete metrics and numbers wherever possible.',
  Metadata: {
    AllowMarkdown: true,
  },
};
storyPartDescriptor[StoryPart.Approach] = {
  Label: 'Sales Approach',
  Description:
    'This section describes how we approached the customer, what the initial proposal was and the story of how we won the engagement.',
  Metadata: {
    AllowMarkdown: true,
  },
};
storyPartDescriptor[StoryPart.Summary] = {
  Label: 'Summary',
  Description:
    '<strong>What is the elevator pitch of the story?</strong> Tell the summary of the story to date (this should evolve over time) succinctly in your own words, include any impactful comments or quotes that really sell the story - this is how you quickly get attention from your colleagues and inspire them to look in more detail.',
  Metadata: {
    AllowMarkdown: false,
  },
};
storyPartDescriptor[StoryPart.SharingConditions] = {
  Label: 'Sharing Conditions',
  Description: 'Are there any conditions that we need to adhere to when sharing this story?',
};
// tslint:enable:max-line-length
