import { EnumDescriptor } from './enum-descriptor';

export enum StoryPhase {
  Sale = 10,
  Deliver = 20,
  Measure = 30,
  Completed = 40,
}

export const storyPhaseDescriptor = new EnumDescriptor(StoryPhase);
storyPhaseDescriptor[StoryPhase.Sale] = {
  Label: 'In Sale',
};
storyPhaseDescriptor[StoryPhase.Deliver] = {
  Label: 'In Delivery',
};
storyPhaseDescriptor[StoryPhase.Measure] = {
  Label: 'In Measurement',
};
