import { HttpParams } from '@angular/common/http';
import { StorySearchResult } from './../../../../shared/models/story-search-result';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { PagedResult } from '../../../../shared/models/page-result';
import { User } from '../../../../shared/models/user';
import { ActivatedRoute } from '@angular/router';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';

const PagingSeperator = ';';

@Component({
  selector: 'app-story-search-result-list',
  templateUrl: './story-search-result-list.component.html',
  styleUrls: ['./story-search-result-list.component.scss'],
})
export class StorySearchResultListComponent implements OnInit {
  currentUser: User;
  @Input() storySearchResult: PagedResult<StorySearchResult>;
  @Input() isLoading;
  @Input() resultCount: number;
  @Input() pageSize: number;
  @Input() pageIndex: number;
  @Input() pageSizeOptions: number[];

  @Output() pageEvent: EventEmitter<PageEvent> = new EventEmitter();

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {}

  public page($event) {
    this.pageEvent.emit($event);
  }
}
