import { finalize } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { StorySummary } from '../../../shared/models/story-summary';
import { StoriesService } from '../../services/stories.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DATE_TIME_FORMAT } from 'src/app/shared/date-formats-datepipe';

const NUMBER_OF_STORIES = 4;

@Component({
  selector: 'app-my-own-stories',
  templateUrl: './my-own-stories.component.html',
  styleUrls: ['./my-own-stories.component.scss'],
})
export class MyOwnStoriesComponent implements OnInit, OnDestroy {
  readonly DATE_TIME_FORMAT: string = DATE_TIME_FORMAT;

  loading = true;
  stories: StorySummary[];
  deleteSubscribtion: Subscription;

  constructor(private storiesService: StoriesService, private router: Router) {
    this.deleteSubscribtion = this.storiesService.storyDeleted$.subscribe(() => {
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.stories = new Array<StorySummary>();
    this.storiesService
      .getMyLastUpdatedStories(NUMBER_OF_STORIES)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((stories: StorySummary[]) => {
        if (stories && stories.length > 0) {
          this.stories = stories.sort(
            (a: StorySummary, b: StorySummary) => b.lastEdited.getTime() - a.lastEdited.getTime()
          );
        }
      });
  }

  onStoryClick(id: string) {
    this.router.navigate(['/story', id]);
  }

  ngOnDestroy() {
    this.deleteSubscribtion.unsubscribe();
  }
}
