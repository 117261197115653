import { Component, OnInit, Inject } from '@angular/core';
import { UpdateStoryPart } from '../../../shared/models/update-story-part';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { StoriesService } from '../../services/stories.service';
import { UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn } from '@angular/forms';
import { storyPartMaxCharacterLimit } from '../../../shared/constants';
import { StoryPart, storyPartDescriptor } from 'src/app/shared/models/story-part';
import markdownIt from 'markdown-it';
import 'quill-emoji/dist/quill-emoji.js';

export interface UpdateStoryPartDialogData {
  id: string;
  storyPart: StoryPart;
  placeholder: string;
  value: string;
  title: string;
}

@Component({
  selector: 'app-edit-story-part-dialog',
  templateUrl: './edit-story-part-dialog.component.html',
  styleUrls: ['./edit-story-part-dialog.component.scss'],
})
export class EditStoryPartDialogComponent implements OnInit {
  storyPartCharacterLimit = storyPartMaxCharacterLimit;

  inputTextArea: UntypedFormControl;
  storyPartForm: UntypedFormGroup;
  md = new markdownIt();
  allowMarkdown = storyPartDescriptor[this.editStoryPartData.storyPart].Metadata
    ? storyPartDescriptor[this.editStoryPartData.storyPart].Metadata.AllowMarkdown
    : false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public editStoryPartData: UpdateStoryPartDialogData,
    private dialogRef: MatDialogRef<EditStoryPartDialogComponent>,
    private storiesService: StoriesService
  ) {
    const validators = [Validators.maxLength(this.storyPartCharacterLimit)];

    // #15985: If the story part value is currently empty, don't allow an empty value.
    if (!this.editStoryPartData.value) {
      validators.push(Validators.required);
    }

    this.inputTextArea = new UntypedFormControl('', validators);
    this.inputTextArea.setValue(
      this.allowMarkdown && this.editStoryPartData.value
        ? this.md.render(this.editStoryPartData.value)
        : this.editStoryPartData.value
    );

    this.storyPartForm = new UntypedFormGroup({ inputTextArea: this.inputTextArea });
  }

  getInputErrorMessage() {
    if (this.inputTextArea.hasError('maxlength')) {
      const error = this.inputTextArea.getError('maxlength');
      return `Your input has a length of ${error.actualLength} characters which exceeds the maximum of ${error.requiredLength} characters.`;
    }
    if (this.inputTextArea.getError('required')) {
      return `${this.editStoryPartData.title} is required.`;
    }
  }

  ngOnInit() {}

  onCancel(): void {
    this.dialogRef.close();
  }

  updatePart(): void {
    const updateStoryPart = {
      id: this.editStoryPartData.id,
      storyPart: this.editStoryPartData.storyPart,
      value: this.inputTextArea.value,
      storeAsMarkdown: this.allowMarkdown,
    } as UpdateStoryPart;

    this.storiesService.updateStoryPart(updateStoryPart).subscribe(() => this.dialogRef.close());
  }
}
