import { StoryPart } from './story-part';
import { User, UserMapper } from './user';
import { Injectable } from '@angular/core';
import { IMapper } from '../mapper';
import { IRevisionDto } from '../api/revision-dto';

export class Revision {
  id: string;
  storyPart: StoryPart;
  value: string;
  restoredFrom?: Revision;
  storyId: string;
  dateCreated: Date;
  createdBy: User;
}

@Injectable({ providedIn: 'root' })
export class RevisionMapper implements IMapper<IRevisionDto, Revision> {
  constructor(private userMapper: UserMapper) {}
  map(source: IRevisionDto): Revision {
    const result = new Revision();
    result.id = source.id;
    result.storyPart = source.storyPart;
    result.value = source.value;
    result.storyId = source.storyId;
    result.dateCreated = new Date(source.dateCreated);
    result.createdBy = this.userMapper.map(source.createdBy);
    if (source.restoredFrom) {
      result.restoredFrom = this.map(source.restoredFrom);
    }
    return result;
  }
}
