<div class="deleteDialogWrapper">
  <div class="dialogTitle">
    <h3 mat-dialog-title>Delete draft story</h3>
    <button mat-button class="storyDialogButton" (click)="onCancel()"><mat-icon>clear</mat-icon></button>
  </div>
  <div mat-dialog-content>
    You are about to delete a draft story. By doing this you will lose all content added to this story, would you like
    to proceed?
  </div>
  <div id="dialogButtonsContainer" mat-dialog-actions>
    <button class="storyDialogButton" mat-button color="primary" (click)="onDelete()">
      <span>PROCEED</span>
    </button>
    <button class="storyDialogButton" mat-button (click)="onCancel()">
      <span>CANCEL</span>
    </button>
  </div>
</div>
