<div id="container">
  <div id="title">
    <h3 mat-dialog-title>Delete File</h3>
    <button mat-button (click)="onCancelClicked()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <mat-dialog-content id="content">
    <p>This file will be permanently deleted. Would you like to proceed?</p>
    <div [hidden]="!showPublishWarning()" class="warning">
      <mat-icon>warning</mat-icon>
      <p>This story is published.</p>
    </div>
    <div [hidden]="!showConsentWarning()" class="warning">
      <mat-icon>warning</mat-icon>
      <p>
        The attachment you're about to delete was uploaded as evidence of the customer's consent to publish this story.
      </p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions id="buttons">
    <button mat-button color="primary" (click)="onProceedClicked()">
      <span>PROCEED</span>
    </button>
    <button mat-button (click)="onCancelClicked()">
      <span>CANCEL</span>
    </button>
  </mat-dialog-actions>
</div>
