import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ReleaseNote } from 'src/app/shared/models/release-note';
import { User } from 'src/app/shared/models/user';
import { ReleaseNotesService } from '../../services/release-note.service';
import { ClientMessageService } from 'src/app/shared/services/client-message.service';

@Component({
  selector: 'app-add-release-note',
  templateUrl: './add-release-note.component.html',
  styleUrls: ['./add-release-note.component.scss'],
})
export class AddReleaseNoteComponent {
  public currentUser$: Observable<User>;
  public releaseNote: ReleaseNote;

  constructor(
    private releaseNotesService: ReleaseNotesService,
    private messageService: ClientMessageService,
    private titleService: Title
  ) {
    this.titleService.setTitle('Add Release Notes');
  }

  onSubmit(releaseNote: ReleaseNote) {
    this.releaseNotesService.createReleaseNote(releaseNote).subscribe((result: string | {}) => {
      this.messageService.showClientInfoMessage(
        `The release note '${releaseNote.title}' has been created successfully`
      );
      this.releaseNote = null;
    });
  }
}
