// This code allows using the ngx-quill editor within angular material.
// The code is taken from: https://github.com/KillerCodeMonkey/ngx-quill-example

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';

import { QuillModule } from 'ngx-quill';
import { MatQuillComponent } from './mat-quill';

@NgModule({
  declarations: [MatQuillComponent],
  exports: [MatQuillComponent],
  imports: [BrowserModule, FormsModule, ReactiveFormsModule, QuillModule, MatFormFieldModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MatQuillModule {}
