<div class="dialogTitle" [class.wait]="isUploading">
  <!-- Attachments links -->
  <h3 mat-dialog-title>Edit Attachments</h3>
  <button mat-button class="releaseNoteDialogButton" (click)="cancel()"><mat-icon>close</mat-icon></button>
</div>

<div class="scrollable">
  <input type="file" #file style="display: none" (change)="onFilesAdded()" />

  <table>
    <tr *ngFor="let attachment of attachments">
      <td>
        <app-icon
          [title]="attachmentTypeDescriptor[attachment.attachmentType].Label"
          [icon]="attachmentTypeDescriptor[attachment.attachmentType].Metadata.Icon"
        >
        </app-icon>
      </td>
      <td>
        <mat-form-field class="field">
          <input
            matInput
            [placeholder]="attachmentTypeDescriptor[attachment.attachmentType].Label"
            [(ngModel)]="attachment.label"
            [readonly]="isUploading"
            (input)="onChange()"
          />
        </mat-form-field>
      </td>
      <td>
        <button
          *ngIf="attachment.id === null && attachment.name === null"
          mat-button
          color="primary"
          (click)="addFiles(attachment)"
        >
          Add File
        </button>
        <div class="attachment-name" *ngIf="attachment.id === null && attachment.name !== null">
          <span class="pad-right">{{ attachment.name }}</span>
        </div>
        <div class="attachment-name" *ngIf="attachment.id !== null">
          <a href="javascript:;" (click)="downloadAttachment(attachment)" class="pad-right">{{ attachment.name }}</a>
        </div>
      </td>
      <td>
        <button
          *ngIf="!isUploading"
          class="releaseNoteDialogButton"
          mat-button
          color="primary"
          (click)="deleteAttachment(attachment)"
        >
          <span>DELETE</span>
        </button>
        <mat-spinner
          *ngIf="isUploading && attachment.id === null"
          [diameter]="20"
          class="padd-right spinner"
        ></mat-spinner>
      </td>
    </tr>
  </table>
</div>

<div class="buttons" mat-dialog-actions>
  <div mat-dialog-actions>
    <!-- add -->
    <button mat-fab color="primary" aria-label="Add Link" [matMenuTriggerFor]="menu" [disabled]="isUploading">
      <mat-icon class="add-button">add</mat-icon>
    </button>

    <mat-menu #menu="matMenu" xPosition="before" yPosition="above">
      <button *ngFor="let t of types" mat-menu-item (click)="addAttachment(t)" [disabled]="isUploading">
        <app-icon
          [title]="attachmentTypeDescriptor[t].Label"
          [icon]="attachmentTypeDescriptor[t].Metadata.Icon"
        ></app-icon>
        Upload {{ attachmentTypeDescriptor[t].Label }}
      </button>
    </mat-menu>
  </div>

  <div>
    <!-- save -->
    <button
      class="releaseNoteDialogButton"
      mat-button
      color="primary"
      [disabled]="isUploading || !hasChanges || !isValid"
      (click)="save()"
    >
      <span>SAVE</span>
    </button>

    <!-- cancel -->
    <button class="releaseNoteDialogButton" mat-button [disabled]="isUploading" (click)="cancel()">
      <span>CANCEL</span>
    </button>
  </div>
</div>
