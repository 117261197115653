<div>
  <form>
    <mat-form-field class="storyField">
      <mat-select [(value)]="selectedStoryPhase" (selectionChange)="onSelectionChange()">
        <mat-option *ngFor="let key of storyPhaseDescriptor.Keys" [value]="key">
          {{ storyPhaseDescriptor[key].Label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="storyField" *ngIf="deliveryStarted">
      <input
        matInput
        [matDatepicker]="picker"
        placeholder="Sale Closed Date"
        (dateChange)="saleClosedDateChanged($event)"
        [value]="selectedSaleClosedDate"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="storyField" *ngIf="deliveryStarted">
      <input
        matInput
        [matDatepicker]="picker"
        placeholder="Delivery Start Date"
        [value]="selectedDeliveryStartDate"
        (dateChange)="deliveryStartDateChanged($event)"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="storyField" *ngIf="deliveryCompleted">
      <input
        matInput
        [matDatepicker]="picker"
        placeholder="Delivery Completion Date"
        (dateChange)="deliveryCompletedDateChanged($event)"
        [value]="selectedDeliveryCompletedDate"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="storyField" *ngIf="deliveryStarted">
      <input
        matInput
        [matDatepicker]="picker"
        placeholder="Gone to Production Date"
        (dateChange)="goneToProductionDateChanged($event)"
        [value]="selectedGoneToProductionDate"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <div class="highlight" *ngIf="showVisibilityWarningMsg">
      Changing the story phase to 'In Sale' or 'In Delivery', will accordingly change the Visibility to Internal.
    </div>

    <div class="highlight" *ngIf="showValidationWarningMsg">
      <mat-icon color="warn" matTooltip="{{ this.story.errorMessages.join('\n') }}">error</mat-icon>
      You can't save this (published) story as this phase until the errors are fixed.
    </div>
  </form>
</div>
<div id="dialogButtonsContainer" mat-dialog-actions>
  <button class="releaseNoteDialogButton" mat-button color="primary" [disabled]="saveDisabled" (click)="updatePhase()">
    <span>SAVE</span>
  </button>
  <button class="releaseNoteDialogButton" mat-button cdkFocusInitial (click)="cancel()">
    <span>CANCEL</span>
  </button>
</div>
