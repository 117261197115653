<div class="tagEditcontainer">
  <div class="tagEditContent">
    <p>
      Good tags will make your story searchable. Add tags for location, technology or any other relevant information.
    </p>
    <div mat-dialog-content>
      <app-story-tag [story]="story" (changeHappened)="tagsChanged($event)" #storyTags></app-story-tag>
    </div>
  </div>
  <div class="tagEditfooter">
    <div class="updateCancelButtons">
      <button mat-button color="primary" [disabled]="!hasTagChanges" (click)="updateTags()">
        <span>SAVE</span>
      </button>
      <button mat-button (click)="cancelTags()">
        <span>CANCEL</span>
      </button>
    </div>
  </div>
</div>
