import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UpdateOwner } from '../../../shared/models/update-owner';
import { StoriesService } from '../../services/stories.service';

@Component({
  selector: 'app-update-owner-dialog',
  templateUrl: './update-owner-dialog.component.html',
  styleUrls: ['./update-owner-dialog.component.scss'],
})
export class UpdateOwnerDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) private updateOwnerTitle: UpdateOwner,
    private dialogRef: MatDialogRef<UpdateOwnerDialogComponent>,
    private storiesService: StoriesService
  ) {}

  ngOnInit() {}

  proceed() {
    this.storiesService
      .updateOwner(this.updateOwnerTitle.storyId, this.updateOwnerTitle.selectedOwnerId)
      .subscribe(() => this.dialogRef.close());
  }

  cancel() {
    this.dialogRef.close();
  }
}
