<div class="section-title">
  <h2>My recent stories</h2>
</div>
<mat-grid-list cols="4" gutterSize="24" rowHeight="250px" *ngIf="!loading && stories.length > 0">
  <mat-grid-tile class="tile" *ngFor="let story of stories">
    <mat-card class="my-story-card" (click)="onStoryClick(story.id)">
      <mat-card-title class="story-title">{{ story.title }}</mat-card-title>
      <mat-card-footer>
        <div id="avatar" mat-card-avatar>{{ story.owner.initials }}</div>
        <div class="avatar-text">
          {{ story.lastEditedBy.fullName }}<br />
          @ {{ story.lastEdited | date: DATE_TIME_FORMAT }}
        </div>
      </mat-card-footer>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>
<div *ngIf="!loading && !(stories.length > 0)"><h4>You currently do not have any stories.</h4></div>
<mat-spinner *ngIf="loading"></mat-spinner>
