import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CreateReleaseNoteButtonComponent } from './components/create-release-note-button/create-release-note-button.component';
import { NewOrEditReleaseNoteComponent } from './components/new-or-edit-release-note-dialog/new-or-edit-release-note-dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddReleaseNoteComponent } from './components/add-release-note/add-release-note.component';
import { ReleaseNoteEditorComponent } from './components/release-note-editor/release-note-editor.component';
import { ReleaseNotesListComponent } from './components/release-notes-list/release-notes-list.component';
import { ReleaseNotesComponent } from './components/release-notes/release-notes.component';
import { MaterialModule } from '../material.module';
import { EditReleaseNotesDialogComponent } from './components/edit-release-notes/edit-release-notes.dialog';

@NgModule({
  declarations: [
    CreateReleaseNoteButtonComponent,
    NewOrEditReleaseNoteComponent,
    AddReleaseNoteComponent,
    ReleaseNoteEditorComponent,
    ReleaseNotesListComponent,
    ReleaseNotesComponent,
    EditReleaseNotesDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserAnimationsModule,
    MaterialModule,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [CreateReleaseNoteButtonComponent],
})
export class AboutModule {}
