import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../configuration/configuration.service';
import { Observable } from 'rxjs';
import { User, UserMapper } from '../../models/user';
import { SkipInterceptor } from '../../skip-interceptor';
import { map } from 'rxjs/operators';
import { IUserDto } from '../../api/user-dto';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  baseApiUrl: string;
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
    private config: ConfigurationService,
    private userMapper: UserMapper,
    private skipInterceptor: SkipInterceptor
  ) {
    this.baseApiUrl = this.config.apiUrl + '/user';
  }

  getUser(): Observable<User> {
    return this.http
      .get<IUserDto>(this.baseApiUrl + '/me', { headers: this.headers })
      .pipe(map((item) => this.userMapper.map(item)));
  }

  getUserByEmail(email: string): Observable<User> {
    return this.http
      .get<IUserDto>(this.baseApiUrl + '?email=' + email, { headers: this.skipInterceptor.addSkipHeader(this.headers) })
      .pipe(map((item) => this.userMapper.map(item)));
  }
}
