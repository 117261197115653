<div class="section-title">
  <h2>Stories I contribute to</h2>
  <app-create-story-button></app-create-story-button>
</div>

<mat-spinner *ngIf="loading; else storiesTemplate"></mat-spinner>
<ng-template #storiesTemplate>
  <div class="stories-wrapper">
    <mat-toolbar class="mat-elevation-z1">
      <mat-form-field class="search">
        <input
          matInput
          placeholder="Filter stories by title, client or contributors"
          (keyup)="onKeywordsUpdate($event.target.value)"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="In phase" [(ngModel)]="filter.phase" (selectionChange)="onFilterUpdate()">
          <mat-option [value]="null">Any</mat-option>
          <mat-option *ngFor="let phase of storyPhaseDescriptor.Keys" [value]="phase">{{
            storyPhaseDescriptor[phase].Label
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="In state" [(ngModel)]="filter.state" (selectionChange)="onFilterUpdate()">
          <mat-option [value]="null">Any</mat-option>
          <mat-option *ngFor="let state of storyStateDescriptor.Keys" [value]="state">{{
            storyStateDescriptor[state].Label
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-toolbar>

    <mat-table [dataSource]="stories" class="mat-elevation-z1">
      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.title }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="clientName">
        <mat-header-cell *matHeaderCellDef>Client</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.clientName }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="allContributors">
        <mat-header-cell *matHeaderCellDef>Contributors</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.allContributors }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="lastEdited">
        <mat-header-cell *matHeaderCellDef>Last edit</mat-header-cell>
        <mat-cell *matCellDef="let element"
          >{{ element.lastEditedBy.fullName }} @ {{ element.lastEdited | date: DATE_TIME_FORMAT }}</mat-cell
        >
      </ng-container>
      <ng-container matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" class="delete-icon">
          <span>
            <mat-icon
              *ngIf="showDelete(element) && canDelete(element)"
              matListIcon
              matTooltip="Delete Story"
              (click)="onStoryDelete(element.id); $event.stopPropagation()"
            >
              delete
            </mat-icon>
            <mat-icon
              *ngIf="showDelete(element) && !canDelete(element)"
              matListIcon
              matTooltip="This story is published and cannot be deleted. Return the story to draft to delete it."
              (click)="$event.stopPropagation()"
            >
              delete_forever
            </mat-icon>
          </span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" (click)="onStoryClick(row.id)"></mat-row>
    </mat-table>
  </div>
</ng-template>
