<div class="editTitleDialogWrapper">
  <div class="dialogTitle">
    <h3 mat-dialog-title>Edit Story Details</h3>
    <button mat-button class="storyDialogButton" (click)="onCancel()"><mat-icon>clear</mat-icon></button>
  </div>
  <div mat-dialog-content>
    <form [formGroup]="storyTitleForm">
      <mat-form-field class="storyField">
        <mat-label>Title</mat-label>
        <input matInput formControlName="title" cdkFocusInitial #storyTitle [maxlength]="storyTitleCharacterLimit" />
        <mat-hint align="end">{{ storyTitle.value?.length || 0 }} / {{ storyTitleCharacterLimit }}</mat-hint>
        <span matPrefix>&nbsp;</span>
      </mat-form-field>
      <mat-form-field class="storyField">
        <mat-label>Client</mat-label>
        <input matInput formControlName="clientName" [maxlength]="storyTitleCharacterLimit" #clientName />
        <mat-hint align="end">{{ clientName.value?.length || 0 }} / {{ storyTitleCharacterLimit }}</mat-hint>
        <span matPrefix>&nbsp;</span>
      </mat-form-field>
      <mat-form-field class="storyFieldShort" hintLabel="Use numbers only">
        <mat-label>Value</mat-label>
        <input matInput formControlName="value" type="number" />
        <span matPrefix>$&nbsp;</span>
      </mat-form-field>
    </form>
  </div>
  <div id="dialogButtonsContainer" mat-dialog-actions>
    <button
      class="storyDialogButton"
      mat-button
      color="primary"
      [disabled]="!storyTitleForm.valid"
      (click)="updateStoryTitle()"
    >
      <span>SAVE</span>
    </button>
    <button class="storyDialogButton" mat-button (click)="onCancel()">
      <span>CANCEL</span>
    </button>
  </div>
</div>
