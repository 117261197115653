import { Injectable } from '@angular/core';
import { IMapper } from '../mapper';
import { IStorySearchResultDto } from '../api/story-search-result-dto';
import { StorySearchResult, StorySearchResultMapper } from './story-search-result';
import { IPagedResultDto } from '../api/paged-result-dto';

export class PagedResult<T> {
  currentPage: number;
  pageCount: number;
  pageSize: number;
  rowCount: number;
  results?: T[];
}

@Injectable({ providedIn: 'root' })
export class StorySearchPagedResultMapper
  implements IMapper<IPagedResultDto<IStorySearchResultDto>, PagedResult<StorySearchResult>> {
  constructor(private storySearchResultMapper: StorySearchResultMapper) {}

  map(item: IPagedResultDto<IStorySearchResultDto>): PagedResult<StorySearchResult> {
    const result = new PagedResult<StorySearchResult>();
    result.currentPage = item.currentPage;
    result.pageCount = item.pageCount;
    result.pageSize = item.pageSize;
    result.rowCount = item.rowCount;
    result.results = item.results.map((r) => this.storySearchResultMapper.map(r));
    return result;
  }
}
