import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

@Component({
  selector: 'app-card-item',
  templateUrl: './card-item.component.html',
  styleUrls: ['./card-item.component.scss'],
})
export class CardItemComponent implements OnInit {
  @Input()
  image: string;

  @Input()
  icon: IconDefinition;

  @Input()
  iconTitle: string;

  @Input()
  title: string;

  @Input()
  subtitle: string;

  @Input()
  loading = false;

  @Output()
  action = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
