import { IMapper } from '../mapper';
import { Injectable } from '@angular/core';
import { ITagDto } from '../api/tag-dto';

export class Tag {
  value: string;
  isLegacy: boolean;
}

@Injectable({ providedIn: 'root' })
export class TagMapper implements IMapper<ITagDto, Tag> {
  map(item: ITagDto): Tag {
    const result = new Tag();
    result.value = item.value;
    result.isLegacy = item.isLegacy;
    return result;
  }
}
