import { EnumDescriptor } from './enum-descriptor';

export enum StoryCaseStudyNomination {
  NotWilling = 1,
  Unsure = 2,
  Category1 = 3,
  Category2 = 4,
  Category3 = 5,
}

export const storyCaseStudyNominationDescriptor = new EnumDescriptor(StoryCaseStudyNomination);

storyCaseStudyNominationDescriptor[StoryCaseStudyNomination.Unsure] = {
  Label: "Unsure - haven't checked yet",
  Description: '',
};

storyCaseStudyNominationDescriptor[StoryCaseStudyNomination.NotWilling] = {
  Label: 'Not willing',
  Description: '',
};

storyCaseStudyNominationDescriptor[StoryCaseStudyNomination.Category1] = {
  Label: 'Category 1 – Closed User-Group Reference',
  Description: '',
};

storyCaseStudyNominationDescriptor[StoryCaseStudyNomination.Category2] = {
  Label: 'Category 2 – External Reference',
  Description: '',
};

storyCaseStudyNominationDescriptor[StoryCaseStudyNomination.Category3] = {
  Label: '​Category 3 - Case Study',
  Description: '',
};
