<mat-spinner class="view-story-spinner" *ngIf="loading"></mat-spinner>
<div class="parent" *ngIf="story && !loading">
  <div class="left">
    <div id="top-button-container">
      <button
        mat-button
        color="primary"
        (click)="backClicked()"
        *ngIf="navigateBack"
        title="{{ searchText !== '' ? getBackButtonText(true) : '' }}"
        [ngClass]="searchText !== '' ? 'back-btn' : ''"
        style="padding-left: 0px"
      >
        <span> <mat-icon>arrow_back</mat-icon> {{ getBackButtonText() }} </span>
      </button>

      <button
        id="story-readonly-edit-button"
        mat-button
        color="primary"
        (click)="onSwitchReadOnly()"
        *ngIf="allowSwitchReadOnly"
        fxShow.lt-sm="false"
      >
        <span *ngIf="isInReadMode"> <mat-icon>edit</mat-icon> Edit Mode </span>
        <span *ngIf="!isInReadMode"> <mat-icon>done</mat-icon> Done </span>
      </button>
    </div>
    <mat-card
      [id]="isInReadMode || 'headerCard'"
      [ngClass]="{ 'mat-card': !isInReadMode, 'readonly-header': isInReadMode }"
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="space-between"
    >
      <mat-spinner id="spinner" diameter="25" *ngIf="imageIsLoading"></mat-spinner>

      <div
        id="featured-image-container"
        *ngIf="featuredImage && !imageIsLoading"
        [style.backgroundImage]="featuredImageUrl"
      >
        <app-story-visibility-badge *ngIf="isInReadMode" [storyVisibility]="story.visibility">
        </app-story-visibility-badge>
        <div class="overlay" *ngIf="!isInReadMode" (click)="imageClick()">
          <h2>Upload Image</h2>
          <p>Company logo or stock image</p>
          <p>Minimum dimension: 548x316</p>
          <p>(Will crop and resize automatically for you)</p>
          <p>6 MB limit</p>
          <input type="file" #file style="display: none" (change)="onFeatureImageAdded()" accept=".png, .jpg" />
        </div>
      </div>
      <div
        id="featured-image-container"
        *ngIf="!featuredImage && !imageIsLoading"
        [style.backgroundImage]="'url(' + defaultImage + ')'"
      >
        <app-story-visibility-badge *ngIf="isInReadMode" [storyVisibility]="story.visibility">
        </app-story-visibility-badge>
        <mat-icon color="warn" *ngIf="canEdit" matTooltip="Story image is required" class="error">error</mat-icon>
        <div class="overlay" *ngIf="canEdit" (click)="imageClick()">
          <mat-icon color="warn" *ngIf="canEdit" matTooltip="Story image is required" class="error">error</mat-icon>
          <h2>Upload Image</h2>
          <p>Company logo or stock image</p>
          <p>Minimum dimension: 548x316</p>
          <p>(Will crop and resize automatically for you)</p>
          <p>6 MB limit</p>
          <input type="file" #file style="display: none" (change)="onFeatureImageAdded()" accept=".png, .jpg, .jpeg" />
        </div>
      </div>
      <div id="header-card-info">
        <div id="header-info-wrapper">
          <mat-card-title class="title">{{ story.title }}</mat-card-title>
          <mat-card-subtitle class="subtitle">Client: {{ story.clientName }}</mat-card-subtitle>
          <div id="value-row">
            <mat-card-subtitle class="subtitle">Value: {{ story.value | currency }}</mat-card-subtitle>
            <div id="like-share-container" *ngIf="isInReadMode">
              <div class="share-container">
                <button
                  mat-stroked-button
                  color="primary"
                  (click)="copyLink()"
                  [matTooltip]="toolTipText"
                  [matTooltipDisabled]="!shouldShowToolTip"
                >
                  <mat-icon class="share-icon">share</mat-icon>
                  Share
                  <mat-icon color="warn" *ngIf="shouldShowToolTip">error</mat-icon>
                </button>
              </div>
              <div class="likes">
                <div [hidden]="!likeSpinner">
                  <mat-spinner diameter="30"></mat-spinner>
                </div>
                <mat-icon
                  id="like-button"
                  (click)="changeLike(story.id, !story.hasCurrentUserLiked)"
                  [ngClass]="{ selected: story.hasCurrentUserLiked }"
                  *ngIf="!likeSpinner"
                  >thumb_up
                </mat-icon>
                <div [hidden]="story.numberOfLikes == 0">
                  <a href="javascript:void(0)" (click)="openLikesDialog(story.id)">{{ story.numberOfLikes }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="header-edit-icon" *ngIf="canEdit" (click)="editStoryTitle()">
          <mat-icon matTooltip="Edit">create</mat-icon>
        </div>
        <div class="header-edit-icon">
          <mat-icon
            *ngIf="showDelete() && canDelete()"
            matListIcon
            matTooltip="Delete Story"
            (click)="onStoryDelete(); $event.stopPropagation()"
          >
            delete
          </mat-icon>
          <mat-icon
            *ngIf="showDelete() && !canDelete()"
            matListIcon
            matTooltip="This story is published and cannot be deleted. Return the story to draft to delete it."
            (click)="$event.stopPropagation()"
          >
            delete forever
          </mat-icon>
        </div>
      </div>
    </mat-card>
    <mat-card *ngIf="isInReadMode && story.sharingConditions">
      <div class="sharing-conditions-warning">
        <mat-icon color="warn" *ngIf="shouldShowToolTip">error</mat-icon>
        Please ensure you follow the sharing conditions for this story:
      </div>
      <mat-card-content>{{ story.sharingConditions }}</mat-card-content>
    </mat-card>

    <br />
    <app-story-strength [story]="story" *ngIf="canEdit"></app-story-strength>
    <br />
    <mat-expansion-panel [expanded]="true" class="details-expansion-panel" [ngClass]="{ 'is-expanded': true }">
      <app-view-story-part
        [story]="story"
        [storyPart]="StoryPart.Summary"
        [isReadOnly]="isInReadMode"
        [canEdit]="canEdit"
        (afterDialogClosed)="fetchStoryFromApi($event)"
      ></app-view-story-part>
    </mat-expansion-panel>
    <br />
    <!-- case study / sharing (read mode) -->
    <mat-expansion-panel
      [expanded]="true"
      class="details-expansion-panel"
      [ngClass]="{ 'is-expanded': true }"
      *ngIf="(isInReadMode && story.hasAttachmentsOrEmbeddedLinks) || !isInReadMode"
    >
      <!-- header -->
      <mat-expansion-panel-header class="panel-header">
        <mat-panel-title class="expansion-header">Supporting Material</mat-panel-title>
      </mat-expansion-panel-header>

      <!-- content -->
      <div class="attachment-links-container">
        <app-view-story-attachments
          [story]="story"
          (afterDialogClosed)="fetchStoryFromApi($event)"
          [canEdit]="!isInReadMode"
        ></app-view-story-attachments>
        <app-view-story-links
          [story]="story"
          [canEdit]="!isInReadMode"
          (afterDialogClosed)="fetchStoryFromApi($event)"
        ></app-view-story-links>
      </div>
    </mat-expansion-panel>

    <br />

    <ng-container *ngFor="let phase of story.phaseMap">
      <mat-expansion-panel
        [expanded]="isInReadMode || story.phase === phase.phase || !story.partsAreValid(phase.parts)"
        class="details-expansion-panel"
        [ngClass]="{ 'is-expanded': story.phase === phase.phase }"
        *ngIf="!isInReadMode || story.hasValues(phase.phase)"
      >
        <mat-expansion-panel-header class="panel-header">
          <mat-panel-title class="expansion-header">{{ phase.title }}</mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let storyPart of phase.parts">
          <app-view-story-part
            *ngIf="storyPart !== StoryPart.Summary && storyPart !== StoryPart.SharingConditions"
            [story]="story"
            [storyPart]="storyPart"
            [isReadOnly]="isInReadMode"
            [canEdit]="canEdit"
            (afterDialogClosed)="fetchStoryFromApi($event)"
          ></app-view-story-part>
        </ng-container>
      </mat-expansion-panel>
    </ng-container>

    <br />
  </div>
  <div class="right">
    <app-story-meta [story]="story" [isReadOnly]="isInReadMode" (storyUpdated)="fetchStoryFromApi(story.id)">
    </app-story-meta>
  </div>
</div>
