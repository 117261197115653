<div class="tagMoveDialogWrapper">
  <div class="tagMoveDialogTitle">
    <h3 mat-dialog-title>Move tag to Categorised Tag</h3>
    <button mat-button class="tagMoveDialogButton" (click)="onCancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <h4>{{ message }}</h4>
  <div mat-dialog-content class="tagMoveDialogContent">
    <mat-form-field class="tagMoveDialogField">
      <mat-label>Select Category</mat-label>
      <mat-select [(value)]="selectedCategory">
        <mat-option *ngFor="let category of categories" [value]="category">
          {{ category.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div id="tagMoveDialogButtonsContainer" mat-dialog-actions>
    <button class="tagMoveDialogButton" mat-button color="primary" [disabled]="!selectedCategory" (click)="moveTag()">
      <span>SAVE</span>
    </button>
    <button class="tagMoveDialogButton" mat-button (click)="onCancel()">
      <span>CANCEL</span>
    </button>
  </div>
</div>
