import { Component, OnInit } from '@angular/core';
import { StoriesService } from '../../story/services/stories.service';
import { Story } from '../../shared/models/story';
import { ClientMessageService } from '../../shared/services/client-message.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { StoryVisibility, storyVisibilityDescriptor } from '../../shared/models/story-visibility';
import {
  StoryCaseStudyNomination,
  storyCaseStudyNominationDescriptor,
} from '../../shared/models/story-case-study-nomination';
import { StoryPhase, storyPhaseDescriptor } from '../../shared/models/story-phase';

@Component({
  selector: 'app-export-home',
  templateUrl: './export-home.component.html',
  styleUrls: ['./export-home.component.scss'],
})
export class ExportHomeComponent implements OnInit {
  constructor(
    private storiesService: StoriesService,
    private clipboard: Clipboard,
    private messageService: ClientMessageService
  ) {}

  stories: Story[];
  displayedColumns: string[] = [
    'clientName',
    'title',
    'visibility',
    'phase',
    'saleClosedDate',
    'deliveryStartDate',
    'deliveryCompletedDate',
    'participationWillingness',
    'goneToProductionDate',
    'nominatedBy',
    'nominationDate',
    'notes',
    'owner',
    'contributors',
  ];

  ngOnInit(): void {
    this.storiesService.getStoriesForExport().then((s) => (this.stories = s));
  }

  copy() {
    const selection = window.getSelection();
    selection.removeAllRanges();

    const range = document.createRange();
    range.selectNodeContents(document.getElementById('data-table'));

    selection.addRange(range);

    document.execCommand('copy');
    selection.removeAllRanges();
    this.messageService.showClientInfoMessage('Table copied to clipboard.');
  }

  visibility(v: StoryVisibility) {
    return v ? storyVisibilityDescriptor[v].Label : '';
  }

  nomination(n: StoryCaseStudyNomination) {
    return n ? storyCaseStudyNominationDescriptor[n].Label : '';
  }

  phase(ph: StoryPhase) {
    return ph ? storyPhaseDescriptor[ph].Label : '';
  }

  get loaded(): boolean {
    return this.stories !== undefined;
  }

  navigate(id: string) {
    window.open(`/view-story/${id}`, '_blank');
  }
}
