<mat-list role="list">
  <mat-list-item role="listitem" *ngFor="let contributor of contributors">
    <div class="avatar" mat-card-avatar>{{ contributor.initials }}</div>
    <div
      class="contributor-name"
      [title]="
        contributor.fullName + (contributor.fullName !== contributor.email ? ' (' + contributor.email + ')' : '')
      "
    >
      {{ contributor.fullName }}
    </div>
    <span class="fill-remaining-space"></span>
    <button *ngIf="enableRemoveContributor" mat-icon-button (click)="removeContributor.emit(contributor)">
      <mat-icon>close</mat-icon>
    </button>
  </mat-list-item>
</mat-list>
