import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { StoriesService } from '../../services/stories.service';
import { EmbeddedLinkType, embeddedLinkTypeDescriptor } from '../../../shared/models/embedded-link-type';

@Component({
  selector: 'app-view-story-link',
  templateUrl: './view-story-link.component.html',
  styleUrls: ['./view-story-link.component.scss'],
})
export class ViewStoryLinkComponent implements OnInit {
  @Input()
  uri: string;

  @Input()
  label: string;

  @Input()
  type: EmbeddedLinkType;

  metadata;

  loading = true;

  error = false;

  public readonly embeddedLinkTypeDescriptor = embeddedLinkTypeDescriptor;

  public embeddedLinkType = EmbeddedLinkType;

  constructor(private readonly sanitizer: DomSanitizer, private readonly storiesService: StoriesService) {}

  async ngOnInit() {
    try {
      if (!embeddedLinkTypeDescriptor[this.type].Metadata.DefaultImage)
        this.metadata = await this.storiesService.getEmbeddedLinkMetadata(this.uri).toPromise();
    } catch {
      this.error = true;
      this.metadata = { description: 'Could not fetch content from the link.' };
    } finally {
      this.loading = false;
    }
  }

  get types() {
    return Object.values(this.embeddedLinkType)
      .filter((value) => isNaN(Number(value)) === false)
      .filter((value) => this.embeddedLinkTypeDescriptor[value].Metadata);
  }

  get showFallbackImage() {
    return !this.showDefaultImage && (!this.metadata || !this.metadata.image) && !this.loading;
  }

  get showDefaultImage(): boolean {
    const e = embeddedLinkTypeDescriptor[this.type];
    return e && e.Metadata && !!e.Metadata.DefaultImage;
  }

  get image(): string {
    if (this.showDefaultImage) {
      return embeddedLinkTypeDescriptor[this.type].Metadata.DefaultImage;
    } else if (this.showFallbackImage) {
      return '/assets/link-attachment-default.png';
    } else if (this.metadata && this.metadata.image && !this.loading) {
      return this.sanitizer.sanitize(SecurityContext.URL, this.metadata.image);
    }
  }

  get title() {
    return this.loading ? 'loading ...' : this.metadata.description;
  }

  navigate() {
    window.open(this.uri, '_blank');
  }
}
