<div class="file-icon"><mat-icon>description</mat-icon></div>
<div class="file-information">
  <div class="file-name">{{ file.name }}</div>
  <div
    class="file-status"
    [ngClass]="{ 'is-error': isError(), 'is-uploading': progressEvent.status === UploadStatus.Uploading }"
  >
    {{ statusText(progressEvent.status) }}
  </div>
</div>
<div class="file-actions">
  <mat-progress-spinner
    class="progress"
    *ngIf="progressEvent.status === UploadStatus.Uploading"
    [diameter]="20"
    mode="determinate"
    color="primary"
    [value]="progressEvent.progress"
  ></mat-progress-spinner>
  <mat-icon
    class="cancel"
    *ngIf="progressEvent.status === UploadStatus.Uploading"
    color="primary"
    (click)="cancelUpload()"
    >cancel</mat-icon
  >
  <mat-icon class="retry" (click)="startUpload()" *ngIf="progressEvent.status === UploadStatus.Error && !error"
    >refresh</mat-icon
  >
  <mat-icon
    class="clear"
    (click)="clearUpload()"
    *ngIf="
      progressEvent.status === UploadStatus.Error ||
      progressEvent.status === UploadStatus.ErrorTooLarge ||
      progressEvent.status === UploadStatus.ErrorUnsupported
    "
    >clear</mat-icon
  >
  <mat-icon
    class="start"
    (click)="startUpload()"
    *ngIf="mode === UploadMode.Manual && progressEvent.status === UploadStatus.Inactive"
    >arrow_downward</mat-icon
  >
</div>
