<div class="deleteDialogWrapper">
  <div class="dialogTitle">
    <h3 mat-dialog-title>{{ title }}</h3>
    <button mat-button class="dialogButton" (click)="onCancel()"><mat-icon>clear</mat-icon></button>
  </div>
  <div mat-dialog-content>
    {{ message }}
  </div>
  <div id="dialogButtonsContainer" mat-dialog-actions>
    <button class="dialogButton" mat-button color="primary" (click)="onConfirm()">
      <span>{{ okText }}</span>
    </button>
    <button class="dialogButton" mat-button (click)="onCancel()">
      <span>{{ cancelText }}</span>
    </button>
  </div>
</div>
