export enum StorySortField {
  Id = 1,
  LastUpdatedDate = 2,
  // DeliveryDate = 3,
  Value = 4,
  Likes = 5,
  Owner = 6,
  DatePublished = 7,
  SaleClosedDate = 8,
  DeliveryStartDate = 9,
  DeliveryCompletedDate = 10,
  DateCreated = 11,
}
