<div class="dialogBox">
  <div class="dialogTitle">
    <h3 mat-dialog-title>Change ownership</h3>
    <button mat-button class="storyDialogButton" (click)="cancel()"><mat-icon>clear</mat-icon></button>
  </div>
  <div mat-dialog-content>
    <p>
      You are about to grant ownership of this story to a contributor. By doing this you will lose all privileges as an
      owner of this document, would you like to proceed?
    </p>
  </div>
  <div id="dialogButtonsContainer" mat-dialog-actions>
    <button class="storyDialogButton" color="primary" cdkFocusInitial="true" mat-button (click)="proceed()">
      <span>PROCEED</span>
    </button>
    <button class="storyDialogButton" mat-button (click)="cancel()">
      <span>CANCEL</span>
    </button>
  </div>
</div>
