import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { StoryVisibility, storyVisibilityDescriptor, storyVisibilityOrdered } from '../../../../shared/models/story-visibility';
import { Story } from '../../../../shared/models/story';
import { AttachmentType } from '../../../../shared/models/attachment-type';
import { FileUploadService, IFileUploadEvent, UploadStatus } from '../../../services/file-upload.service';
import { StoriesService } from '../../../services/stories.service';
import { ClientMessageService } from '../../../../shared/services/client-message.service';

enum State {
  Idle,
  Processing,
  Cancelled,
  Error,
}

@Component({
  selector: 'app-visibility-edit-mode',
  templateUrl: './visibility-edit-mode.component.html',
  styleUrls: ['./visibility-edit-mode.component.scss'],
})
export class VisibilityEditModeComponent implements OnInit {
  @Input()
  story: Story;

  @Output()
  switchToView = new EventEmitter();

  @Output()
  storyUpdated = new EventEmitter();

  readonly storyVisibilityDescriptor = storyVisibilityDescriptor;
  selectedVisibility: StoryVisibility;
  readonly storyVisibilityOrdered = storyVisibilityOrdered;
  @ViewChild('evidenceFileInput', { static: true }) evidenceFileInput;

  private evidenceFile: File;
  private state: State = State.Idle;

  constructor(
    private storiesService: StoriesService,
    private uploadService: FileUploadService,
    private clientMessageService: ClientMessageService
  ) {}

  ngOnInit(): void {
    this.selectedVisibility = this.story.visibility;
  }

  evidenceIsRequired(): boolean {
    switch (this.selectedVisibility) {
      case StoryVisibility.InternalStory:
      case StoryVisibility.PrivateStory:
        return false;
      case StoryVisibility.PublicStory:
      case StoryVisibility.CaseStudy:
      default:
        return true;
    }
  }

  evidenceRequirementIsSatisfied(): boolean {
    return this.evidenceFile != null;
  }

  evidenceFileName(): string {
    if (this.evidenceFile) {
      return this.evidenceFile.name;
    } else {
      return null;
    }
  }

  evidenceUploadStatusText(): string {
    if (!this.evidenceFile) {
      return null;
    }
    switch (this.state) {
      case State.Idle:
        return 'Ready to upload';
      case State.Processing:
        return 'Uploading...';
      case State.Cancelled:
        return 'Cancelled';
      case State.Error:
        return 'Error';
    }
  }

  saveDisabled(): boolean {
    return this.state === State.Processing || (this.evidenceIsRequired() && !this.evidenceRequirementIsSatisfied());
  }

  onVisibilityChange(value: StoryVisibility) {
    this.selectedVisibility = value;
  }

  onEvidenceBrowseClicked() {
    this.evidenceFileInput.nativeElement.click();
  }

  onEvidenceFilesChange(files: FileList) {
    if (files.length > 0) {
      this.evidenceFile = files[0];
    } else {
      this.evidenceFile = null;
    }
    this.state = State.Idle;
  }

  onSaveClicked() {
    if (this.state === State.Processing) return;
    this.state = State.Processing;

    const storyId = this.story.id;
    const visibility = this.selectedVisibility;
    const file = this.evidenceFile;

    if (!file) {
      this.storiesService.updateStoryVisibility(storyId, visibility, []).subscribe(
        (_) => {
          this.storyUpdated.emit();
          this.switchToView.emit();
        },
        (error) => {
          this.state = State.Error;
          this.onUpdateStoryVisibilityFailed(error);
        }
      );
      return;
    }

    // Begin uploading evidence attachment.
    //
    // The attachment is uploaded with attachment type: Hidden.
    // Later it will be atomically swapped in as a PublishConsent
    // attachment by the API via updateStoryVisibility.
    const upload = this.uploadService.uploadAttachment(file, storyId, AttachmentType.Hidden);

    // Wait for uploads to complete.
    upload.event.subscribe(
      (uploadEvent) => {
        if (uploadEvent.status === UploadStatus.Uploading) {
          // Upload still in progress. Keep waiting.
          return;
        }
        if (uploadEvent.status !== UploadStatus.Complete) {
          // Upload not complete, but didn't error. Perhaps it was cancelled.
          // Stop here.
          this.state = State.Cancelled;
          return;
        }
        // When upload is complete, set the story visibility with the uploaded attachment
        // as evidence.
        const evidenceAttachmentId = uploadEvent.attachment.id;
        this.storiesService.updateStoryVisibility(storyId, visibility, [evidenceAttachmentId]).subscribe(
          (_) => {
            this.storyUpdated.emit();
            this.switchToView.emit();
          },
          (error) => {
            this.state = State.Error;
            this.onUpdateStoryVisibilityFailed(error);
          }
        );
      },
      (error) => {
        this.state = State.Error;
        this.onFileUploadFailed(error);
      }
    );
  }

  private onFileUploadFailed(error: IFileUploadEvent) {
    this.clientMessageService.showClientErrorMessage('An error occurred while uploading your attachment.');
    throw error;
  }

  private onUpdateStoryVisibilityFailed(error: IFileUploadEvent) {
    this.clientMessageService.showClientErrorMessage(
      'An error occurred while attempting to change the story visibility.'
    );
    throw error;
  }

  cancel() {
    this.selectedVisibility = this.story.visibility;
    this.switchToView.emit();
  }

  visibilityInfoMessages(visibility: StoryVisibility): string {
    switch(visibility){
      case StoryVisibility.InternalStory:{
        return "Do not share with customers"
      }
      case StoryVisibility.PrivateStory:{
        return "You can share privately with customers (per any noted sharing conditions)"
      }
      case StoryVisibility.PublicStory:{
        return "You can post about this publicly on our websites, socials etc (per any noted sharing conditions)"
      }
      case StoryVisibility.CaseStudy:{
        return "This story has a published document / video that you can post publicly on our websites, socials etc (per any noted sharing conditions)"
      }
    }
  }
}
