import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ConfigurationService } from 'src/app/shared/services/configuration/configuration.service';
import { GetAllTagsAndCategoriesResponse } from '../models/get-all-tags-categories-response';
import { Tag } from '../models/tag';
import { map } from 'rxjs/operators';
import { TagCategory } from '../models/tag-category';

@Injectable({
  providedIn: 'root',
})
export class TagCategoryService {
  baseApiUrl: string;
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  tagEventSubject: Subject<TagEvent> = new Subject<TagEvent>();

  constructor(private http: HttpClient, private config: ConfigurationService) {
    this.baseApiUrl = this.config.apiUrl + '/tag';
  }

  getAllTagsAndCategories(): Observable<GetAllTagsAndCategoriesResponse> {
    return this.http.get<GetAllTagsAndCategoriesResponse>(`${this.baseApiUrl}/all`, {
      headers: this.headers,
    });
  }

  renameTag(tag: Tag): Observable<any> {
    return this.http
      .post(this.baseApiUrl + `/${tag.id}/rename`, { value: tag.value }, { headers: this.headers })
      .pipe(map(() => this.tagEventSubject.next({ tag: tag, event: TagEventType.Rename })));
  }

  replaceTag(uncategorisedTag: Tag, categorisedTag: Tag): Observable<any> {
    return this.http
      .post(
        this.baseApiUrl + `/${uncategorisedTag.id}/replace`,
        { categorisedTagId: categorisedTag.id },
        { headers: this.headers }
      )
      .pipe(map(() => this.tagEventSubject.next({ tag: uncategorisedTag, event: TagEventType.Replace })));
  }

  deleteTag(tag: Tag): Observable<any> {
    return this.http
      .delete(this.baseApiUrl + `/${tag.id}/delete`, { headers: this.headers })
      .pipe(map(() => this.tagEventSubject.next({ tag: tag, event: TagEventType.Delete })));
  }

  moveTag(uncategorisedTag: Tag, selectedCategory: TagCategory): Observable<any> {
    return this.http
      .post(
        this.baseApiUrl + `/${uncategorisedTag.id}/move`,
        { categoryId: selectedCategory.id },
        { headers: this.headers }
      )
      .pipe(
        map(() => {
          uncategorisedTag.categoryId = selectedCategory.id;
          this.tagEventSubject.next({ tag: uncategorisedTag, event: TagEventType.Move });
        })
      );
  }

  addTags(tags: Tag[]): Observable<any> {
    return this.http.post(this.baseApiUrl, { categorisedTags: tags }, { headers: this.headers });
  }
}

export interface TagEvent {
  tag: Tag;
  event: TagEventType;
}

export enum TagEventType {
  Rename,
  Replace,
  Delete,
  Move,
}
