import { Component, Input, NgZone, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { releaseNoteContentMaxLength, storyTitleMaxCharacterLimit } from '../../../shared/constants';
import { Router } from '@angular/router';
import { UserService } from '../../../shared/services/user/user.service';
import { User } from '../../../shared/models/user';
import { ReleaseNotesService } from '../../services/release-note.service';
import { ReleaseNote } from 'src/app/shared/models/release-note';

@Component({
  selector: 'app-new-or-edit-release-note-dialog',
  templateUrl: './new-or-edit-release-note-dialog.html',
  styleUrls: ['./new-or-edit-release-note-dialog.scss'],
})
export class NewOrEditReleaseNoteComponent implements OnInit {
  releaseNoteNameCharacterLimit = storyTitleMaxCharacterLimit;
  newReleaseNoteForm = new FormGroup({
    title: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(this.releaseNoteNameCharacterLimit),
    ]),
    versionId: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(this.releaseNoteNameCharacterLimit),
    ]),
    creator: new FormControl({ disabled: false, value: '// todo: Get user' }, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(this.releaseNoteNameCharacterLimit),
    ]),
    implementer: new FormControl({ disabled: false, value: '// todo: Get user' }, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(this.releaseNoteNameCharacterLimit),
    ]),
    content: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(releaseNoteContentMaxLength),
    ]),
  });

  @Input() releaseNoteId: string;

  constructor(
    private dialogRef: MatDialogRef<NewOrEditReleaseNoteComponent>,
    private router: Router,
    private userService: UserService,
    private releaseNotesService: ReleaseNotesService
  ) {}

  ngOnInit() {
    this.userService.getUser().subscribe((user: User) => {
      this.newReleaseNoteForm.controls['implementer'].setValue(user.fullName);
      this.newReleaseNoteForm.controls['creator'].setValue(user.fullName);
      this.newReleaseNoteForm.controls['creator'].disable();
    });
  }
  onDoCreate(): void {
    const formValue = (this.newReleaseNoteForm.value as any) as ReleaseNote;
    this.releaseNotesService.createReleaseNote(formValue).subscribe((created: any) => {
      this.dialogRef.close();
    });
  }

  onEditCommit(): void {
    console.log('onEditCommit()');
    this.dialogRef.close();
  }

  onCancel(): void {
    console.log('onCancel()');
    this.dialogRef.close();
  }

  onDelete(): void {
    console.log('onDelete()');
    this.dialogRef.close();
  }
}
