import { HttpParams } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StoryVisibility } from '../../../shared/models/story-visibility';
import { User } from '../../../shared/models/user';
import { StorySearchResult } from '../../../shared/models/story-search-result';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FeaturedImageService } from '../../../shared/services/featured-images/featured-image.service';
import { DATE_FORMAT } from 'src/app/shared/date-formats-datepipe';
import markdownIt from 'markdown-it';

@Component({
  selector: 'app-story-display-item',
  templateUrl: './story-display-item.component.html',
  styleUrls: ['./story-display-item.component.scss'],
})
export class StoryDisplayItemComponent implements OnInit {
  readonly DATE_FORMAT: string = DATE_FORMAT;
  readonly md = new markdownIt();

  @Input() story: StorySearchResult;
  @Input() currentUser: User;

  imageIsLoading: boolean;
  internalStory = StoryVisibility.InternalStory;
  caseStudy = StoryVisibility.CaseStudy;
  storyTitle: string;
  storyClient: string;
  storyDeliveredBy: string;
  storySummary: string;
  featuredImageUrl: string;
  lastEdited: Date;

  searchText = '';

  constructor(
    public dialog: MatDialog,
    private featuredImageService: FeaturedImageService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.storyTitle = this.story.title;
    this.storyClient = this.story.clientName;
    this.storyDeliveredBy = this.deliveredByString();
    this.storySummary = this.story.summary || this.story.background;
    this.lastEdited = this.story.lastEdited;
    if (this.story.hasFeaturedImage) {
      this.imageIsLoading = true;
      this.featuredImageUrl = this.featuredImageService.getFeaturedImageUrlForStory(
        this.story.id,
        this.story.lastEdited
      );
    }
    this.activatedRoute.fragment.subscribe((fragment) => {
      const params = new HttpParams({ fromString: fragment });
      const term = params.get('term');
      if (term) {
        this.searchText = term;
      }
    });
  }

  imageLoaded(): void {
    this.imageIsLoading = false;
  }

  deliveredByString() {
    return this.story.deliveredBy.join(' + ');
  }

  storyClick(event: MouseEvent) {
    const isIFrame = window.location.pathname === '/story-search-iframe';
    //Open the story in new tab if ctrl key is hold
    if (event && (event.ctrlKey || isIFrame)) {
      const url = `/view-story/${this.story.id}`;
      window.open(url, '_blank');
      event.stopPropagation();
      return false;
    }

    this.router.navigate(['/view-story/', this.story.id], {
      queryParams: this.searchText !== '' ? { term: this.searchText } : {},
    });
    event.stopPropagation();
    return false;
  }

  getSubsectionByLength(section: string, charsToReturn: number) {
    if (section === null) {
      return '';
    } else if (section.length < charsToReturn) {
      return section;
    } else {
      return section.substring(0, charsToReturn) + '...';
    }
  }
}
