import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StoryState, storyStateDescriptor } from '../../../../shared/models/story-state';
import { Story } from '../../../../shared/models/story';
import { StoriesService } from '../../../services/stories.service';
import { ClientMessageService } from '../../../../shared/services/client-message.service';
import { Router } from '@angular/router';
import { StoryVisibility } from 'src/app/shared/models/story-visibility';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-state-edit-mode',
  templateUrl: './state-edit-mode.component.html',
  styleUrls: ['./state-edit-mode.component.scss'],
})
export class StateEditModeComponent implements OnInit {
  @Input()
  story: Story;

  @Output()
  switchToView = new EventEmitter();

  @Output()
  storyUpdated = new EventEmitter();

  selectedState: StoryState;
  storyStateDescriptor = storyStateDescriptor;

  constructor(
    private storiesService: StoriesService,
    private messageService: ClientMessageService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.selectedState = this.story.state;
  }

  get showVisibilityWarning() {
    return (
      this.story.visibility !== StoryVisibility.InternalStory &&
      this.selectedState !== this.story.state &&
      this.selectedState === StoryState.Draft
    );
  }

  get saveDisabled() {
    return this.story.state === this.selectedState || this.cantPublish;
  }

  get cantPublish() {
    return this.selectedState === StoryState.Published && !this.story.isValid;
  }

  save() {
    this.storiesService.updateStoryState(this.story.id, this.selectedState).subscribe(
      () => {
        if (this.selectedState === StoryState.Draft && this.story.visibility !== StoryVisibility.InternalStory) {
          this.messageService.showClientInfoMessage('Story visibility has been reverted back to Internal');
        } else if (
          this.selectedState === StoryState.Published &&
          this.story.visibility === StoryVisibility.InternalStory
        ) {
          this.messageService.showClientInfoMessageCustom(
            "Congratulations on publishing the story! Please consider taking action to change this story's visibility to allow us to share the story more broadly as part of helping grow the usefulness of StoryHub.",
            'OK',
            30000
          );
        }
        this.switchToView.emit();
        this.storyUpdated.emit();
      },
      (error) => {
        if (error.status === 428 && error.error && error.error.detail) {
          this.messageService.showClientErrorMessage('Received error saving the story state. ' + error.error.detail);
        } else {
          this.messageService.showClientErrorMessage('Received error saving the story state');
        }
      }
    );
  }

  cancel() {
    this.switchToView.emit();
    this.selectedState = this.story.state;
  }

  isOptionDisabled(state: StoryState): boolean {
    if (state !== StoryState.Draft && state !== StoryState.Published) return this.story.state !== StoryState.Draft;

    return false;
  }
}
