// To be user along with enums to set different Labels and/or a Description
// By default, all labels will match enum keys
export class EnumDescriptor<T = {}> {
  get Keys(): number[] {
    return this.keys;
  }

  private readonly keys: number[];

  constructor(private _enum: Object) {
    this.keys = Object.keys(this._enum)
      .map((key) => Number(key))
      .filter((key) => !isNaN(key));
    for (const k of this.Keys) {
      this[k] = { Label: _enum[k] };
    }
  }

  [key: number]: { Label: string; Description?: string; Metadata?: T };
}
