<mat-expansion-panel [expanded]="true" class="metaPanel mat-elevation-z0">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <h4>
        {{ header }}
        <mat-icon color="warn" *ngIf="errorMessage && !isReadOnly" matTooltip="{{ errorMessage }}">error</mat-icon>
      </h4>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-content></ng-content>
</mat-expansion-panel>
<mat-divider></mat-divider>
