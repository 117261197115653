import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../configuration/configuration.service';

@Injectable({
  providedIn: 'root',
})
export class FeaturedImageService {
  private baseApiUrl: string;

  constructor(private httpClient: HttpClient, private config: ConfigurationService) {
    this.baseApiUrl = this.config.apiUrl;
  }

  getFeaturedImageForStory(storyId: string): Observable<Blob> {
    return this.httpClient.get(`${this.baseApiUrl}/story/${storyId}/featured-image`, {
      responseType: 'blob',
    });
  }

  getFeaturedImageUrlForStory(storyId: string, lastUpdated: Date): string {
    return `${this.baseApiUrl}/story/${storyId}/featured-image/${lastUpdated.getTime()}.img`;
  }
}
