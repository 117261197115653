import { IMapper } from '../mapper';
import { Injectable } from '@angular/core';
import { IUserDto } from '../api/user-dto';

export function getInitials(fullName: string) {
  const firstName = fullName.split(' ')[0] && fullName.split(' ')[0].replace(',', '');
  const lastName = fullName.split(' ')[1] && fullName.split(' ')[1].replace(',', '');

  let initails: string;
  if (firstName) {
    initails = firstName[0];
    if (lastName) {
      initails += lastName[0];
    }
  } else if (lastName) {
    initails = lastName[0];
  }
  return initails;
}

export class User {
  id: string;
  email: string;
  fullName: string;
  displayName?: string;
  isAdmin: boolean;

  get initials(): string {
    return getInitials(this.fullName);
  }
}

@Injectable({ providedIn: 'root' })
export class UserMapper implements IMapper<IUserDto, User> {
  map(item: IUserDto): User {
    const result = new User();
    result.id = item.id;
    result.email = item.email;
    result.fullName = item.displayName;
    result.isAdmin = item.isAdmin;
    return result;
  }
}
