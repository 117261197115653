import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { IConfiguration, IAttachmentSettings, AzureConfig } from './configuration.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class ConfigurationServiceHttpClientFactory {
  private httpClient: HttpClient;

  constructor(private handler: HttpBackend) {
    // HttpClient is created in this way to avoid the HttpErrorInterceptor being constructed at app startup.
    // The interceptor has a dependency on the logging service which was being constructed before it could
    // access the applicationInsightsKey from the load() function below.
    this.httpClient = new HttpClient(this.handler);
  }

  public get(): HttpClient {
    return this.httpClient;
  }
}

@Injectable()
export class ConfigurationService {
  public httpClient: HttpClient;

  constructor(factory: ConfigurationServiceHttpClientFactory) {
    this.httpClient = factory.get();
  }

  get apiUrl(): string {
    return this.config.apiUrl;
  }

  get applicationInsightsKey(): string {
    return this.config.applicationInsightsKey;
  }

  get attachmentSettings(): IAttachmentSettings {
    return this.config.attachmentSettings;
  }

  get tokenRefreshTimeMinutes(): number {
    return this.config.tokenRefreshTimeMinutes;
  }

  get azureConfig(): AzureConfig {
    return this.config.azureConfig;
  }

  get environmentName(): string {
    return this.config.environmentName;
  }
  private config: IConfiguration = {
    apiUrl: 'http://localhost:4200/api',
    applicationInsightsKey: '',
    attachmentSettings: {
      acceptFiles: '.txt, .pdf',
      maxFileSize: 5000,
    } as IAttachmentSettings,
    tokenRefreshTimeMinutes: 1,
    azureConfig: {} as AzureConfig,
    environmentName: 'Development',
  };

  load(): Observable<any> {
    return this.httpClient
      .get('/assets/settings.json', {
        headers: {
          'Cache-Control': 'no-store, max-age=0',
        },
      })
      .pipe(
        map((response: any) => {
          const responseConfig = response as IConfiguration;
          this.config = {
            ...this.config,
            ...responseConfig,
            attachmentSettings: {
              ...this.config.attachmentSettings,
              ...responseConfig.attachmentSettings,
            },
            azureConfig: {
              ...this.config.azureConfig,
              ...responseConfig.azureConfig,
            },
          };
          return;
        })
      );
  }
}
