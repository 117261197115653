<div class="dialogTitle">
  <h3 mat-dialog-title><strong>People Who Liked This Story</strong></h3>
  <button mat-button class="storyDialogButton" (click)="onCancel()">
    <mat-icon>clear</mat-icon>
  </button>
</div>
<div [hidden]="!isLoading">
  <mat-spinner diameter="30"></mat-spinner>
</div>
<mat-list role="list" *ngIf="!isLoading">
  <mat-list-item role="listitem" *ngFor="let user of users">
    <div class="avatar" mat-card-avatar>{{ user.initials }}</div>
    {{ user.fullName }}
  </mat-list-item>
</mat-list>
