<div class="tagRenameDialogWrapper">
  <div class="tagRenameDialogTitle">
    <h3 mat-dialog-title>Rename Tag</h3>
    <button mat-button class="tagRenameDialogButton" (click)="onCancel()"><mat-icon>clear</mat-icon></button>
  </div>
  <div mat-dialog-content class="tagRenameDialogContent">
    <form [formGroup]="tagRenameForm">
      <mat-form-field class="tagRenameDialogField">
        <mat-label>Rename</mat-label>
        <input matInput formControlName="value" cdkFocusInitial #tagValue [maxlength]="tagValueMaxCharacterLimit" />
        <mat-hint align="end">{{ tagValue.value?.length || 0 }} / {{ tagValueMaxCharacterLimit }}</mat-hint>
      </mat-form-field>
    </form>
  </div>
  <div id="tagRenameDialogButtonsContainer" mat-dialog-actions>
    <button
      class="tagRenameDialogButton"
      mat-button
      color="primary"
      [disabled]="!tagRenameForm.valid || tagValue.value === tag.value"
      [mat-dialog-close]="tag"
      (click)="updateTagValue()"
    >
      <span>SAVE</span>
    </button>
    <button class="tagRenameDialogButton" mat-button (click)="onCancel()">
      <span>CANCEL</span>
    </button>
  </div>
</div>
