<div class="parent">
  <div class="row">
    <div class="col-md-12">
      <h2>Release Notes</h2>

      <release-notes-list
        [releaseNotes]="releaseNotes"
        [rowCount]="rowCount"
        [showDelete]="isAdmin"
        (editClicked)="openEdit($event)"
        (deleteClicked)="deleteReleaseNote($event)"
      >
      </release-notes-list>
    </div>
  </div>
</div>
