import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NewOrEditReleaseNoteComponent } from '../new-or-edit-release-note-dialog/new-or-edit-release-note-dialog';

const newReleaseNoteDialogHeight = '500px';
const newReleaseNoteDialogWidth = '600px';

@Component({
  selector: 'app-create-release-note-button',
  templateUrl: './create-release-note-button.component.html',
})
export class CreateReleaseNoteButtonComponent implements OnInit {
  @Input() releaseNoteId: string;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  createNewReleaseNote(): void {
    const newComponentDialogRef = this.dialog.open(NewOrEditReleaseNoteComponent, {
      width: newReleaseNoteDialogWidth,
      height: newReleaseNoteDialogHeight,
    });
  }
}
