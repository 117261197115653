import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Tag } from '../../models/tag';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { tagValueMaxCharacterLimit, validTagRegex } from '../../../shared/constants';
import { TagCategoryService } from '../../services/tag-category.service';

@Component({
  selector: 'app-tag-rename',
  templateUrl: './tag-rename.component.html',
  styleUrls: ['./tag-rename.component.scss'],
})
export class TagRenameComponent implements OnInit {
  tagValueMaxCharacterLimit = tagValueMaxCharacterLimit;
  tagCtrl = new UntypedFormControl('', [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(this.tagValueMaxCharacterLimit),
  ]);
  tagRenameForm = new UntypedFormGroup({
    value: this.tagCtrl,
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public tag: Tag,
    private dialogRef: MatDialogRef<TagRenameComponent>,
    private tagCategoryService: TagCategoryService
  ) {}

  ngOnInit() {
    this.tagRenameForm.addControl('id', new UntypedFormControl(this.tag.id));
    this.tagRenameForm.setValue({ id: this.tag.id, value: this.tag.value });

    this.tagCtrl.valueChanges.subscribe((value) => {
      const newValue = value ? value.replace(validTagRegex, '') : '';
      if (newValue !== this.tagCtrl.value) {
        this.tagCtrl.setValue(newValue);
      }
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  updateTagValue(): void {
    const updatedTag = this.tagRenameForm.value as Tag;
    this.tagCategoryService.renameTag(updatedTag).subscribe(() => this.dialogRef.close());
  }
}
