import { EnumDescriptor } from './enum-descriptor';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faFileImage, faNewspaper, faStar } from '@fortawesome/free-solid-svg-icons';

export enum AttachmentType {
  Hidden = 0,
  Story = 10,
  PublishConsent = 20,
  CaseStudy = 30, // PDF
  Proposal = 40, // PowerPoint
  DeliverableScreenshot = 50, // image
}

export const attachmentTypeDescriptor = new EnumDescriptor<{
  Icon?: IconDefinition;
  Editable?: boolean;
  Order: number;
  DefaultImage: string;
}>(AttachmentType);
attachmentTypeDescriptor[AttachmentType.CaseStudy] = {
  Label: 'Case Study',
  Description: '',
  Metadata: { Icon: faStar, Editable: true, Order: 1, DefaultImage: '/assets/case-study.png' },
};
attachmentTypeDescriptor[AttachmentType.Proposal] = {
  Label: 'Proposal Insert Slide',
  Description: '',
  Metadata: { Icon: faNewspaper, Editable: true, Order: 2, DefaultImage: '/assets/proposal.png' },
};
attachmentTypeDescriptor[AttachmentType.DeliverableScreenshot] = {
  Label: 'Deliverable screenshot',
  Description: '',
  Metadata: { Icon: faFileImage, Editable: true, Order: 3, DefaultImage: '/assets/deliverable.png' },
};
attachmentTypeDescriptor[AttachmentType.Story] = {
  Label: 'Other',
  Description: '',
  Metadata: { Icon: faFileImage, Editable: true, Order: 4, DefaultImage: '/assets/attachment.png' },
};
attachmentTypeDescriptor[AttachmentType.PublishConsent] = {
  Label: 'Publish Consent Documents',
  Description: '',
  Metadata: { Icon: faFileImage, Editable: false, Order: 5, DefaultImage: '/assets/sharing.png' },
};
