import { User, UserMapper } from './user';
import { Injectable } from '@angular/core';
import { IMapper } from '../mapper';
import { IStorySummaryDto } from '../api/story-summary-dto';

export class StorySummary {
  id: string;
  title: string;
  clientName: string;
  value: number;
  createdBy: User;
  phase: string;
  lastEdited: Date;
  lastEditedBy: User;
  state: string;
  owner: User;
}

@Injectable({ providedIn: 'root' })
export class StorySummaryMapper implements IMapper<IStorySummaryDto, StorySummary> {
  constructor(private userMapper: UserMapper) {}

  map(item: IStorySummaryDto): StorySummary {
    const result = new StorySummary();
    result.id = item.id;
    result.title = item.title;
    result.clientName = item.clientName;
    result.value = item.value;
    result.createdBy = this.userMapper.map(item.createdBy);
    result.phase = item.phase;
    result.lastEdited = new Date(item.lastEdited);
    result.lastEditedBy = this.userMapper.map(item.lastEditedBy);
    result.state = item.state || 'Draft';
    result.owner = this.userMapper.map(item.owner);
    return result;
  }
}
