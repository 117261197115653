<div id="ownerName">
  <app-contributors-list [enableRemoveContributor]="false" [contributors]="ownerAsList"> </app-contributors-list>
</div>
<div class="updateCancelButtons" *ngIf="!isReadOnly && story.contributors.length > 0">
  <button mat-button *ngIf="currentUserIsStoryOwner" color="primary" (click)="edit.emit(null)">
    CHANGE
  </button>
</div>
<div class="noContributorsMessage" *ngIf="!isReadOnly && story.contributors.length == 0">
  Owner of a story can only be changed where there are one or more contributors.
</div>
