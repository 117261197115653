import { TagService } from './../../../shared/services/tag/tag.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChange,
  OnChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { Observable } from 'rxjs';
import { map, debounceTime, switchMap, tap, finalize } from 'rxjs/operators';
import { Story } from 'src/app/shared/models/story';
import { TagWithCategory } from 'src/app/shared/models/tag-with-category';
import { Tag } from 'src/app/shared/models/tag';

export class TagListChangedEvent {
  addedTags: string[];
  removedTags: string[];
}

export class TagCategoryViewModel {
  constructor(public name: string, public tags: Tag[], public selectedTags: string[]) {}
}

@Component({
  selector: 'app-story-tag',
  templateUrl: './story-tag.component.html',
  styleUrls: ['./story-tag.component.scss'],
})
export class StoryTagComponent implements OnInit, OnChanges {
  isLoading = false;
  isLoadingAllTags = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new UntypedFormControl();
  filteredTags: Observable<string[]>;
  tags: string[] = [];
  addedTags: string[] = [];
  removedTags: string[] = [];
  allTags: TagWithCategory[] = [];
  allCategories: TagCategoryViewModel[] = [];
  uncategorisedTags: string[];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @Input() story: Story;
  @Output() changeHappened = new EventEmitter<TagListChangedEvent>();

  constructor(private tagService: TagService) {}

  ngOnInit(): void {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      map((value) => {
        const newValue = value ? value.replace(/[^\w- _()]/gi, '') : ''; // SpecialCharsNotAllowedInTags must be excluded
        if (newValue !== this.tagCtrl.value) {
          this.tagCtrl.setValue(newValue);
          this.tagInput.nativeElement.value = newValue;
        }
        return newValue;
      }),
      debounceTime(300),
      tap(() => (this.isLoading = true)),
      switchMap((value) =>
        this.tagService
          .searchTags(value ? (value as string).toLowerCase() : '', 10)
          .pipe(finalize(() => (this.isLoading = false)))
      ),
      map((value) => value.map((t) => t.value))
    );
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes['story'] && changes['story'].previousValue !== changes['story'].currentValue) {
      this.reset();
    }
  }

  public reset(): void {
    this.addedTags = [];
    this.removedTags = [];
    this.allTags = [];
    this.allCategories = [];
    this.uncategorisedTags = [];
    this.refreshTags();
  }

  refreshTags() {
    this.isLoadingAllTags = true;
    this.tagService.getAllTags().subscribe((tags) => {
      this.allTags = tags;
      this.tags = this.story.tags.map((t) => t.value).sort((a, b) => a.localeCompare(b));
      this.allCategories = this.mapCategoriesToViewModel(this.allTags);
      this.isLoadingAllTags = false;
      this.uncategorisedTags = this.allTags
        .filter((t) => t.category === null && this.tags.find((tag) => tag === t.value))
        .map((t) => t.value);
    });
  }

  mapCategoriesToViewModel(tags: TagWithCategory[]): TagCategoryViewModel[] {
    return tags
      .map((t) => t.category)
      .filter((item, i, ar) => ar.indexOf(item) === i && item)
      .map((c) => {
        const filteredTags = this.allTags.filter((t) => t.category === c);
        const selectedTags = filteredTags.filter((t) => this.tags.indexOf(t.value) >= 0).map((t) => t.value);
        return new TagCategoryViewModel(c, filteredTags, selectedTags);
      });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    this.addValue(value);

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  addValue(value: string) {
    if ((value || '').trim()) {
      const trimmedValue = value.trim();

      if (this.tags.findIndex((v) => v.trim().toLowerCase() === trimmedValue.toLowerCase()) < 0) {
        this.tags.push(trimmedValue);
        this.tags = this.tags.sort((a, b) => a.localeCompare(b));

        if (this.addedTags.indexOf(trimmedValue) < 0) {
          this.addedTags.push(trimmedValue);
        }

        // Update the category model
        this.allCategories.forEach((c) => {
          c.tags.forEach((t) => {
            if (t.value.trim() === trimmedValue && c.selectedTags.indexOf(t.value) < 0) {
              const newArr = Object.assign([], c.selectedTags);
              newArr.push(t);
              c.selectedTags = newArr;
            }
          });
        });
      }

      // if it was also removed before then there is no need to mark it to be removed, just get rid of it from the removed list
      const removedIndex = this.removedTags.findIndex((v) => v.trim().toLowerCase() === trimmedValue.toLowerCase());
      if (removedIndex >= 0) {
        this.removedTags.splice(removedIndex, 1);
      }
    }

    this.changeHappened.emit({
      addedTags: this.addedTags,
      removedTags: this.removedTags,
    } as TagListChangedEvent);

    // Update uncategorised tags
    const tag = this.allTags.find((t) => t.value === value);
    if (!tag) this.uncategorisedTags.push(value);
  }

  removeValue(value: string): void {
    const trimmedValue = value.trim();
    const index = this.tags.findIndex((v) => v.trim().toLowerCase() === trimmedValue.toLowerCase());

    if (index >= 0) {
      this.tags.splice(index, 1);

      // if it was also added then there is no need to mark it to be removed, just get rid of it from the added list
      const addedIndex = this.addedTags.findIndex((v) => v.trim().toLowerCase() === trimmedValue.toLowerCase());
      if (addedIndex >= 0) {
        this.addedTags.splice(addedIndex, 1);
      } else {
        this.removedTags.push(trimmedValue);
      }

      // Update the category model
      this.allCategories.forEach((c) => {
        if (c.selectedTags.findIndex((v) => v.trim().toLowerCase() === trimmedValue.toLowerCase()) >= 0) {
          const newArr = Object.assign([], c.selectedTags);
          newArr.splice(
            newArr.findIndex((v) => v.trim().toLowerCase() === trimmedValue.toLowerCase()),
            1
          );
          c.selectedTags = newArr;
        }
      });
    }

    // Update uncategorised tags
    const tagIndex = this.uncategorisedTags.findIndex((t) => t.toLowerCase() === trimmedValue.toLowerCase());
    if (tagIndex >= 0) this.uncategorisedTags.splice(tagIndex, 1);

    this.changeHappened.emit({
      addedTags: this.addedTags,
      removedTags: this.removedTags,
    } as TagListChangedEvent);
  }

  selectionChanged(event: MatAutocompleteSelectedEvent): void {
    const toAdd: string[] = [];
    const toRemove: string[] = [];

    this.allCategories.forEach((c) => {
      c.tags.forEach((t) => {
        if (this.tags.indexOf(t.value) < 0 && c.selectedTags.findIndex((x) => x === t.value.trim()) >= 0) {
          toAdd.push(t.value);
        }

        if (this.tags.indexOf(t.value) >= 0 && c.selectedTags.findIndex((x) => x === t.value.trim()) < 0) {
          toRemove.push(t.value);
        }
      });
    });

    toAdd.forEach((v) => {
      this.addValue(v);
    });

    toRemove.forEach((v) => {
      this.removeValue(v);
    });
  }

  focusedOut(event: any) {
    if (this.tagInput.nativeElement.value) {
      this.addValue(this.tagInput.nativeElement.value);
      this.tagInput.nativeElement.value = '';
    }
  }
}
