import { StoriesService } from './../../services/stories.service';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SHORT_DATE_FORMAT } from 'src/app/shared/date-formats-datepipe';
import { Attachment } from 'src/app/shared/models/attachment';
import { ClientMessageService } from 'src/app/shared/services/client-message.service';

@Component({
  selector: 'app-attachment-item',
  templateUrl: './attachment-item.component.html',
  styleUrls: ['./attachment-item.component.scss'],
})
export class AttachmentItemComponent {
  readonly SHORT_DATE_FORMAT: string = SHORT_DATE_FORMAT;

  @Input() attachment: Attachment;
  @Input() canDelete: boolean;
  @Input() isReadOnly: boolean;
  @Output() deleteClick = new EventEmitter<{}>();
  @ViewChild('downloadLink') downloadLinkRef: ElementRef;

  constructor(private storyService: StoriesService, private messageService: ClientMessageService) {}

  justUploaded(): boolean {
    if (this.attachment && this.attachment.createdDate) {
      const spanMilliseconds = Date.now() - this.attachment.createdDate.getTime();
      return spanMilliseconds < 5 * 60 * 1000;
    } else {
      return false;
    }
  }

  onDeleteClicked(): void {
    this.deleteClick.emit({});
  }

  downloadAttachment(): void {
    this.storyService.getAttachmentUrl(this.attachment.storyId, this.attachment.id).subscribe((result) => {
      window.open(result.url);
      this.messageService.showClientInfoMessage(
        'Attachment download is ready and will be opened in a new window. \n' +
          'This link is only valid for a short time and can only be used once.'
      );
    });
  }
}
