import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FilterFieldViewModel } from '../story-search-filter/story-search-filter.component';

export interface FilterViewModel {
  groupLabel: string;
  label: string;
}

@Component({
  selector: 'app-story-active-filters',
  templateUrl: './story-active-filters.component.html',
  styleUrls: ['./story-active-filters.component.scss'],
})
export class StoryActiveFiltersComponent {
  @Output() filtersChanged = new EventEmitter<FilterViewModel[]>();

  isLoading = false;
  activeFilters: FilterViewModel[] = [];

  private allActiveFilters(fieldGroups: FilterFieldViewModel[]): FilterViewModel[] {
    return fieldGroups
      .map((f) => ({ label: f.label, groupLabel: f.groupLabel, checked: f.checked }))
      .filter((f) => f.checked);
  }

  initialize(intitalFilters: FilterFieldViewModel[]): void {
    this.isLoading = false;
    this.activeFilters = this.allActiveFilters(intitalFilters);
  }

  public setFilters(filters: FilterFieldViewModel[]) {
    this.activeFilters = this.allActiveFilters(filters);
  }

  public clearFilters(): void {
    this.activeFilters = [];
    this.filtersChanged.emit(this.activeFilters);
  }

  public itemChanged(filter: FilterFieldViewModel): void {
    this.activeFilters = this.activeFilters.filter(
      (f) => f.groupLabel !== filter.groupLabel || f.label !== filter.label
    );
    this.filtersChanged.emit(this.activeFilters);
  }
}
