import { User, UserMapper } from './user';
import { StoryPhase } from './story-phase';
import { StoryState } from './story-state';
import { IStoryListItemDto } from '../api/story-list-item-dto';
import { IMapper } from '../mapper';
import { Injectable } from '@angular/core';

export class StoryListItem {
  id: string;
  title: string;
  clientName: string;
  phase: StoryPhase;
  state: StoryState;
  owner: User;
  contributors: User[];
  lastEdited: Date;
  lastEditedBy: User;

  get allContributors(): string {
    let result = [this.owner];
    if (this.contributors) {
      result = result.concat(this.contributors);
    }
    return result.map((x: User) => x.fullName).join(', ');
  }
}

@Injectable({ providedIn: 'root' })
export class StoryListItemMapper implements IMapper<IStoryListItemDto, StoryListItem> {
  constructor(private userMapper: UserMapper) {}

  map(item: IStoryListItemDto): StoryListItem {
    const result = new StoryListItem();
    result.id = item.id;
    result.title = item.title;
    result.clientName = item.clientName;
    result.phase = item.phase;
    result.contributors = item.contributors && item.contributors.map(this.userMapper.map);
    result.lastEdited = new Date(item.lastEdited);
    result.lastEditedBy = item.lastEditedBy && this.userMapper.map(item.lastEditedBy);
    result.state = item.state || StoryState.Draft;
    result.owner = this.userMapper.map(item.owner);
    return result;
  }
}
