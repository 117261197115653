import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import {
  FileUploadService,
  UploadStatus,
  FileUploadEventSource,
  IFileUploadEvent,
} from '../../services/file-upload.service';
import { Subscription } from 'rxjs';
import { ConfigurationService } from 'src/app/shared/services/configuration/configuration.service';
import { IAttachmentSettings } from 'src/app/shared/services/configuration/configuration.model';

export enum UploadMode {
  Manual,
  Automatic,
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnDestroy {
  @Input() file: File;
  @Input() mode: UploadMode = UploadMode.Manual;
  @Input() storyId: string;
  @Output() statusChange = new EventEmitter<IFileUploadEvent>();

  readonly UploadStatus = UploadStatus;
  readonly UploadMode = UploadMode;
  uploadEventSource: FileUploadEventSource;

  status = UploadStatus.Inactive;
  eventSubscription: Subscription;
  progressEvent = { progress: 0, status: UploadStatus.Inactive } as IFileUploadEvent;
  error: Error = null;
  settings: IAttachmentSettings;

  constructor(private uploadService: FileUploadService, configurationService: ConfigurationService) {
    this.settings = configurationService.attachmentSettings;
  }

  ngOnInit() {
    // check file size
    if (this.file && this.file.size > this.settings.maxFileSize) {
      this.progressEvent = { status: UploadStatus.ErrorTooLarge, file: this.file };
    } else if (this.mode === UploadMode.Automatic) {
      this.startUpload();
    }
  }

  startUpload() {
    this.error = null;
    this.safeUnsubscribe();
    this.uploadEventSource = this.uploadService.uploadAttachment(this.file, this.storyId, 10);
    this.eventSubscription = this.uploadEventSource.event.subscribe(
      (event: IFileUploadEvent) => {
        this.progressEvent = event;
        this.statusChange.emit(event);
      },
      (error: IFileUploadEvent | any) => {
        this.progressEvent = error;
        this.statusChange.emit(error);
      }
    );
  }

  statusText(status: UploadStatus): string {
    switch (status) {
      case UploadStatus.Inactive:
        return 'Waiting...';
      case UploadStatus.Uploading:
        return 'Uploading...';
      case UploadStatus.ErrorTooLarge:
        return 'File size too large';
      case UploadStatus.Error:
        return this.error ? this.error.message : 'Error uploading...';
      case UploadStatus.ErrorUnsupported:
        return 'Usupported file type';
      default:
        return '';
    }
  }

  clearUpload(): void {
    this.statusChange.emit({ status: UploadStatus.Canceled, file: this.file });
  }

  cancelUpload(): void {
    this.uploadEventSource.cancel();
    this.statusChange.emit({ file: this.file, status: UploadStatus.Canceled });
  }

  ngOnDestroy(): void {
    this.safeUnsubscribe();
  }

  private safeUnsubscribe() {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
      this.eventSubscription = null;
    }
  }

  isError(): boolean {
    return (
      this.progressEvent.status === UploadStatus.Error ||
      this.progressEvent.status === UploadStatus.ErrorTooLarge ||
      this.progressEvent.status === UploadStatus.ErrorUnsupported ||
      this.error != null
    );
  }
}
