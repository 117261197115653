<div class="story-clients" *ngIf="clients?.length">
  <mat-expansion-panel [hidden]="isLoading" [expanded]="true" class="metaPanel mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h5>Clients</h5>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <mat-checkbox
        *ngFor="let client of clients"
        [ngModel]="isChecked(client)"
        (change)="itemChanged(client, $event.checked)"
      >
        <span>{{ client.name }}</span>
      </mat-checkbox>
    </div>
  </mat-expansion-panel>
</div>
