<mat-form-field class="owner-field">
  <input
    type="text"
    placeholder="Owner"
    aria-label="Update owner"
    matInput
    [formControl]="ownerControl"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete (optionSelected)="displaySelectedOwner($event)" #auto="matAutocomplete">
    <mat-option *ngFor="let contributor of existingContributors" [value]="contributor.fullName">
      {{ contributor.fullName }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<div class="updateCancelButtons" *ngIf="!isReadOnly">
  <button mat-button color="primary" (click)="updateOwner()">
    SAVE
  </button>
  <button mat-button (click)="cancelOwner()">
    CANCEL
  </button>
</div>
