import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Tag } from '../../../../shared/models/tag';

@Component({
  selector: 'app-tags-view-mode',
  templateUrl: './tags-view-mode.component.html',
  styleUrls: ['./tags-view-mode.component.scss'],
})
export class TagsViewModeComponent implements OnInit {
  @Input()
  tags: Tag[];

  @Input()
  isReadOnly: boolean;

  @Output()
  switchToEdit = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
