import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-meta-section',
  templateUrl: './meta-section.component.html',
  styleUrls: ['./meta-section.component.scss'],
})
export class MetaSectionComponent implements OnInit {
  @Input()
  header: string;

  @Input()
  errorMessage?: string;

  @Input()
  isReadOnly?: boolean;

  constructor() {}

  ngOnInit(): void {}
}
