import { Input, Component, Output, EventEmitter, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ReleaseNote } from 'src/app/shared/models/release-note';

@Component({
  selector: 'release-notes-list',
  templateUrl: 'release-notes-list.component.html',
  styleUrls: ['release-notes-list.component.css'],
})
export class ReleaseNotesListComponent implements OnChanges {
  @Input() releaseNotes: ReleaseNote[];
  @Input() rowCount: number;
  @Input() showDelete: boolean;
  @Output() editClicked = new EventEmitter<ReleaseNote>();
  @Output() deleteClicked = new EventEmitter<string>();

  public displayedColumns: string[];
  public tableSource = new MatTableDataSource<ReleaseNote>();

  constructor() {}

  ngOnChanges() {
    this.displayedColumns = ['title', 'versionId', 'implementedBy', 'lastUpdatedBy', 'content'];
    if (this.showDelete) this.displayedColumns.push('delete');
  }

  onEditClicked(releaseNote: ReleaseNote) {
    this.editClicked.emit(releaseNote);
  }

  onDeleteClicked(id: string) {
    this.deleteClicked.emit(id);
  }
}
