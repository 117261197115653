import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Story } from '../../../../shared/models/story';

@Component({
  selector: 'app-owner-view-mode',
  templateUrl: './owner-view-mode.component.html',
  styleUrls: ['./owner-view-mode.component.scss'],
})
export class OwnerViewModeComponent implements OnInit {
  @Input()
  story: Story;
  @Input()
  isReadOnly: boolean;
  @Input()
  currentUserIsStoryOwner: boolean;
  @Output()
  edit = new EventEmitter();
  constructor() {}

  get ownerAsList() {
    return [this.story.owner];
  }

  ngOnInit(): void {}
}
