import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Story } from '../../../../shared/models/story';
import { StoriesService } from '../../../services/stories.service';
import { ClientMessageService } from '../../../../shared/services/client-message.service';

@Component({
  selector: 'app-sharing-conditions-edit-mode',
  templateUrl: './sharing-conditions-edit-mode.component.html',
  styleUrls: ['./sharing-conditions-edit-mode.component.scss'],
})
export class SharingConditionsEditModeComponent implements OnInit {
  public sharingConditionsChecked: boolean;
  public sharingConditions: string;

  @Input()
  story: Story;

  @Output()
  storyUpdated = new EventEmitter();

  @Output()
  switchToView = new EventEmitter();

  constructor(private storiesService: StoriesService, private clientMessageService: ClientMessageService) {}

  ngOnInit(): void {
    this.sharingConditions = this.story.sharingConditions;
    this.sharingConditionsChecked = !!this.sharingConditions;
  }

  onSaveClicked() {
    const storyId = this.story.id;
    const sharingConditionsChecked = this.sharingConditionsChecked;
    const sharingConditions = sharingConditionsChecked ? this.sharingConditions : '';

    this.storiesService.updateStorySharingConditions(storyId, sharingConditions).subscribe(
      (_) => {
        this.storyUpdated.emit();
        this.switchToView.emit();
      },
      (error) => {
        this.clientMessageService.showClientErrorMessage(
          'An error occurred while attempting to change the story visibility.'
        );
        throw error;
      }
    );
    return;
  }

  cancel() {
    this.sharingConditions = this.story.sharingConditions;
    this.switchToView.emit();
  }
}
