import { Component, OnInit, Input, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { Story } from 'src/app/shared/models/story';
import { StoryPart } from 'src/app/shared/models/story-part';
import { StoryPhase } from 'src/app/shared/models/story-phase';

@Component({
  selector: 'app-story-strength',
  templateUrl: './story-strength.component.html',
  styleUrls: ['./story-strength.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StoryStrengthComponent implements OnInit {
  @Input() story: Story;

  strengthPoints: {};

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['story']) {
      this.calculateStoryPoints();
    }
  }

  calculateStoryPoints() {
    const calculatedPoints = this.story.phaseMap.map((p) => {
      const totalPoints = this.calculatePhasePoints(
        p.phase,
        p.parts,
        p.strengthRules.strengthPointForeachPart,
        p.strengthRules.featuredImagePoints,
        p.strengthRules.phasePoints,
        p.strengthRules.likeCount,
        p.strengthRules.likePoints,
        p.strengthRules.tagPoints,
        p.strengthRules.maxTagPoints,
        p.strengthRules.specificTag
      );

      return {
        phase: p.title,
        isPhaseActive: p.phase <= this.story.phase,
        points: totalPoints,
        totalPoints: p.strengthRules.totalStrengthPoints,
        totalPointsPercentage: (totalPoints * 100) / p.strengthRules.totalStrengthPoints,
      };
    });
    this.strengthPoints = calculatedPoints;
  }

  calculatePhasePoints(
    phase: StoryPhase,
    parts: StoryPart[],
    partPoints: number,
    featuredImagePoints: number,
    phasePoints: number,
    likeCount: number,
    likePoints: number,
    tagPoints: number,
    maxTagPoints: number,
    specificTag: string
  ) {
    let points = 0;
    parts.forEach((val) => {
      if (this.story.getStoryPartValue(val)) {
        points = points + partPoints;
      }
    });

    if (this.story.hasFeaturedImage) {
      points = points + featuredImagePoints;
    }

    if (this.story.phase > phase) {
      points = points + phasePoints;
    }

    if (this.story.numberOfLikes >= likeCount) {
      points = points + likePoints;
    }

    if (tagPoints) {
      const tagCount = specificTag
        ? this.story.tags.filter((t) => t.category === specificTag).length - 1
        : this.story.tags.length;
      const total = tagCount <= 0 ? 0 : tagPoints * tagCount;
      points = total > maxTagPoints ? points + maxTagPoints : points + total;
    }

    return points;
  }
}
