import { CallbackComponent } from './shared/components/callback/callback.component';
import { LoginComponent } from './shared/components/login/login.component';
import { ViewStoryComponent } from './story/components/view-story/view-story.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './story/components/home/home.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { StorySearchComponent } from './story/components/story-search/story-search.component';
import { TagManagementHomeComponent } from './tag-management/components/tag-management-home/tag-management-home.component';
import { AdminAuthGuard } from './shared/services/auth-guard/authguard.service';
import { AboutComponent } from './about/about.component';
import { StorySearchIframeComponent } from './story/components/story-search-iframe/story-search-iframe.component';
import { MsalGuard } from '@azure/msal-angular';
import { ExportHomeComponent } from './export/export-home/export-home.component';
import { AddReleaseNoteComponent } from './about/components/add-release-note/add-release-note.component';
import { ReleaseNotesComponent } from './about/components/release-notes/release-notes.component';

const routes: Routes = [
  { path: 'callback', component: CallbackComponent },
  { path: 'login', component: LoginComponent },
  { path: 'my-stories', component: HomeComponent, canActivate: [MsalGuard], data: { title: 'My Stories - StoryHub' } },
  { path: 'story/:id', component: ViewStoryComponent, canActivate: [MsalGuard] },
  { path: 'view-story/:id', component: ViewStoryComponent, canActivate: [MsalGuard] },
  { path: 'story-search', component: StorySearchComponent, canActivate: [MsalGuard] },
  {
    path: 'story-search-iframe',
    component: StorySearchIframeComponent,
    canActivate: [MsalGuard],
    data: { isIFrame: true },
  },
  {
    path: 'tag-management',
    component: TagManagementHomeComponent,
    canActivate: [MsalGuard, AdminAuthGuard],
    data: { title: 'Tag Management - StoryHub' },
  },
  {
    path: 'export',
    component: ExportHomeComponent,
    canActivate: [MsalGuard, AdminAuthGuard],
    outlet: 'fullwidth',
  },
  { path: 'about', component: AboutComponent, canActivate: [MsalGuard], data: { title: 'About - StoryHub' } },
  {
    path: 'add-release-note',
    component: AddReleaseNoteComponent,
    canActivate: [MsalGuard, AdminAuthGuard],
    data: { title: 'Add Release Note' },
  },
  {
    path: 'release-notes',
    component: ReleaseNotesComponent,
    canActivate: [MsalGuard],
    data: { title: 'Release Notes' },
  },
  { path: '', redirectTo: '/story-search', pathMatch: 'full' },
  // Keep wildcard route at the very end
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
