import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from './shared/services/configuration/configuration.service';
import { environment } from '../environments/environment';
import { MsalService } from '@azure/msal-angular';
import { LandingUrlKey } from './shared/components/login/login.component';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { LoggingService } from './shared/services/logging/logging.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public isIFrame = false;

  constructor(
    private configurationService: ConfigurationService,
    private msalService: MsalService,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private logger: LoggingService
  ) {
    this.msalService.handleRedirectCallback((authError, response) => {
      if (authError) {
        this.logger.logError(authError, {
          message: 'Error authenticating',
          response: JSON.stringify(response),
        });
        return;
      }
    });
  }

  ngOnInit() {
    // User needs to be authenticated to access StoryHub
    if (!this.msalService.getAccount()) {
      localStorage.setItem(LandingUrlKey, window.location.href);
      this.msalService.loginRedirect();
    }

    if (environment.production) {
      this.tokenRefreshCounter();
    }

    this.updateIsIFrame();
    this.updateTitle();
  }

  tokenRefreshCounter() {
    setInterval(() => {
      this.msalService.acquireTokenSilent({
        scopes: [this.configurationService.azureConfig.clientId],
        forceRefresh: true,
      });
    }, this.configurationService.tokenRefreshTimeMinutes * 60 * 1000); // unit is in milliseconds here
  }

  updateTitle() {
    const appTitle = 'StoryHub';
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          const child = this.activatedRoute.firstChild;
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      )
      .subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
  }

  updateIsIFrame() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          const child = this.activatedRoute.firstChild;
          if (child.snapshot.data['isIFrame']) {
            return child.snapshot.data['isIFrame'];
          }
          return false;
        })
      )
      .subscribe((isIFrame: boolean) => {
        this.isIFrame = isIFrame;
      });
  }
}
