<div class="releaseNoteEditorWrapper">
  <form [formGroup]="releaseNoteForm">
    <mat-form-field class="releaseNoteField">
      <mat-label>Title</mat-label>
      <input
        matInput
        formControlName="title"
        cdkFocusInitial
        #releaseNoteTitle
        maxlength="{{ releaseNoteNameCharacterLimit }}"
      />
      <mat-hint align="end">{{ releaseNoteTitle.value?.length || 0 }} / {{ releaseNoteNameCharacterLimit }}</mat-hint>
    </mat-form-field>
    <mat-form-field class="releaseNoteField">
      <mat-label>Version</mat-label>
      <input matInput formControlName="versionId" #versionId maxlength="{{ releaseNoteNameCharacterLimit }}" />
      <mat-hint align="end">{{ versionId.value?.length || 0 }} / {{ releaseNoteNameCharacterLimit }}</mat-hint>
    </mat-form-field>
    <mat-form-field class="releaseNoteFieldShort">
      <mat-label>Creator</mat-label>
      <input matInput formControlName="creator" value="// Find user text field" />
    </mat-form-field>
    <mat-form-field class="releaseNoteFieldShort">
      <mat-label>Implementor</mat-label>
      <input matInput formControlName="implementer" value="// Find user text field" />
    </mat-form-field>
    <mat-form-field class="releaseNoteField">
      <mat-label>Content</mat-label>
      <textarea matInput formControlName="content" #content></textarea>
      <mat-hint align="end">{{ content.value?.length || 0 }}</mat-hint>
    </mat-form-field>
  </form>
</div>
<div id="buttonsContainer">
  <button class="releaseNoteButton" mat-button color="primary" [disabled]="!releaseNoteForm.valid" (click)="submit()">
    Save
  </button>
  <button class="releaseNoteButton" mat-button (click)="cancel(releaseNoteForm.dirty)">CANCEL</button>
</div>
