import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../../../shared/models/user';

@Component({
  selector: 'app-contributors-list',
  templateUrl: './contributors-list.component.html',
  styleUrls: ['./contributors-list.component.scss'],
})
export class ContributorsListComponent implements OnInit {
  @Input()
  contributors: User[];
  @Input()
  enableRemoveContributor: boolean;
  @Output()
  removeContributor = new EventEmitter<User>();

  constructor() {}

  ngOnInit(): void {}
}
