<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
  <app-create-story-button fxShow.lt-lg="false"></app-create-story-button>
  <mat-card id="search-card">
    <form>
      <mat-form-field id="published-stories-search-field" fxLayout="row" fxLayoutAlign="space-between none">
        <input
          id="search-input"
          matInput
          cdkFocusInitial
          type="search"
          name="storySearchInput"
          [value]="inputText"
          (input)="inputText = $event.target.value"
          placeholder="Search for stories..."
          fxFill
        />
        <button mat-button matSuffix (click)="initiateSearch()">
          <mat-icon matSuffix cdkFocusInitial class="pointer">search</mat-icon>
        </button>
      </mat-form-field>
    </form>
  </mat-card>
</div>
<div class="activeFilters">
  <app-story-active-filters (filtersChanged)="activeFiltersChanged()"></app-story-active-filters>
</div>
<div class="info-container-parent" fxLayout="row wrap" fxLayoutAlign="space-between center">
  <div class="message-container">
    <mat-icon class="message-icon">info</mat-icon>
    <h4 class="message" *ngIf="!isLoading && showMessage">{{ message }}</h4>
    <h4 class="message" *ngIf="isLoading">Loading...</h4>
  </div>
  <div class="share-container">
    <button *ngIf="!isLoading && showMessage" mat-stroked-button color="primary" (click)="copyUrlToClipboard()">
      <mat-icon class="share-icon">share</mat-icon>Share
    </button>
    <button
      *ngIf="!isLoading && showMessage"
      mat-stroked-button
      color="primary"
      (click)="copyResultSectionToClipboard()"
    >
      <mat-icon class="share-icon">input</mat-icon>Share as iFrame
    </button>
  </div>
</div>
<article>
  <mat-drawer-container class="example-container">
    <mat-drawer
      #drawer
      [mode]="isDeviceLessThanLarge ? 'over' : 'side'"
      [opened]="isDeviceLessThanLarge ? false : true"
      position="start"
      fxLayout="column"
      fxLayoutAlign="start space-between"
    >
      <div class="left">
        <app-story-search-filter
          [storySearchClients]="storySearchClients"
          [selectedClients]="filterComponent?.selectedClients"
          (filtersChanged)="filtersChanged()"
        ></app-story-search-filter>
      </div>
    </mat-drawer>

    <mat-drawer-content>
      <div class="right" [hidden]="!isLoading">
        <mat-spinner diameter="100"></mat-spinner>
      </div>
      <div class="right" *ngIf="!isLoading && storySearchResult">
        <mat-toolbar fxLayout="row wrap" fxLayoutAlign="space-between center">
          <button fxShow.lt-lg="true" fxShow="false" type="button" mat-icon-button (click)="drawer.toggle()">
            <mat-icon>menu</mat-icon>
          </button>
          <mat-form-field class="sort-by">
            <mat-label>Sort by</mat-label>
            <mat-select
              disableRipple
              #sortField
              (selectionChange)="sortChangeRequested($event.value)"
              [(value)]="selectedSortField"
            >
              <mat-option *ngFor="let field of allSortFields" [value]="field">
                {{ field.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-toolbar>
        <app-story-search-result-list
          [storySearchResult]="storySearchResult"
          [isLoading]="isLoading"
          [resultCount]="resultCount"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          [pageIndex]="pageIndex"
          (pageEvent)="pageEventHappened($event)"
        >
        </app-story-search-result-list>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</article>
