import { IMapper } from '../mapper';
import { Injectable } from '@angular/core';
import { Tag } from './tag';
import { ITagWithCategoryDto } from '../api/tag-with-category-dto';

export class TagWithCategory extends Tag {
  category: string;
}

@Injectable({ providedIn: 'root' })
export class TagWithCategoryMapper implements IMapper<ITagWithCategoryDto, TagWithCategory> {
  map(item: ITagWithCategoryDto): TagWithCategory {
    const result = new TagWithCategory();
    result.value = item.value;
    result.category = item.category;
    result.isLegacy = item.isLegacy;
    return result;
  }
}
