import { IEmbeddedLinkDto } from '../api/embedded-link-dto';
import { IMapper } from '../mapper';
import { EmbeddedLinkType } from './embedded-link-type';
import { Injectable } from '@angular/core';

export class EmbeddedLink {
  public id: string;
  public label: string;
  public type: EmbeddedLinkType;
  public uri: string;
}

@Injectable({ providedIn: 'root' })
export class EmbeddedLinkMapper implements IMapper<IEmbeddedLinkDto, EmbeddedLink> {
  map(source: IEmbeddedLinkDto): EmbeddedLink {
    const result = new EmbeddedLink();

    result.id = source.id;
    result.type = source.type;
    result.label = source.label;
    result.uri = source.uri;

    return result;
  }
}
