<div id="main-wrapper">
  <div class="right" [hidden]="!isLoading">
    <mat-spinner diameter="100"></mat-spinner>
  </div>
  <div id="story-item-wrapper">
    <app-story-display-item
      [hidden]="isLoading"
      *ngFor="let story of storySearchResult?.results"
      [story]="story"
      [currentUser]="currentUser"
    ></app-story-display-item>
  </div>
  <div fxFill *ngIf="storySearchResult?.rowCount === 0">
    <h3 fxFill>No results found</h3>
  </div>
  <div *ngIf="storySearchResult?.rowCount !== 0">
    <mat-paginator
      [length]="resultCount"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      [pageIndex]="pageIndex"
      (page)="page($event)"
    >
    </mat-paginator>
  </div>
</div>
