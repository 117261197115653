export const storyTitleMaxCharacterLimit = 100;
export const storyPartMaxCharacterLimit = 64000;
export const releaseNoteContentMaxLength = 2000;
export const notificationDuration = 3000;
export const tagValueMaxCharacterLimit = 200;
export const interceptorSkipHeader = 'X-Skip-Interceptor';
export const interceptorSkip401RedirectHeader = 'X-Skip-401-Redirect-Interceptor';
export const interceptorSkip409ConflictHeader = 'X-Skip-409-Conflict-Interceptor';
export const validTagRegex = /[^\w- _(),&@\/]/gi;
export const searchTextSliceLimit = 25;
export const confirmationDialogHeight = '250px';
export const confirmationDialogWidth = '650px';
