import { Component, Input, OnInit } from '@angular/core';
import { StoryVisibility } from '../../../shared/models/story-visibility';
import { StoryState } from '../../../shared/models/story-state';

@Component({
  selector: 'app-story-visibility-badge',
  templateUrl: './story-visibility-badge.component.html',
  styleUrls: ['./story-visibility-badge.component.scss'],
})
export class StoryVisibilityBadgeComponent implements OnInit {
  constructor() {}

  @Input() storyVisibility: StoryVisibility;
  @Input() storyState: StoryState;

  ngOnInit() {}

  public get isDraft(): boolean {
    return this.storyState === StoryState.Draft;
  }

  public get isInternalStory(): boolean {
    return !this.isDraft && this.storyVisibility === StoryVisibility.InternalStory;
  }

  public get isPrivateStory(): boolean {
    return !this.isDraft && this.storyVisibility === StoryVisibility.PrivateStory;
  }

  public get isPublicStory(): boolean {
    return !this.isDraft && this.storyVisibility === StoryVisibility.PublicStory;
  }

  public get isCaseStudy(): boolean {
    return !this.isDraft && this.storyVisibility === StoryVisibility.CaseStudy;
  }
}
