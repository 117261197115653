import { Story } from '../../../../shared/models/story';
import { storyCaseStudyNominationDescriptor } from '../../../../shared/models/story-case-study-nomination';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-case-study-nomination-view-mode',
  templateUrl: './case-study-nomination-view-mode.component.html',
  styleUrls: ['./case-study-nomination-view-mode.component.scss'],
})
export class CaseStudyNominationViewModeComponent {
  public readonly storyCaseStudyNominationDescriptor = storyCaseStudyNominationDescriptor;
  @Output()
  public readonly switchToEdit = new EventEmitter();

  @Input()
  public currentUserIsStoryOwner: boolean;
  @Input()
  public isReadOnly: boolean;
  @Input()
  public story: Story;
}
