import { EnumDescriptor } from './enum-descriptor';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faFilePowerpoint,
  faFileSignature,
  faFileVideo,
  faLock,
  faTrophy,
  faUnlockAlt,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';

export enum EmbeddedLinkType {
  ExternalVideo,
  InternalVideo,
  PublicArticle,
  InternalArticle,
  StatementOfWork,
  Proposal,
  Deliverable,
}

// tslint:disable:max-line-length
export const embeddedLinkTypeDescriptor = new EnumDescriptor<{ Icon: IconDefinition; DefaultImage?: string }>(
  EmbeddedLinkType
);
embeddedLinkTypeDescriptor[EmbeddedLinkType.ExternalVideo] = {
  Label: 'External Video',
  Description: '',
  Metadata: { Icon: faVideo },
};
embeddedLinkTypeDescriptor[EmbeddedLinkType.InternalVideo] = {
  Label: 'Internal video',
  Description: '',
  Metadata: { Icon: faFileVideo },
};
embeddedLinkTypeDescriptor[EmbeddedLinkType.PublicArticle] = {
  Label: 'Public Article',
  Description: '',
  Metadata: { Icon: faUnlockAlt },
};
embeddedLinkTypeDescriptor[EmbeddedLinkType.InternalArticle] = {
  Label: 'Internal Article',
  Description: '',
  Metadata: { Icon: faLock, DefaultImage: '/assets/internal.png' },
};
embeddedLinkTypeDescriptor[EmbeddedLinkType.StatementOfWork] = {
  Label: 'Statement of Work',
  Description: '',
  Metadata: { Icon: faFileSignature, DefaultImage: '/assets/sow.png' },
};
embeddedLinkTypeDescriptor[EmbeddedLinkType.Proposal] = {
  Label: 'Proposal',
  Description: '',
  Metadata: { Icon: faFilePowerpoint, DefaultImage: '/assets/proposal.png' },
};
embeddedLinkTypeDescriptor[EmbeddedLinkType.Deliverable] = {
  Label: 'Deliverable',
  Description: '',
  Metadata: { Icon: faTrophy, DefaultImage: '/assets/deliverable.png' },
};
