<div class="tagReadOnlyContainer">
  <mat-chip-list #tagChipList>
    <mat-chip *ngFor="let tag of tags">
      {{ tag.value }}
    </mat-chip>
  </mat-chip-list>
  <div class="updateCancelButtons" *ngIf="!isReadOnly">
    <button mat-button color="primary" mat-button (click)="switchToEdit.emit()">
      Change
    </button>
  </div>
</div>
