import { EnumDescriptor } from './enum-descriptor';

export enum StoryVisibility {
  InternalStory = 10,
  PublicStory = 20,
  CaseStudy = 30,
  PrivateStory = 40,
}

export const storyVisibilityDescriptor = new EnumDescriptor(StoryVisibility);
storyVisibilityDescriptor[StoryVisibility.InternalStory] = { Label: 'Internal Story' };
storyVisibilityDescriptor[StoryVisibility.PublicStory] = { Label: 'Public Story' };
storyVisibilityDescriptor[StoryVisibility.CaseStudy] = { Label: 'Case Study' };
storyVisibilityDescriptor[StoryVisibility.PrivateStory] = { Label: 'Private Story' };

export const storyVisibilityOrdered = [StoryVisibility.InternalStory, StoryVisibility.PrivateStory , StoryVisibility.PublicStory , StoryVisibility.CaseStudy]
