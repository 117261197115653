import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { releaseNoteContentMaxLength, storyTitleMaxCharacterLimit } from '../../../shared/constants';
import { UserService } from '../../../shared/services/user/user.service';
import { User } from '../../../shared/models/user';
import { ReleaseNote } from 'src/app/shared/models/release-note';

@Component({
  selector: 'app-release-note-editor',
  templateUrl: './release-note-editor.component.html',
  styleUrls: ['./release-note-editor.component.scss'],
})
export class ReleaseNoteEditorComponent implements OnInit, OnChanges {
  releaseNoteNameCharacterLimit = storyTitleMaxCharacterLimit;
  releaseNoteForm = new FormGroup({
    title: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(this.releaseNoteNameCharacterLimit),
    ]),
    versionId: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(this.releaseNoteNameCharacterLimit),
    ]),
    creator: new FormControl({ disabled: false, value: '// todo: Get user' }, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(this.releaseNoteNameCharacterLimit),
    ]),
    implementer: new FormControl({ disabled: false, value: '// todo: Get user' }, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(this.releaseNoteNameCharacterLimit),
    ]),
    content: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(releaseNoteContentMaxLength),
    ]),
  });

  @Input() releaseNote: ReleaseNote;
  @Output() makeSubmit = new EventEmitter<ReleaseNote>();
  @Output() cancelSubmit = new EventEmitter<boolean>();

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.releaseNoteForm.controls['creator'].disable();
  }

  ngOnChanges() {
    this.initForm();
  }

  submit(): void {
    const { title, versionId, creator, implementer, content } = this.releaseNoteForm.value;

    const releaseNote: ReleaseNote = {
      ...this.releaseNote,
      title,
      versionId,
      creator,
      implementer: implementer,
      content,
    };

    this.makeSubmit.emit(releaseNote);
  }

  cancel(isDirty: boolean): void {
    this.cancelSubmit.emit(isDirty);
  }

  private initForm() {
    if (this.releaseNote) {
      this.releaseNoteForm.patchValue({
        title: this.releaseNote.title,
        content: this.releaseNote.content,
        versionId: this.releaseNote.versionId,
        creator: this.releaseNote.createdBy.displayName,
        implementer: this.releaseNote.implementer,
      });
    } else {
      this.releaseNoteForm.reset();
      this.userService.getUser().subscribe((user: User) => {
        this.releaseNoteForm.controls['implementer'].setValue(user.fullName);
        this.releaseNoteForm.controls['creator'].setValue(user.fullName);
      });
    }
  }
}
