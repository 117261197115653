import { finalize } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Story } from '../../../shared/models/story';
import { StoriesService } from '../../services/stories.service';
import { UserService } from '../../../shared/services/user/user.service';
import { Router } from '@angular/router';
import { User } from '../../../shared/models/user';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DeleteStoryDialogComponent } from '../delete-story-dialog/delete-story-dialog.component';
import { notificationDuration } from '../../../shared/constants';
import { storyStateDescriptor, StoryState } from '../../../shared/models/story-state';
import { storyPhaseDescriptor } from 'src/app/shared/models/story-phase';
import { DATE_TIME_FORMAT } from 'src/app/shared/date-formats-datepipe';
import { StoryListItem } from '../../../shared/models/story-list-item';

const deleteDialogHeight = '300px';
const deleteDialogWidth = '650px';
@Component({
  selector: 'app-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.scss'],
})
export class StoriesComponent implements OnInit {
  readonly DATE_TIME_FORMAT: string = DATE_TIME_FORMAT;

  loading = true;
  stories = new MatTableDataSource<StoryListItem>();
  displayedColumns: string[] = ['title', 'clientName', 'allContributors', 'lastEdited', 'delete'];
  currentUser: User;
  filter = { keywords: null, phase: null, state: null }; // current state of filter
  private _filterPredicate: (data: any, filter: string) => boolean; // save default filter predicate to use it later

  readonly storyPhaseDescriptor = storyPhaseDescriptor;
  readonly storyStateDescriptor = storyStateDescriptor;

  constructor(
    private storiesService: StoriesService,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.fetchStories();
    this.userService.getUser().subscribe((user: User | {}) => {
      if (Object.keys(user).length !== 0) {
        this.currentUser = user as User;
      }
    });
  }

  private fetchStories() {
    this.stories = new MatTableDataSource<StoryListItem>();
    this._filterPredicate = this.stories.filterPredicate;
    this.stories.filterPredicate = (data: StoryListItem, filter: string) => this.filterPredicate(data, filter);
    this.storiesService
      .getStories()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((stories: StoryListItem[]) => {
        if (stories && stories.length > 0) {
          this.stories.data = stories.sort(
            (a: StoryListItem, b: StoryListItem) => b.lastEdited.getTime() - a.lastEdited.getTime()
          );
        }
      });
  }

  private filterPredicate(data: StoryListItem, jsonFilter: string): boolean {
    const filter = JSON.parse(jsonFilter);

    if (filter.phase && data.phase !== filter.phase) {
      return false;
    }

    if (filter.state && data.state !== filter.state) {
      return false;
    }

    if (filter.keywords) {
      const target = Object.assign(
        {},
        { title: data.title, clientName: data.clientName, allContributors: data.allContributors }
      );
      return this._filterPredicate(target, filter.keywords);
    }

    return true;
  }

  onStoryClick(id: string) {
    this.router.navigate(['/story', id]);
  }

  onKeywordsUpdate(keywords: string): void {
    this.filter.keywords = keywords;
    this.onFilterUpdate();
  }

  onFilterUpdate(): void {
    this.stories.filter = JSON.stringify(this.filter);
  }

  showDelete(story: StoryListItem): boolean {
    if (
      this.currentUser &&
      story &&
      ((story.owner && this.currentUser.id === story.owner.id) || this.currentUser.isAdmin)
    ) {
      return true;
    }
    return false;
  }

  canDelete(story: Story): boolean {
    return story.state === StoryState.Draft;
  }

  onStoryDelete(id: string) {
    const deleteStoryDialogRef = this.dialog.open(DeleteStoryDialogComponent, {
      height: deleteDialogHeight,
      width: deleteDialogWidth,
    });
    deleteStoryDialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.storiesService.deleteStoryById(id).subscribe(() => {
          this.fetchStories();
          this.storiesService.deleteStory();
          this.snackBar.open('Draft story deleted successfully.', '', {
            duration: notificationDuration,
            verticalPosition: 'top',
          });
        });
      }
    });
  }
}
