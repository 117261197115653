import { StorySearchResponse } from 'src/app/shared/models/story-search-response';
import { IRevisionDto } from '../../shared/api/revision-dto';
import { IStoryDto } from '../../shared/api/story-dto';
import { IStoryFilterFieldGroupDto } from '../../shared/api/story-filter-field-group-dto';
import { IStoryListItemDto } from '../../shared/api/story-list-item-dto';
import { IStorySummaryDto } from '../../shared/api/story-summary-dto';
import { AttachmentType } from '../../shared/models/attachment-type';
import { CreateStory } from '../../shared/models/create-story';
import { EmbeddedLink } from '../../shared/models/embedded-link';
import { Story, StoryMapper } from '../../shared/models/story';
import { StoryCaseStudyNomination } from '../../shared/models/story-case-study-nomination';
import { StoryLike } from '../../shared/models/story-like';
import { StoryListItem, StoryListItemMapper } from '../../shared/models/story-list-item';
import { StoryState } from '../../shared/models/story-state';
import { StorySummary, StorySummaryMapper } from '../../shared/models/story-summary';
import { StoryVisibility } from '../../shared/models/story-visibility';
import { UpdateStoryPart } from '../../shared/models/update-story-part';
import { UpdateStoryPhase } from '../../shared/models/update-story-phase.model';
import { ConfigurationService } from '../../shared/services/configuration/configuration.service';
import { StorySearchResult } from './../../shared/models/story-search-result';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { interceptorSkip401RedirectHeader, interceptorSkip409ConflictHeader } from 'src/app/shared/constants';
import { PagedResult, StorySearchPagedResultMapper } from 'src/app/shared/models/page-result';
import { Revision, RevisionMapper } from 'src/app/shared/models/revision';
import { StoryPart } from 'src/app/shared/models/story-part';
import { UpdateStoryTitle } from 'src/app/shared/models/update-story-title';
import { IStorySearchResponseDto } from 'src/app/shared/api/story-search-response-dto';

@Injectable({
  providedIn: 'root',
})
export class StoriesService {
  baseApiUrl: string;
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  private storyDeletedSource = new Subject();
  storyDeleted$ = this.storyDeletedSource.asObservable();

  mapper: (data: IStoryDto[], index: number) => any = null;

  constructor(
    private http: HttpClient,
    private config: ConfigurationService,
    private storyMapper: StoryMapper,
    private storyListItemMapper: StoryListItemMapper,
    private storySummaryMapper: StorySummaryMapper,
    private storySearchResultMapper: StorySearchPagedResultMapper,
    private revisionMapper: RevisionMapper
  ) {
    this.baseApiUrl = this.config.apiUrl + '/story';
  }

  private setInterceptorHeader(
    header: typeof interceptorSkip401RedirectHeader | typeof interceptorSkip409ConflictHeader
  ): HttpHeaders {
    // set() modifies a value for a given header and returns a clone of the original instance.
    // This will not modify the original headers object.
    return this.headers.set(header, 'true');
  }

  deleteStory() {
    this.storyDeletedSource.next(null);
  }

  createStory(story: CreateStory): Observable<string | {}> {
    return this.http.post(this.baseApiUrl, story, { headers: this.headers });
  }

  updateStory(story: Story): Observable<any> {
    return this.http.put(this.baseApiUrl, story, { headers: this.headers });
  }

  getStoryById(id: string, isReadOnly: boolean = false) {
    const skip401RedirectHeaders = this.setInterceptorHeader(interceptorSkip401RedirectHeader);
    return this.http
      .get<IStoryDto>(this.baseApiUrl + `/${id}?isReadOnly=${isReadOnly}`, { headers: skip401RedirectHeaders })
      .pipe(switchMap((item) => this.storyMapper.map(item)));
  }

  getStories(): Observable<StoryListItem[]> {
    return this.http
      .get<IStoryListItemDto[]>(this.baseApiUrl, { headers: this.headers })
      .pipe(map((data) => data.map((item) => this.storyListItemMapper.map(item))));
  }

  async getStoriesForExport() {
    const dtos = await this.http.get<IStoryDto[]>(`${this.baseApiUrl}/export`).toPromise();
    return await Promise.all(dtos.map(async (dto) => await this.storyMapper.map(dto, false).toPromise()));
  }

  getMyLastUpdatedStories(limit: number): Observable<StorySummary[]> {
    return this.http
      .get<IStorySummaryDto[]>(this.baseApiUrl + `/my-last-updated/${limit}`, { headers: this.headers })
      .pipe(map((data) => data.map((item) => this.storySummaryMapper.map(item))));
  }

  deleteStoryById(id: string): Observable<any> {
    return this.http.delete(this.baseApiUrl + `/${id}`, { headers: this.headers });
  }

  updateStoryTitleField(updateStory: UpdateStoryTitle): Observable<any> {
    return this.http.patch(`${this.baseApiUrl}/${updateStory.id}`, updateStory, { headers: this.headers });
  }

  updateStoryPart(updateStory: UpdateStoryPart): Observable<any> {
    return this.http.patch(`${this.baseApiUrl}/${updateStory.id}/part`, updateStory, { headers: this.headers });
  }

  updateContributors(id: string, addedContributors: string[], removedContributors: string[]): Observable<any> {
    return this.http.post(
      this.baseApiUrl + `/${id}/contributor`,
      {
        AddedContributorEmails: addedContributors,
        RemovedContributorEmails: removedContributors,
      },
      { headers: this.headers }
    );
  }

  updateOwner(id: string, userId: string): Observable<any> {
    return this.http.post(this.baseApiUrl + `/${id}/owner`, { userId }, { headers: this.headers });
  }

  getStoryPartRevisions(id: string, part: StoryPart): Observable<Revision[]> {
    return this.http
      .get<IRevisionDto[]>(`${this.baseApiUrl}/${id}/revision?part=${part}`, { headers: this.headers })
      .pipe(map((data) => data.map((item) => this.revisionMapper.map(item))));
  }

  revertToRevistion(revision: Revision): Observable<any> {
    return this.http.post(
      `${this.baseApiUrl}/${revision.storyId}/revision`,
      { revisionId: revision.id },
      { headers: this.headers }
    );
  }

  updateTags(storyId: string, addedTags: string[], removedTags: string[]): Observable<any> {
    return this.http.patch(
      `${this.baseApiUrl}/${storyId}/tags`,
      {
        AddedTags: addedTags,
        RemovedTags: removedTags,
      },
      { headers: this.headers }
    );
  }

  updateStoryState(storyId: string, state: StoryState): Observable<any> {
    return this.http.post(`${this.baseApiUrl}/${storyId}/state`, { State: state }, { headers: this.headers });
  }

  updateStoryVisibility(
    storyId: string,
    visibility: StoryVisibility,
    evidenceAttachmentIds: string[]
  ): Observable<any> {
    const requestBody = {
      Visibility: visibility,
      EvidenceAttachmentIds: evidenceAttachmentIds,
    };
    return this.http.post(`${this.baseApiUrl}/${storyId}/visibility`, requestBody, { headers: this.headers });
  }

  updateStorySharingConditions(storyId: string, sharingConditions: string): Observable<any> {
    const requestBody = {
      sharingConditions: sharingConditions,
    };
    return this.http.post(`${this.baseApiUrl}/${storyId}/sharing-conditions`, requestBody, { headers: this.headers });
  }

  updateStoryPhaseField(updateStoryPhase: UpdateStoryPhase): Observable<any> {
    return this.http.patch(`${this.baseApiUrl}/${updateStoryPhase.id}/phase`, updateStoryPhase, {
      headers: this.headers,
    });
  }

  getStoriesSearchResponse(
    terms: string,
    pageNumber: number = 1,
    resultsPerPage: number = 9,
    sortOrder: string = '',
    filters: string[] = []
  ): Observable<StorySearchResponse> {
    const params = new HttpParams({
      fromObject: {
        terms: terms,
        pageNumber: pageNumber.toString(),
        resultsPerPage: resultsPerPage.toString(),
        sortOrder: sortOrder,
        filters: filters,
      },
    });

    return this.http
      .get<IStorySearchResponseDto>(`${this.baseApiUrl}/search`, {
        headers: this.headers,
        params: params,
      })
      .pipe(
        map(
          (data) =>
            ({
              storySearchResult: this.storySearchResultMapper.map(data.storySearchResult),
              storySearchClients: data.storySearchClients,
            } as StorySearchResponse)
        )
      );
  }

  updateStoryLike(storyLike: StoryLike): Observable<any> {
    const skip409ConflictHeaders = this.setInterceptorHeader(interceptorSkip409ConflictHeader);
    return this.http.patch(`${this.baseApiUrl}/${storyLike.storyId}/like`, storyLike, {
      headers: skip409ConflictHeaders,
    });
  }

  getStoryLikes(storyId: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseApiUrl}/${storyId}/like`, { headers: this.headers });
  }

  getSearchFilters(): Observable<IStoryFilterFieldGroupDto[]> {
    return this.http.get<IStoryFilterFieldGroupDto[]>(`${this.baseApiUrl}/filters`, { headers: this.headers });
  }

  getAttachmentUrl(storyId: string, attachmentId: string): Observable<{ url: string }> {
    const tokenUri = `${this.baseApiUrl}/attachment-token/${attachmentId}`;
    return this.http
      .get<{ token: string }>(tokenUri, { headers: this.headers })
      .pipe(
        map((result) => {
          return {
            url: `${this.baseApiUrl}/${storyId}/attachment/${attachmentId}?token=${result.token}`,
          };
        })
      );
  }

  public uploadAttachment(
    file: File,
    storyId: string,
    attachmentType: AttachmentType,
    label: string = null
  ): Observable<any> {
    const uri = `${this.baseApiUrl}/${storyId}/attachment`;
    const formData: FormData = new FormData();

    formData.append('file', file, file.name);
    formData.append('attachmentType', attachmentType.toString());
    formData.append('label', label);

    return this.http.post(uri, formData, { headers: { interceptorSkipHeader: '' } });
  }

  public deleteAttachment(storyId: string, attachmentId: string): Observable<{}> {
    const uri = `${this.baseApiUrl}/${storyId}/attachment/${attachmentId}`;
    return this.http.delete(uri, { headers: this.headers });
  }

  public updateAttachment(
    storyId: string,
    attachmentId: string,
    label: string = null,
    description: string = null
  ): Observable<{}> {
    const body = {
      label,
      description,
    };

    return this.http.put(`${this.baseApiUrl}/${storyId}/attachment/${attachmentId}`, body);
  }

  async updateStoryCaseStudyNomination(
    storyId: string,
    caseStudyNomination: StoryCaseStudyNomination,
    caseStudyNominationNotes: string
  ): Promise<any> {
    const uri = `${this.baseApiUrl}/${storyId}/case-study-nomination`;
    const body = { caseStudyNomination, caseStudyNominationNotes };
    const headers = { headers: this.headers };

    return this.http.post(uri, body, headers).toPromise();
  }

  createEmbeddedLink(storyId: string, embeddedLink: EmbeddedLink): Observable<string> {
    const uri = `${this.baseApiUrl}/${storyId}/embedded-link/`;

    return this.http.post<string>(uri, embeddedLink, { headers: this.headers });
  }

  updateEmbeddedLink(storyId: string, embeddedLink: EmbeddedLink): Observable<void> {
    const uri = `${this.baseApiUrl}/${storyId}/embedded-link/${embeddedLink.id}`;

    return this.http.put<void>(uri, embeddedLink, { headers: this.headers });
  }

  deleteEmbeddedLink(storyId: string, embeddedLink: EmbeddedLink): Observable<any> {
    const uri = `${this.baseApiUrl}/${storyId}/embedded-link/${embeddedLink.id}`;

    return this.http.delete(uri, { headers: this.headers });
  }

  getEmbeddedLinkMetadata(linkUri: string): Observable<any> {
    const uri = `${this.baseApiUrl}/embedded-link-metadata`;

    return this.http.post(uri, JSON.stringify(linkUri), { headers: this.headers });
  }
}
