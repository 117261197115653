import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Tag } from '../../models/tag';
import { TagCategoryService } from '../../services/tag-category.service';
import { TagCategory } from '../../models/tag-category';

@Component({
  selector: 'app-tag-move',
  templateUrl: './tag-move.component.html',
  styleUrls: ['./tag-move.component.scss'],
})
export class TagMoveComponent implements OnInit {
  message: string;
  selectedCategory: TagCategory;
  categories: TagCategory[];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: TagMoveComponentData,
    private dialogRef: MatDialogRef<TagMoveComponent>,
    private tagCategoryService: TagCategoryService
  ) {}

  ngOnInit() {
    this.message = `Move the ${this.data.tag.value} tag to ...`;
    this.categories = this.data.categories;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  moveTag() {
    this.tagCategoryService.moveTag(this.data.tag, this.selectedCategory).subscribe(() => this.dialogRef.close());
  }
}

export interface TagMoveComponentData {
  tag: Tag;
  categories: TagCategory[];
}
