import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { LoggingService } from './logging/logging.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error) {
    const loggingService = this.injector.get(LoggingService);

    const extraProperties = {
      url: window.location.href,
    };
    // log to App Insights Exceptions
    loggingService.logError(error, extraProperties);

    throw error;
  }
}
