import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Location } from '@angular/common';
import { ViewStoryComponent } from '../view-story/view-story.component';

@Component({
  selector: 'app-view-story-readonly-dialog',
  templateUrl: './view-story-readonly-dialog.component.html',
  styleUrls: ['./view-story-readonly-dialog.component.scss'],
})
export class ViewStoryReadonlyDialogComponent implements OnInit, OnDestroy {
  @ViewChild(ViewStoryComponent, { static: true })
  private viewStoryComponent: ViewStoryComponent;
  private url: string;
  constructor(private dialogRef: MatDialogRef<ViewStoryReadonlyDialogComponent>, private location: Location) {}

  ngOnInit() {
    if (this.viewStoryComponent) {
      this.url = window.location.href;
      this.location.go(`/view-story/${this.viewStoryComponent.dialogModel.id}`);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.url) {
      this.location.back();
    }
  }
}
