import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { StoriesService } from '../../services/stories.service';
import { finalize } from 'rxjs/operators';
import { getInitials } from 'src/app/shared/models/user';

export interface ViewLikesDialogComponentData {
  storyId: string;
}

@Component({
  selector: 'app-view-likes-dialog',
  templateUrl: './view-likes-dialog.component.html',
  styleUrls: ['./view-likes-dialog.component.scss'],
})
export class ViewLikesDialogComponent implements OnInit {
  public isLoading = true;
  public users: { fullName: string; initials: string }[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ViewLikesDialogComponentData,
    private dialogRef: MatDialogRef<ViewLikesDialogComponent>,
    private storyService: StoriesService
  ) {}

  ngOnInit() {
    this.storyService
      .getStoryLikes(this.data.storyId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((res) => {
        this.users = res
          .sort((a, b) => a.localeCompare(b))
          .map((r) => ({
            fullName: r,
            initials: getInitials(r),
          }));
      });
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
