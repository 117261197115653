import { SkipInterceptor } from '../../shared/skip-interceptor';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { IAttachmentDto } from 'src/app/shared/api/attachment-dto';
import { Attachment, AttachmentMapper } from 'src/app/shared/models/attachment';
import { AttachmentType } from 'src/app/shared/models/attachment-type';
import { ConfigurationService } from 'src/app/shared/services/configuration/configuration.service';

export enum UploadStatus {
  Inactive,
  Uploading,
  Error,
  ErrorTooLarge,
  Complete,
  Canceled,
  ErrorUnsupported,
}

export class IFileUploadEvent {
  progress?: number;
  status: UploadStatus;
  attachment?: Attachment;
  file: File;
}

export class FileUploadEventSource {
  private progress = 0;
  private requestSubscription: Subscription;
  private eventSource = new Subject<IFileUploadEvent>();
  event = this.eventSource.asObservable();

  constructor(private file: File, private attachmentMapper: AttachmentMapper, request: Observable<HttpEvent<{}>>) {
    this.requestSubscription = request.subscribe(
      (event) => this.handleEvent(event),
      (error) => this.handleError(error),
      () => this.onComplete()
    );
  }

  private handleEvent(event: any): void {
    if (event.type === HttpEventType.UploadProgress) {
      const percentDone = Math.round(100 * event.loaded) / event.total;
      this.onEvent(percentDone, UploadStatus.Uploading, null);
    } else if (event instanceof HttpResponse) {
      const attachment = this.attachmentMapper.map(event.body as IAttachmentDto);
      this.onEvent(100, UploadStatus.Complete, attachment);
    }
  }

  private handleError(error: HttpErrorResponse): void {
    if (error.status === 413) {
      this.onEvent(100, UploadStatus.ErrorTooLarge, null);
    }
    if (error.status === 415) {
      this.onEvent(100, UploadStatus.ErrorUnsupported, null);
    } else {
      this.onEvent(100, UploadStatus.Error, null);
    }
  }

  private onComplete(): void {
    this.end();
  }

  private onEvent(progress: number, status: UploadStatus, attachment: Attachment) {
    const event = { progress: progress, status: status, attachment: attachment, file: this.file } as IFileUploadEvent;
    if (status === UploadStatus.Error || status === UploadStatus.ErrorTooLarge) {
      this.eventSource.error(event);
    } else {
      this.eventSource.next(event);
    }
    if (status !== UploadStatus.Uploading) {
      this.end();
    }
  }

  cancel() {
    this.onEvent(100, UploadStatus.Canceled, null);
    this.end();
  }

  private end() {
    this.eventSource.complete();
    this.requestSubscription.unsubscribe();
  }
}

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private readonly baseApiUrl: string;
  headers = new HttpHeaders();

  constructor(
    private httpClient: HttpClient,
    private config: ConfigurationService,
    private skipInterceptor: SkipInterceptor,
    private attachmentMapper: AttachmentMapper
  ) {
    this.baseApiUrl = this.config.apiUrl;
  }

  uploadFeaturedImage(featuredImage: File, storyId: string): Observable<any> {
    if (featuredImage.size > 6000000) {
      return throwError({ name: 'filesize' });
    } else {
      const image = new Image();
      image.src = window.URL.createObjectURL(featuredImage);

      const formData: FormData = new FormData();
      formData.append('file', featuredImage, featuredImage.name);
      return this.httpClient.post(`${this.baseApiUrl}/story/${storyId}/featured-image`, formData, {
        headers: this.skipInterceptor.addSkipHeader(this.headers),
      });
    }
  }

  public uploadAttachment(
    file: File,
    storyId: string,
    attachmentType: AttachmentType,
    label: string = null
  ): FileUploadEventSource {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('attachmentType', attachmentType.toString());
    formData.append('label', label);

    const request = new HttpRequest('POST', `${this.baseApiUrl}/story/${storyId}/attachment`, formData, {
      reportProgress: true,
      headers: this.skipInterceptor.addSkipHeader(this.headers),
    });

    return new FileUploadEventSource(file, this.attachmentMapper, this.httpClient.request(request));
  }
}
