import { EmbeddedLink } from '../../../shared/models/embedded-link';
import { EmbeddedLinkType, embeddedLinkTypeDescriptor } from '../../../shared/models/embedded-link-type';
import { Story } from '../../../shared/models/story';
import { StoriesService } from '../../services/stories.service';
import { Component, Inject, Input } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-edit-story-links',
  templateUrl: './edit-story-links.component.html',
  styleUrls: ['./edit-story-links.component.scss'],
})
export class EditStoryLinksComponent {
  public readonly embeddedLinkTypeDescriptor = embeddedLinkTypeDescriptor;

  public embeddedLinkType = EmbeddedLinkType;
  @Input()
  public embeddedLinks: EmbeddedLink[] = [];
  public isValid: boolean;

  isSaving = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly story: Story,
    private readonly dialogRef: MatDialogRef<EditStoryLinksComponent>,
    private readonly storiesService: StoriesService
  ) {
    if (story && story.embeddedLinks) {
      this.embeddedLinks = story.embeddedLinks.map((el) => {
        return { ...el };
      });
    }
  }

  public addEmbeddedLink(type: EmbeddedLinkType): void {
    const embeddedLink = new EmbeddedLink();

    embeddedLink.type = type;
    embeddedLink.label = embeddedLinkTypeDescriptor[type].Label;

    this.embeddedLinks.push(embeddedLink);
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public deleteEmbeddedLink(embeddedLink: EmbeddedLink): void {
    const embeddedLinkIndex = this.embeddedLinks.indexOf(embeddedLink);

    if (embeddedLinkIndex > -1) {
      this.embeddedLinks.splice(embeddedLinkIndex, 1);
    }

    this.onChange();
  }

  public onChange() {
    this.hasChanges = true;
    this.isValid = true;

    for (const embeddedLink of this.embeddedLinks) {
      if (
        !embeddedLink.label ||
        // !embeddedLink.description ||
        !embeddedLink.uri
      ) {
        this.isValid = false;
      }
    }
  }

  public hasChanges: boolean;

  public async save(): Promise<void> {
    try {
      this.isSaving = true;

      for (const embeddedLinkNew of this.embeddedLinks) {
        const embeddedLinkOld = this.story.embeddedLinks.find((el) => el.id == embeddedLinkNew.id);

        if (!embeddedLinkNew.uri.match(/^https?:\/\//)) {
          embeddedLinkNew.uri = 'http://' + embeddedLinkNew.uri;
        }

        if (!embeddedLinkOld) {
          await this.storiesService.createEmbeddedLink(this.story.id, embeddedLinkNew).toPromise();
        } else if (
          embeddedLinkNew.uri !== embeddedLinkOld.uri ||
          embeddedLinkNew.label !== embeddedLinkOld.label ||
          // embeddedLinkNew.description !== embeddedLinkOld.description ||
          embeddedLinkNew.type !== embeddedLinkOld.type
        ) {
          await this.storiesService.updateEmbeddedLink(this.story.id, embeddedLinkNew).toPromise();
        }
      }

      for (const embeddedLinkOld of this.story.embeddedLinks) {
        const embeddedLinkNew = this.embeddedLinks.find((el) => el.id == embeddedLinkOld.id);

        if (!embeddedLinkNew) {
          await this.storiesService.deleteEmbeddedLink(this.story.id, embeddedLinkOld).toPromise();
        }
      }
    } finally {
      this.isSaving = false;
      this.dialogRef.close();
    }
  }

  get types() {
    return Object.values(this.embeddedLinkType)
      .filter((value) => isNaN(Number(value)) === false)
      .filter((value) => this.embeddedLinkTypeDescriptor[value].Metadata);
  }
}
