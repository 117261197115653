import { IAttachmentDto } from '../api/attachment-dto';
import { IMapper } from '../mapper';
import { AttachmentType } from './attachment-type';
import { Injectable } from '@angular/core';

export class Attachment {
  id: string;
  name: string;
  label: string;
  attachmentType: AttachmentType;
  createdDate: Date;
  createdById: string;
  storyId: string;
}

@Injectable({ providedIn: 'root' })
export class AttachmentMapper implements IMapper<IAttachmentDto, Attachment> {
  map(source: IAttachmentDto): Attachment {
    const result = new Attachment();
    result.id = source.id;
    result.label = source.label;
    result.name = source.name;
    result.attachmentType = source.attachmentType;
    result.createdDate = new Date(source.createdDate);
    result.createdById = source.createdById;
    result.storyId = source.storyId;
    return result;
  }
}
