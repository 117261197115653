<div class="newStoryDialogWrapper">
  <div class="dialogTitle">
    <h3 mat-dialog-title>Add new story</h3>
    <button mat-button class="storyDialogButton" (click)="onCancel()"><mat-icon>clear</mat-icon></button>
  </div>
  <div mat-dialog-content>
    <form [formGroup]="newStoryForm">
      <mat-form-field class="newStoryField">
        <mat-label>Title</mat-label>
        <input matInput formControlName="title" cdkFocusInitial #storyTitle [attr.maxlength]="storyTitleCharacterLimit" />
        <mat-hint align="end">{{ storyTitle.value?.length || 0 }} / {{ storyTitleCharacterLimit }}</mat-hint>
      </mat-form-field>
      <mat-form-field class="newStoryField">
        <mat-label>Client</mat-label>
        <input matInput formControlName="clientName" #clientName [attr.maxlength]="storyTitleCharacterLimit" />
        <mat-hint align="end">{{ clientName.value?.length || 0 }} / {{ storyTitleCharacterLimit }}</mat-hint>
      </mat-form-field>
      <mat-form-field class="newStoryFieldShort">
        <mat-label>Total Contract Value</mat-label>
        <input type="number" matInput formControlName="initialValue" />
        <span matPrefix>$&nbsp;</span>
      </mat-form-field>
      <mat-form-field class="newStoryFieldShort">
        <mat-label class="blue">Owner</mat-label>
        <input matInput formControlName="owner" />
      </mat-form-field>
    </form>
  </div>
  <div id="dialogButtonsContainer" mat-dialog-actions>
    <button
      class="storyDialogButton"
      mat-button
      color="primary"
      [disabled]="!newStoryForm.valid"
      (click)="createStory()"
    >
      CREATE
    </button>
    <button class="storyDialogButton" mat-button (click)="onCancel()">
      CANCEL
    </button>
  </div>
</div>
