import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interceptorSkipHeader } from './constants';

@Injectable()
export class SkipInterceptor {
  constructor() {}

  addSkipHeader(headers: HttpHeaders): HttpHeaders {
    return headers.append(interceptorSkipHeader, '');
  }
}
