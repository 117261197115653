import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigurationService } from '../configuration/configuration.service';
import { map, tap } from 'rxjs/operators';
import { ITagWithCategoryDto } from '../../api/tag-with-category-dto';
import { TagWithCategory, TagWithCategoryMapper } from '../../models/tag-with-category';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  baseApiUrl: string;
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  allTags: TagWithCategory[];

  constructor(
    private http: HttpClient,
    private config: ConfigurationService,
    private tagMapper: TagWithCategoryMapper
  ) {
    this.baseApiUrl = this.config.apiUrl + '/tag';
  }

  searchTags(query: string, maxResults: number = 10): Observable<TagWithCategory[]> {
    const params = new HttpParams({
      fromObject: {
        query: query,
        maxResults: maxResults.toString(),
      },
    });

    return this.http
      .get<ITagWithCategoryDto[]>(`${this.baseApiUrl}/search`, {
        headers: this.headers,
        params: params,
      })
      .pipe(map((tags) => tags.map((item) => this.tagMapper.map(item))));
  }

  getAllTags(refresh: boolean = false): Observable<TagWithCategory[]> {
    if (!refresh && this.allTags) {
      return of(this.allTags);
    }
    return this.http
      .get<ITagWithCategoryDto[]>(`${this.baseApiUrl}/`, {
        headers: this.headers,
      })
      .pipe(
        map((tags) => tags.map((item) => this.tagMapper.map(item))),
        tap((tags) => (this.allTags = tags))
      );
  }
}
