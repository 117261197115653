<div>
  <p class="mat-hint">Sharing Conditions</p>
  <mat-checkbox color="primary" [(ngModel)]="sharingConditionsChecked">
    Are there any conditions we need to adhere to when sharing this story
  </mat-checkbox>
  <mat-form-field class="field" *ngIf="sharingConditionsChecked">
    <textarea
      matInput
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="4"
      cdkAutosizeMaxRows="10"
      [(ngModel)]="sharingConditions"
      placeholder="Please specify sharing conditions"
    ></textarea>
  </mat-form-field>
  <div id="dialog-buttons-container" mat-dialog-actions>
    <button color="primary" mat-button (click)="onSaveClicked()">
      <span>SAVE</span>
    </button>
    <button mat-button (click)="cancel()">
      <span>CANCEL</span>
    </button>
  </div>
</div>
