import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClientMessageService } from './../../../shared/services/client-message.service';
import { StorySortField } from './../../../shared/models/story-sort-field';
import { SortOrder } from './../../../shared/components/sort-field/sort-field.component';
import { StorySearchResult } from './../../../shared/models/story-search-result';
import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { StoriesService } from '../../services/stories.service';
import { PagedResult } from '../../../shared/models/page-result';
import { UserService } from '../../..//shared/services/user/user.service';
import { User } from '../../../shared/models/user';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import {
  FilterFieldViewModel,
  FilterGroupViewModel,
  StorySearchFilterComponent,
} from './story-search-filter/story-search-filter.component';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { StoryActiveFiltersComponent } from './story-active-filters/story-active-filters.component';
import { StorySearchResponse } from 'src/app/shared/models/story-search-response';
import { StorySearchClient } from 'src/app/shared/models/story-search-client';
import { CLIENTS_GROUP_LABEL } from './story-clients-selection/story-clients-selection.component';
import { StoryFilterGroupType } from 'src/app/shared/models/story-filter-field-group-type';
import { StoryFilterFieldType } from 'src/app/shared/models/story-filter-field-type';
import { IStoryFilterFieldDto } from 'src/app/shared/api/story-filter-field-group-dto';

const SortFieldSeperator = '|';
const DescendingMarker = '-';
const FilterFieldSeperator = ';';
const FilterFieldTypeSeperator = '|';
const PagingSeperator = ';';

class SortFieldContainer {
  constructor(public label: string, public order: SortOrder, public sortField: StorySortField) {}
}

@Component({
  selector: 'app-story-search',
  templateUrl: './story-search.component.html',
  styleUrls: ['./story-search.component.scss'],
})
export class StorySearchComponent implements OnInit, OnDestroy {
  mediaSub: Subscription;
  isDeviceLessThanLarge: boolean;

  showMessage = false;
  storiesCount: number;
  searchText = '';
  inputText = '';
  message = '';
  currentUser: User;
  storySearchResult: PagedResult<StorySearchResult>;
  storySearchClients: StorySearchClient[];
  public isLoading = true;

  public selectedSortField: SortFieldContainer;

  public publishDateDescField = new SortFieldContainer(
    'Publish Date: Newest to Oldest',
    SortOrder.Descending,
    StorySortField.DatePublished
  );

  public publishDateAscField = new SortFieldContainer(
    'Publish Date: Oldest to Newest',
    SortOrder.Ascending,
    StorySortField.DatePublished
  );

  public dateCreatedDescField = new SortFieldContainer(
    'Date Created: Newest to Oldest',
    SortOrder.Descending,
    StorySortField.DateCreated
  );

  public dateCreatedAscField = new SortFieldContainer(
    'Date Created: Oldest to Newest',
    SortOrder.Ascending,
    StorySortField.DateCreated
  );

  public valueDescField = new SortFieldContainer(
    'Total Contract Value: Highest to Lowest',
    SortOrder.Descending,
    StorySortField.Value
  );

  public valueAscField = new SortFieldContainer(
    'Total Contract Value: Lowest to Highest',
    SortOrder.Ascending,
    StorySortField.Value
  );

  public saleClosedDateDescField = new SortFieldContainer(
    'Sale Closed Date: Newest to Oldest',
    SortOrder.Descending,
    StorySortField.SaleClosedDate
  );

  public saleClosedDateAscField = new SortFieldContainer(
    'Sale Closed Date: Oldest to Newest',
    SortOrder.Ascending,
    StorySortField.SaleClosedDate
  );

  public deliveryStartDateDescField = new SortFieldContainer(
    'Delivery Start: Newest to Oldest',
    SortOrder.Descending,
    StorySortField.DeliveryStartDate
  );

  public deliveryStartDateAscField = new SortFieldContainer(
    'Delivery Start: Oldest to Newest',
    SortOrder.Ascending,
    StorySortField.DeliveryStartDate
  );

  public deliveryCompletedDateDescField = new SortFieldContainer(
    'Delivery Completed: Newest to Oldest',
    SortOrder.Descending,
    StorySortField.DeliveryCompletedDate
  );

  public deliveryCompletedDateAscField = new SortFieldContainer(
    'Delivery Completed: Oldest to Newest',
    SortOrder.Ascending,
    StorySortField.DeliveryCompletedDate
  );

  resultCount = 0;
  pageSize = 24;
  pageIndex = 0;
  pageSizeOptions: number[] = [1, 2, 3, 6, 9, 12, 24, 48, 96];

  private isFirstSearch = false;
  private criteriaChangedSubject = new BehaviorSubject<{ inputText: string; isFirstSearch: boolean }>(null);

  @ViewChild(StorySearchFilterComponent, { static: true })
  filterComponent: StorySearchFilterComponent;
  @ViewChild(StoryActiveFiltersComponent, { static: true })
  activeFiltersComponent: StoryActiveFiltersComponent;

  constructor(
    private storiesService: StoriesService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private messageService: ClientMessageService,
    private titleService: Title,
    public mediaObserver: MediaObserver
  ) {}

  ngOnInit() {
    this.userService.getUser().subscribe((user: User | {}) => {
      if (Object.keys(user).length !== 0) {
        this.currentUser = user as User;
      }
    });

    if (this.filterComponent) {
      this.filterComponent.initialize(() => {
        this.subscribeToCriteriaChangedSubject();
        this.doPostFilterInitializeTasks();
      });
    }

    if (this.activeFiltersComponent) {
      this.activeFiltersComponent.initialize(this.filterComponent ? this.filterComponent.checkedFilters : []);
    }

    this.mediaSub = this.mediaObserver.asObservable().subscribe((res: MediaChange[]) => {
      this.isDeviceLessThanLarge = res.find((x) => x.mqAlias == 'lt-lg') !== undefined;
    });
  }

  ngOnDestroy() {
    this.mediaSub.unsubscribe();
  }

  private doPostFilterInitializeTasks() {
    // We are only interested in params after initial load as we will be rewritting them
    this.activatedRoute.fragment.subscribe((fragment) => {
      const params = new HttpParams({ fromString: fragment });
      const term = params.get('term');
      const filters = params.get('filter');
      const sortOrder = params.get('sortorder');
      const paging = params.get('paging');

      this.isFirstSearch = !(term || filters || sortOrder);

      // Set any default filters or sort oder here
      this.setFilterFromQueryString(filters);
      this.setSortFromQueryString(sortOrder);
      this.setSearchTextFromQueryString(term);
      this.setPagingFromQueryParams(paging);

      this.criteriaChangedSubject.next({ inputText: this.inputText, isFirstSearch: this.isFirstSearch });

      this.activeFiltersComponent.setFilters(this.filterComponent.checkedFilters);

      this.filterComponent.selectedClients = this.filterComponent.allFields
        .filter((f) => f.groupLabel === CLIENTS_GROUP_LABEL && f.checked)
        .map((f) => new StorySearchClient(f.label));
    });
  }

  private setFilterFromQueryString(queryString: string) {
    if (!this.isFirstSearch)
      // Do not override the default filtering by Query Params
      this.filterComponent.allFields.forEach((f) => (f.checked = false));

    if (queryString) {
      const values = queryString.split(FilterFieldSeperator);
      values.forEach((v) => {
        const fieldValues = v.split(FilterFieldTypeSeperator, 2);
        if (fieldValues.length === 2) {
          const groupLabel = fieldValues[0];
          const fieldLabel = fieldValues[1];
          const field = this.filterComponent.allFields.find(
            (f) =>
              f.label.trim().toLowerCase() === fieldLabel.trim().toLowerCase() &&
              f.groupLabel.trim().toLowerCase() === groupLabel.trim().toLowerCase()
          );
          if (field) {
            field.checked = true;
          } else {
            const group = this.filterComponent.allFilters.find((f) => f.label === CLIENTS_GROUP_LABEL);
            if (!group) {
              this.filterComponent.allFilters.push(
                new FilterGroupViewModel({
                  groupType: StoryFilterGroupType.ClientName,
                  label: CLIENTS_GROUP_LABEL,
                  fields: [],
                })
              );
            }
            group.fields.push(
              new FilterFieldViewModel(
                StoryFilterGroupType.ClientName,
                CLIENTS_GROUP_LABEL,
                StoryFilterFieldType.Custom,
                fieldLabel,
                fieldLabel,
                true
              )
            );
          }
        }
      });
    }
  }

  private getQueryStringFromFilters(): string {
    const filters: string[] = [];
    this.filterComponent.checkedFilters.forEach((f) =>
      filters.push(`${f.groupLabel}${FilterFieldTypeSeperator}${f.label}`)
    );
    return filters.join(FilterFieldSeperator);
  }

  private setSortFromQueryString(queryString: string) {
    let hasSortSet = false;

    if (queryString) {
      const values = queryString.split(SortFieldSeperator, 1); // Only allow sorting by one field

      if (values.length === 1) {
        const firstSortValue = values[0];

        const fieldName = firstSortValue.indexOf(DescendingMarker) === 0 ? firstSortValue.substr(1) : firstSortValue;
        const field = this.allSortFields.find((f) => fieldName.trim().toLowerCase() === f.label.trim().toLowerCase());

        if (field) {
          this.selectedSortField = field;
          hasSortSet = true;
        }
      }
    }

    if (!hasSortSet) {
      // default sort
      this.selectedSortField = this.publishDateDescField;
    }
  }

  private getQueryStringFromSort(): string {
    const sortFields: string[] = [];
    const sign = this.selectedSortField.order === SortOrder.Descending ? DescendingMarker : '';
    sortFields.push(`${sign}${this.selectedSortField.label}`);
    return sortFields.join(SortFieldSeperator);
  }

  private setSearchTextFromQueryString(queryString: string) {
    this.inputText = '';
    if (queryString) {
      this.inputText = queryString;
    }
  }

  private getQueryStringFromSearch(): string {
    return this.inputText;
  }

  private setPagingFromQueryParams(queryString: string) {
    if (queryString) {
      const values = queryString.split(PagingSeperator);
      if (values.length === 2) {
        const pageSize = Number(values[0]);
        const pageIndex = Number(values[1]);

        if (!isNaN(pageSize)) {
          this.pageSize = pageSize;
        }

        if (!isNaN(pageIndex)) {
          this.pageIndex = pageIndex;
        }
      }
    }
  }

  private getQueryStringFromPaging(): string {
    return `${this.pageSize}${PagingSeperator}${this.pageIndex}`;
  }

  private pushQueryParamsToRoute() {
    const params = new HttpParams({
      fromObject: {
        term: this.getQueryStringFromSearch(),
        filter: this.getQueryStringFromFilters(),
        sortorder: this.getQueryStringFromSort(),
        paging: this.getQueryStringFromPaging(),
      },
    });

    const fragment = params.toString();

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      fragment: fragment,
    });
  }

  public initiateSearch(resetPaging: boolean = true) {
    if (resetPaging) {
      this.pageIndex = 0;
    }
    this.pushQueryParamsToRoute();
  }

  private subscribeToCriteriaChangedSubject() {
    this.criteriaChangedSubject
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((request) => {
          this.isLoading = true;
          this.searchText = request.inputText;
          this.isFirstSearch = request.isFirstSearch;
          return this.getSearchStoryResult(request.inputText);
        })
      )
      .subscribe((result: StorySearchResponse) => {
        this.showMessage = true;
        this.storySearchResult = result.storySearchResult;
        this.storySearchClients = result.storySearchClients;
        this.resultCount = result.storySearchResult.rowCount;
        this.isLoading = false;

        if (this.isFirstSearch) {
          this.message = `Most recently published stories`;
        } else {
          this.message = `${this.storySearchResult.rowCount} stories found matching the criteria!`;
        }

        if (this.searchText) {
          this.titleService.setTitle(`${this.searchText} - StoryHub`);
        }
      });
  }

  private getSearchStoryResult(terms: string): Observable<StorySearchResponse> {
    // construct the api sort string
    const sortFields: string[] = [];
    const sign = this.selectedSortField.order === SortOrder.Descending ? DescendingMarker : '';
    sortFields.push(`${sign}${this.selectedSortField.sortField}`);

    // construct the api filter string
    const filterFields = this.filterComponent.checkedFilters.map(
      (f) =>
        `${f.groupType}${FilterFieldSeperator}${f.groupLabel}` +
        `${FilterFieldSeperator}${f.fieldType}${FilterFieldSeperator}${f.value}`
    );

    return this.storiesService.getStoriesSearchResponse(
      terms,
      this.pageIndex + 1,
      this.pageSize,
      sortFields.join(SortFieldSeperator),
      filterFields
    );
  }

  get allSortFields(): SortFieldContainer[] {
    return [
      this.publishDateDescField,
      this.publishDateAscField,
      this.valueDescField,
      this.valueAscField,
      this.saleClosedDateDescField,
      this.saleClosedDateAscField,
      this.deliveryStartDateDescField,
      this.deliveryStartDateAscField,
      this.deliveryCompletedDateDescField,
      this.deliveryCompletedDateAscField,
      this.dateCreatedDescField,
      this.dateCreatedAscField,
    ];
  }

  sortChangeRequested(sortFieldContainer: SortFieldContainer) {
    this.initiateSearch();
  }

  public pageEventHappened(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.initiateSearch(false);
  }

  public filtersChanged() {
    this.activeFiltersComponent.setFilters(this.filterComponent.checkedFilters);
    this.initiateSearch();
  }

  public activeFiltersChanged() {
    this.filterComponent.uncheckItem(this.activeFiltersComponent.activeFilters);
    this.initiateSearch();
  }

  public copyUrlToClipboard(): void {
    const el = document.createElement('textarea');
    el.value = window.location.href;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.messageService.showClientInfoMessage('URL for this search has been copied to the clipboard');
  }

  public copyResultSectionToClipboard(): void {
    const el = document.createElement('textarea');
    el.value = `<iframe src="${this.generateIframeUrl()}" width="310" height="488" style="border:none;"></iframe>`;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.messageService.showClientInfoMessage(
      `URL for embedding this search in an iFrame has been copied to clipboard.
      Change the items per page and copy again if you want to control number of stories that display.
      For 1x1  view Set iFrame width to 310 pixels and height to 488 pixels.
      For 2x2  view Set iFrame width to 490 pixels and height to 910 pixels.
      For 3x3  view Set iFrame width to 610 pixels and height to 1348 pixels.`,
      10000
    );
  }

  private generateIframeUrl(): string {
    const hashIndex = document.URL.indexOf('#');
    const documentUrl =
      hashIndex > -1
        ? `${document.URL.slice(0, hashIndex)}-iframe${document.URL.slice(hashIndex)}`
        : `${document.URL}-iframe`;

    return documentUrl;
  }
}
