import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../../../shared/models/user';

@Component({
  selector: 'app-contributors-view-mode',
  templateUrl: './contributors-view-mode.component.html',
  styleUrls: ['./contributors-view-mode.component.scss'],
})
export class ContributorsViewModeComponent implements OnInit {
  @Input()
  contributors: User[];
  @Input()
  isReadOnly: boolean;
  @Output()
  switchToEdit = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
