<div class="releaseNoteEditorDialogWrapper">
  <div class="dialogTitle">
    <h3 mat-dialog-title>Add new release note</h3>
    <button mat-button class="releaseNoteDialogButton" (click)="onCancel()"><mat-icon>clear</mat-icon></button>
  </div>
  <div mat-dialog-content>
    <form [formGroup]="newReleaseNoteForm">
      <mat-form-field class="newReleaseNoteField">
        <mat-label>Title</mat-label>
        <input
          matInput
          formControlName="title"
          cdkFocusInitial
          #releaseNoteTitle
          maxlength="{{releaseNoteNameCharacterLimit}}"
        />
        <mat-hint align="end">{{ releaseNoteTitle.value?.length || 0 }} / {{ releaseNoteNameCharacterLimit }}</mat-hint>
      </mat-form-field>
      <mat-form-field class="newReleaseNoteField">
        <mat-label>Version</mat-label>
        <input matInput formControlName="versionId" #versionId maxlength="{{releaseNoteNameCharacterLimit}}" />
        <mat-hint align="end">{{ versionId.value?.length || 0 }} / {{ releaseNoteNameCharacterLimit }}</mat-hint>
      </mat-form-field>
      <mat-form-field class="newReleaseNoteFieldShort">
        <mat-label>Creator</mat-label>
        <input matInput formControlName="creator" value="// Find user text field" />
      </mat-form-field>
      <mat-form-field class="newReleaseNoteFieldShort">
        <mat-label>Implementor</mat-label>
        <input matInput formControlName="implementer" value="// Find user text field" />
      </mat-form-field>
      <mat-form-field class="newReleaseNoteField">
        <mat-label>Content</mat-label>
        <textarea matInput formControlName="content" #content></textarea>
        <mat-hint align="end">{{ content.value?.length || 0 }}</mat-hint>
      </mat-form-field>
    </form>
  </div>
  <div id="dialogButtonsContainer" mat-dialog-actions>
    <button
      *ngIf="!releaseNoteId"
      class="releaseNoteDialogButton"
      mat-button
      color="primary"
      [disabled]="!newReleaseNoteForm.valid"
      (click)="onDoCreate()"
    >
      CREATE
    </button>
    <button *ngIf="releaseNoteId" class="releaseNoteDialogButton" mat-button (click)="onEditCommit()">EDIT</button>
    <button class="releaseNoteDialogButton" mat-button (click)="onCancel()">CANCEL</button>
    <button *ngIf="releaseNoteId" class="releaseNoteDialogButton" mat-button (click)="onDelete()">DELETE</button>
  </div>
</div>
