import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

export const LandingUrlKey = 'landingUrl';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(private msalService: MsalService) {}

  ngOnInit() {
    this.msalService.loginRedirect();
  }
}
