<div class="icon"><mat-icon>description</mat-icon></div>
<div class="information">
  <div class="name">{{ attachment.name }}</div>
  <div class="status" *ngIf="justUploaded(); else longAgo">Uploaded just now</div>
  <ng-template #longAgo>
    <div class="status">Modified {{ attachment.createdDate | date: SHORT_DATE_FORMAT }}</div>
  </ng-template>
</div>
<div class="actions">
  <mat-icon class="menu" [matMenuTriggerFor]="attachmentMenu">more_vert</mat-icon>
</div>

<mat-menu #attachmentMenu>
  <a mat-menu-item (click)="downloadAttachment()">Download</a>
  <mat-divider *ngIf="!isReadOnly && canDelete"></mat-divider>
  <button mat-menu-item *ngIf="!isReadOnly && canDelete" (click)="onDeleteClicked()">Delete</button>
</mat-menu>
