import { Component, OnInit } from '@angular/core';
import { StoriesService } from '../../services/stories.service';
import { Observable } from 'rxjs';
import { PagedResult } from 'src/app/shared/models/page-result';
import { StorySearchResult } from 'src/app/shared/models/story-search-result';
import { finalize, switchMap, map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { IStoryFilterFieldGroupDto } from 'src/app/shared/api/story-filter-field-group-dto';
import { StorySortField } from 'src/app/shared/models/story-sort-field';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { StorySearchResponse } from 'src/app/shared/models/story-search-response';

const PagingSeperator = ';';

const DescendingMarker = '-';

@Component({
  selector: 'app-story-search-iframe',
  templateUrl: './story-search-iframe.component.html',
  styleUrls: ['./story-search-iframe.component.scss'],
})
export class StorySearchIframeComponent implements OnInit {
  storySearchResult: PagedResult<StorySearchResult>;
  loading = true;
  resultCount = 0;
  pageSize = 9;
  pageIndex = 0;
  selectedFilters: string[];
  filters: string;
  term: string;
  sortOrder: string;

  pageSizeOptions = [];

  constructor(private storiesService: StoriesService, private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.storiesService
      .getSearchFilters()
      .pipe(
        switchMap((filters) =>
          this.activatedRoute.fragment.pipe(map((fragment) => this.setFilterProperties(fragment, filters)))
        )
      )
      .subscribe(() => this.refreshStories());
  }

  public refreshStories() {
    this.getSearchStoryResponse(this.term)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((result: StorySearchResponse) => {
        this.storySearchResult = result.storySearchResult;
        this.resultCount = result.storySearchResult.rowCount;
      });
  }

  private getSearchStoryResponse(terms: string): Observable<StorySearchResponse> {
    return this.storiesService.getStoriesSearchResponse(
      terms,
      this.pageIndex + 1,
      this.pageSize,
      this.sortOrder,
      this.selectedFilters
    );
  }

  public pageEventHappened(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pushQueryParamsToRoute();
  }

  private setFilterProperties(fragment: string, allFilters: IStoryFilterFieldGroupDto[]) {
    const params = new HttpParams({ fromString: fragment });
    const paging = params.get('paging') ? params.get('paging') : '';
    this.filters = params.get('filter') ? params.get('filter') : '';
    this.term = params.get('term') ? params.get('term') : '';
    this.sortOrder = this.convertSortOrder(params.get('sortorder'));
    this.setPagingFromQueryParams(paging);
    this.selectedFilters = this.getSelectedFiltersFromQuery(allFilters, this.filters);
  }

  private getQueryStringFromPaging(): string {
    return `${this.pageSize}${PagingSeperator}${this.pageIndex}`;
  }

  private setPagingFromQueryParams(queryString: string) {
    if (queryString) {
      const values = queryString.split(PagingSeperator);
      if (values.length === 2) {
        const pageSize = Number(values[0]);
        const pageIndex = Number(values[1]);

        if (!isNaN(pageSize)) {
          this.pageSize = pageSize;
        }

        if (!isNaN(pageIndex)) {
          this.pageIndex = pageIndex;
        }
      }
    }
  }

  private pushQueryParamsToRoute() {
    const params = new HttpParams({
      fromObject: {
        term: this.term,
        filter: this.filters,
        sortorder: this.sortOrder,
        paging: this.getQueryStringFromPaging(),
      },
    });

    const fragment = params.toString();

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      fragment: fragment,
    });
    this.loading = true;
    this.refreshStories();
  }

  private getSelectedFiltersFromQuery(allFilters: IStoryFilterFieldGroupDto[], queryParameterString: string) {
    if (!queryParameterString) return ['3;State;3;Published'];

    var fitlterQueries = queryParameterString.split(';');
    return fitlterQueries.map((q) => {
      var query = q.split('|');
      return this.getFilterString(allFilters, query[0], query[1]);
    });
  }

  private getFilterString(allFilters: IStoryFilterFieldGroupDto[], filterLabel: string, fieldLabel: string) {
    const filter = allFilters.find((f) => f.label === filterLabel);
    const field = filter.fields.find((f) => f.label === fieldLabel);

    return `${filter.groupType};${filter.label};${field.fieldType};${field.value}`;
  }

  private convertSortOrder(sortOrder: string) {
    if (!sortOrder) {
      // Default to publish date descending order
      return `${DescendingMarker}${StorySortField.DatePublished}`;
    }

    let orderPrefix = '';
    if (sortOrder[0] === DescendingMarker) {
      sortOrder = sortOrder.substring(1);
      orderPrefix = DescendingMarker;
    }

    switch (sortOrder) {
      case 'Publish Date':
      case 'Publish Date: Oldest to Newest':
      case 'Publish Date: Newest to Oldest':
        return `${orderPrefix}${StorySortField.DatePublished}`;
      case 'Sale Closed':
      case 'Sale Closed Date: Newest to Oldest':
      case 'Sale Closed Date: Oldest to Newest':
        return `${orderPrefix}${StorySortField.SaleClosedDate}`;
      case 'Delivery Completed':
      case 'Delivery Completed: Newest to Oldest':
      case 'Delivery Completed: Oldest to Newest':
        return `${orderPrefix}${StorySortField.DeliveryCompletedDate}`;
      case 'Delivery Start':
      case 'Delivery Start: Newest to Oldest':
      case 'Delivery Start: Oldest to Newest':
        return `${orderPrefix}${StorySortField.DeliveryStartDate}`;
      case 'Date Created: Newest to Oldest':
      case 'Date Created: Oldest to Newest':
        return `${orderPrefix}${StorySortField.DateCreated}`;
      case 'Value':
      case 'Total Contract Value: Highest to Lowest':
      case 'Total Contract Value: Lowest to Highest':
        return `${orderPrefix}${StorySortField.Value}`;
      default:
        return `${orderPrefix}${sortOrder}`;
    }
  }
}
