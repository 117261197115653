/*
 * Date and time formats for use with the Angular `date` pipe.
 *
 * https://angular.io/api/common/DatePipe#custom-format-options
 *
 * WARNING: These formats are not compatible with Moment.js formats.
 */

export const DATE_FORMAT: string = 'dd/MM/yyyy';

export const DATE_TIME_FORMAT: string = 'dd/MM/yyyy - hh:mm';

export const SHORT_DATE_FORMAT: string = 'MMM dd';
