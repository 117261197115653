import { Component, OnDestroy, OnInit } from '@angular/core';
import { TagCategoryService, TagEventType, TagEvent } from '../../services/tag-category.service';
import { Tag } from '../../models/tag';
import { TagCategory } from '../../models/tag-category';
import { finalize, takeUntil } from 'rxjs/operators';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TagRenameComponent } from '../tag-rename/tag-rename.component';
import { Subject } from 'rxjs';
import { ClientMessageService } from '../../../shared/services/client-message.service';
import {
  TagReplaceDeleteComponent,
  TagReplaceDeleteComponentData,
  FormAction,
} from '../tag-replace-delete/tag-replace-delete.component';
import { TagMoveComponent } from '../tag-move/tag-move.component';
import { UntypedFormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { validTagRegex } from 'src/app/shared/constants';

const renameTagDialogWidth = '600px';
const renameTagDialogHeight = '300px';
const replaceDeleteTagDialogWidth = '600px';
const replaceDeleteTagDialogHeight = '360px';
const moveTagDialogWidth = '600px';
const moveTagDialogHeight = '260px';

interface TagLocation {
  categoryIndex: number;
  tagIndex: number;
}

@Component({
  selector: 'app-tag-management-home',
  templateUrl: './tag-management-home.component.html',
  styleUrls: ['./tag-management-home.component.scss'],
})
export class TagManagementHomeComponent implements OnInit, OnDestroy {
  loading = true;
  uncategorisedTags: Tag[];
  categories: TagCategory[];
  private ngUnsubscribe = new Subject();
  addCategorisedTag = false;
  addCategorisedTagCategory: TagCategory;

  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  addedTags: string[] = [];
  addTagControl = new UntypedFormControl();

  constructor(
    private tagCategoryService: TagCategoryService,
    private clientMessageService: ClientMessageService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.fetchTagsAndCategories();

    this.addTagControl.valueChanges.subscribe((value) => {
      const newValue = value ? value.replace(validTagRegex, '') : '';
      if (newValue !== this.addTagControl.value) {
        this.addTagControl.setValue(newValue);
      }
    });

    this.tagCategoryService.tagEventSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((tagEvent) => {
      const tagLocation = this.findTagLocation(tagEvent);
      switch (tagEvent.event) {
        case TagEventType.Rename:
          if (tagLocation.categoryIndex > -1) {
            this.categories[tagLocation.categoryIndex].tags[tagLocation.tagIndex].value = tagEvent.tag.value;
          } else {
            this.uncategorisedTags[tagLocation.tagIndex].value = tagEvent.tag.value;
          }
          this.clientMessageService.showClientInfoMessage('The tag has been successfully renamed');
          break;
        case TagEventType.Delete:
        case TagEventType.Replace:
          if (tagLocation.categoryIndex > -1) {
            this.categories[tagLocation.categoryIndex].tags.splice(tagLocation.tagIndex, 1);
          } else {
            this.uncategorisedTags.splice(tagLocation.tagIndex, 1);
          }
          if (tagEvent.event === TagEventType.Replace) {
            this.clientMessageService.showClientInfoMessage('The tag has been successfully replaced');
          } else {
            this.clientMessageService.showClientInfoMessage('The tag has been successfully deleted');
          }
          break;
        case TagEventType.Move:
          if (tagLocation.categoryIndex > -1) {
            this.uncategorisedTags.splice(tagLocation.tagIndex, 1);
            this.categories[tagLocation.categoryIndex].tags.push(tagEvent.tag);
            this.categories[tagLocation.categoryIndex].tags.sort((a, b) => a.value.localeCompare(b.value));
          }
          this.clientMessageService.showClientInfoMessage('The tag has been successfully moved');
          break;
      }
    });
  }

  findTagLocation(tagEvent: TagEvent): TagLocation {
    const categoryIndex = this.categories.findIndex((c) => c.id === tagEvent.tag.categoryId);
    let foundTagIndex = this.uncategorisedTags.findIndex((t) => t.id === tagEvent.tag.id);
    if (foundTagIndex > -1 && tagEvent.event !== TagEventType.Move) {
      return { categoryIndex: -1, tagIndex: foundTagIndex };
    } else if (tagEvent.event === TagEventType.Move) {
      return { categoryIndex: categoryIndex, tagIndex: foundTagIndex };
    }
    let foundCategoryIndex = 0;
    for (const len = this.categories.length; foundCategoryIndex < len; foundCategoryIndex++) {
      foundTagIndex = this.categories[foundCategoryIndex].tags.findIndex((t) => t.id === tagEvent.tag.id);
      if (foundTagIndex > -1) {
        break;
      }
    }
    return { categoryIndex: foundCategoryIndex, tagIndex: foundTagIndex };
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private fetchTagsAndCategories() {
    this.tagCategoryService
      .getAllTagsAndCategories()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((result) => {
        this.uncategorisedTags = result.uncategorised;
        this.categories = result.categorised;
      });
  }

  renameTag(tag: Tag) {
    this.dialog.open(TagRenameComponent, {
      width: renameTagDialogWidth,
      height: renameTagDialogHeight,
      data: tag,
    });
  }

  replaceTag(tag: Tag) {
    this.dialog.open(TagReplaceDeleteComponent, {
      width: replaceDeleteTagDialogWidth,
      height: replaceDeleteTagDialogHeight,
      data: {
        tag: tag,
        isUncategorisedTag: true,
        formAction: FormAction.Replace,
        categories: this.categories,
      } as TagReplaceDeleteComponentData,
    });
  }

  deleteTag(tag: Tag, isUncategorised: boolean) {
    this.dialog.open(TagReplaceDeleteComponent, {
      width: replaceDeleteTagDialogWidth,
      height: replaceDeleteTagDialogHeight,
      data: {
        tag: tag,
        isUncategorisedTag: isUncategorised,
        formAction: FormAction.Delete,
        categories: this.categories,
      } as TagReplaceDeleteComponentData,
    });
  }

  moveTag(tag: Tag) {
    this.dialog.open(TagMoveComponent, {
      width: moveTagDialogWidth,
      height: moveTagDialogHeight,
      data: {
        tag: tag,
        categories: this.categories,
      } as TagReplaceDeleteComponentData,
    });
  }

  toggleAddCategorisedTag(category: TagCategory) {
    this.addCategorisedTag = !this.addCategorisedTag;
    this.addCategorisedTagCategory = category;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    this.addValue(value);

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.addTagControl.setValue(null);
  }

  addValue(value: string) {
    this.addedTags.push(value.trim());
  }

  removeValue(tag: string): void {
    this.addedTags = this.addedTags.filter((t) => t !== tag);
  }

  cancel(): void {
    this.addedTags = [];
    this.addTagControl.setValue(null);
    this.addCategorisedTag = false;
  }

  saveTags(category: TagCategory) {
    const newTags = this.addedTags.map((t) => {
      return { value: t, categoryId: category.id } as Tag;
    });

    this.tagCategoryService.addTags(newTags).subscribe(
      () => {
        this.clientMessageService.showClientInfoMessage('Tags added successfully');
        this.fetchTagsAndCategories();
      },
      (error) => this.clientMessageService.showClientErrorMessage('Could not add the tags.'),
      () => {
        this.addedTags = [];
        this.addCategorisedTag = false;
        this.addTagControl.setValue(null);
      }
    );
  }

  focusedOut(event: any) {
    if (this.addTagControl.value) {
      this.addValue(this.addTagControl.value);
      this.addTagControl.reset();
    }
  }
}
