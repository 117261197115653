<div class="tagReplaceDeleteDialogWrapper">
  <div class="tagReplaceDeleteDialogTitle">
    <h3 mat-dialog-title>{{ title }}</h3>
    <button mat-button class="tagReplaceDeleteDialogButton" (click)="onCancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <h4>{{ message }}</h4>
  <div mat-dialog-content class="tagReplaceDeleteDialogContent">
    <div *ngIf="replaceTagVisible">
      <mat-checkbox #replaceCheckBox (change)="toggleReplaceDelete($event)" color="primary"
        >I want to replace this tag with an existing tag
      </mat-checkbox>
      <p [hidden]="!replaceCheckBox.checked" class="inset">
        <span class="mat-small">All stories using the {{ tagTitle }} tag will be replaced with the following tag</span>
      </p>
    </div>
    <div *ngIf="showReplace">
      <mat-form-field class="tagReplaceDeleteDialogField">
        <mat-label>Select Category</mat-label>
        <mat-select [(value)]="selectedCategory">
          <mat-option *ngFor="let category of categories" [value]="category">
            {{ category.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="tagReplaceDeleteDialogField" *ngIf="selectedCategory && showReplace">
        <mat-label>Replace with</mat-label>
        <mat-select [(value)]="selectedTag">
          <mat-option *ngFor="let tag of selectedCategory.tags" [value]="tag">
            {{ tag.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div id="tagReplaceDeleteDialogButtonsContainer" mat-dialog-actions>
    <button
      class="tagReplaceDeleteDialogButton"
      mat-button
      color="primary"
      [disabled]="showReplace && !selectedTag"
      (click)="replaceDelete()"
    >
      <span>{{ actionTitle }}</span>
    </button>
    <button class="tagReplaceDeleteDialogButton" mat-button (click)="onCancel()">
      <span>{{ cancelTitle }}</span>
    </button>
  </div>
</div>
