import { StoryPhase } from '../models/story-phase';
import { StoryState } from '../models/story-state';
import { Tag, TagMapper } from './tag';
import { User, UserMapper } from './user';
import { Injectable } from '@angular/core';
import { IMapper } from '../mapper';
import { IStorySearchResultDto } from '../api/story-search-result-dto';
import { StoryVisibility } from './story-visibility';
import { StoryLike } from './story-like';

export class StorySearchResult {
  id: string;
  title: string;
  clientName: string;
  summary: string;
  background: string;
  value: string;
  phase: StoryPhase;
  owner: User;
  state: StoryState;
  contributors: User[];
  lastEdited: Date;
  tags: Tag[];
  visibility: StoryVisibility;
  numberOfLikes: number;
  hasCurrentUserLiked: boolean;
  deliveredBy: string[];
  attachmentCount: number;
  hasFeaturedImage: boolean;
  deliveryStartDate: Date;
  deliveryCompletedDate: Date;
}

@Injectable({ providedIn: 'root' })
export class StorySearchResultMapper implements IMapper<IStorySearchResultDto, StorySearchResult> {
  constructor(private userMapper: UserMapper, private tagMapper: TagMapper) {}

  map(item: IStorySearchResultDto): StorySearchResult {
    const result = new StorySearchResult();
    result.id = item.id;
    result.title = item.title;
    result.clientName = item.clientName;
    result.summary = item.summary;
    result.background = item.background;
    result.value = item.value;
    result.phase = item.phase;
    result.contributors = item.contributors && item.contributors.map(this.userMapper.map);
    result.lastEdited = new Date(item.lastEdited);
    result.state = item.state;
    result.owner = this.userMapper.map(item.owner);
    result.tags = item.tags && item.tags.map(this.tagMapper.map);
    result.visibility = item.visibility;
    result.hasCurrentUserLiked = item.hasCurrentUserLiked;
    result.numberOfLikes = item.numberOfLikes;
    result.deliveredBy = item.deliveredBy;
    result.attachmentCount = item.attachmentCount;
    result.hasFeaturedImage = item.hasFeaturedImage;
    result.deliveryStartDate = item.deliveryStartDate ? new Date(item.deliveryStartDate) : null;
    result.deliveryCompletedDate = item.deliveryCompletedDate ? new Date(item.deliveryCompletedDate) : null;

    return result;
  }
}
