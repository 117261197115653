import { Component, Input, Output, EventEmitter } from '@angular/core';

export enum SortOrder {
  None,
  Ascending,
  Descending,
}

@Component({
  selector: 'app-sort-field',
  templateUrl: './sort-field.component.html',
  styleUrls: ['./sort-field.component.scss'],
})
export class SortFieldComponent {
  @Input() sortOrder: SortOrder;

  @Input() disabled = false;

  @Input() fieldName: string;

  @Input() isReverseOrder = false;

  @Output() toggleOrder = new EventEmitter<SortOrder>();

  constructor() {}

  get ascending(): boolean {
    return this.sortOrder === SortOrder.Ascending;
  }

  get descending(): boolean {
    return this.sortOrder === SortOrder.Descending;
  }

  public setOrder(state: SortOrder) {
    this.sortOrder = state;
  }

  toggle(): void {
    let state: SortOrder;
    switch (this.sortOrder) {
      case SortOrder.Ascending:
        state = this.isReverseOrder ? SortOrder.None : SortOrder.Descending;
        break;
      case SortOrder.Descending:
        state = this.isReverseOrder ? SortOrder.Ascending : SortOrder.None;
        break;
      default:
        state = this.isReverseOrder ? SortOrder.Descending : SortOrder.Ascending;
        break;
    }
    this.toggleOrder.emit(state);
  }
}
