import { Component, OnInit, Input } from '@angular/core';
import { Story } from 'src/app/shared//models/story';
import { StorySearchResult } from 'src/app/shared/models/story-search-result';
import { User } from 'src/app/shared/models/user';
import { StoryPhase } from 'src/app/shared/models/story-phase';
import { DATE_FORMAT } from 'src/app/shared/date-formats-datepipe';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-story-phase-note',
  templateUrl: './story-phase-note.component.html',
  styleUrls: ['./story-phase-note.component.scss'],
})
export class StoryPhaseNoteComponent implements OnInit {
  readonly DATE_FORMAT: string = DATE_FORMAT;

  @Input() story: StorySearchResult;
  @Input() currentUser: User;

  constructor(public datepipe: DatePipe) {}

  ngOnInit(): void {}

  addContributorCount(): string {
    if (this.story.contributors.length === 0) {
      return '';
    } else if (this.story.contributors.length === 1) {
      return ` and 1 other`;
    } else {
      return ` and ${this.story.contributors.length} others`;
    }
  }

  getPhaseNote(): string {
    if (this.story.phase === StoryPhase.Sale) {
      return (
        this.story.owner.fullName +
        this.addContributorCount() +
        (this.story.contributors.length > 0 ? ' are' : ' is') +
        ` currently working on the sale for this project.`
      );
    } else if (this.story.phase === StoryPhase.Deliver) {
      return (
        this.story.owner.fullName +
        this.addContributorCount() +
        ` started delivering this project` +
        this.getDateNote(this.story.deliveryStartDate)
      );
    } else if (this.story.phase === StoryPhase.Measure || this.story.phase === StoryPhase.Completed) {
      return (
        this.story.owner.fullName +
        this.addContributorCount() +
        ` finished delivering this project` +
        this.getDateNote(this.story.deliveryCompletedDate)
      );
    } else return '';
  }

  getDateNote(date: Date): string {
    return (date !== null && !isNaN(date.getTime()) ? ` on ` + this.datepipe.transform(date, DATE_FORMAT) : '') + '.';
  }
}
