import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Revision } from 'src/app/shared/models/revision';
import { StoriesService } from '../../services/stories.service';
import { DATE_TIME_FORMAT } from 'src/app/shared/date-formats-datepipe';

export class ChangeRevisionDialogData {
  title: string;
  revisions: Revision[];
}

@Component({
  selector: 'app-change-revision',
  templateUrl: './change-revision-dialog.component.html',
  styleUrls: ['./change-revision-dialog.component.scss'],
})
export class ChangeRevisionDialogComponent implements OnInit {
  readonly DATE_TIME_FORMAT: string = DATE_TIME_FORMAT;
  current: Revision;
  selected: Revision;
  all: Revision[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ChangeRevisionDialogData,
    private dialogRef: MatDialogRef<ChangeRevisionDialogComponent>,
    private storyService: StoriesService
  ) {}

  ngOnInit() {
    if (this.dialogData.revisions && this.dialogData.revisions.length > 1) {
      this.current = this.dialogData.revisions[0];
      this.selected = this.dialogData.revisions[1];
      this.all = this.dialogData.revisions.slice(1);
    } else {
      this.dialogRef.close();
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  revertToRevision(revision: Revision): void {
    this.storyService.revertToRevistion(revision).subscribe((result) => {
      this.dialogRef.close(revision);
    });
  }

  selectRevision(revision: Revision): void {
    this.selected = revision;
  }
}
