<mat-card class="story-part-wrapper mat-card no-shadow">
  <div (click)="edit()" class="story-part-title">
    <h3>Attachments</h3>
    <mat-icon *ngIf="canEdit" class="edit-icon">create</mat-icon>
  </div>
  <mat-card-content class="container">
    <app-card-item
      *ngFor="let attachment of attachments"
      [icon]="attachmentTypeDescriptor[attachment.attachmentType].Metadata.Icon"
      [iconTitle]="attachmentTypeDescriptor[attachment.attachmentType].Label"
      [title]="attachmentTypeDescriptor[attachment.attachmentType].Label"
      [subtitle]="canEdit ? attachment.name : (attachment.label ? attachment.label : attachment.name)"
      (action)="downloadAttachment(attachment)"
      [image]="attachmentTypeDescriptor[attachment.attachmentType].Metadata.DefaultImage"
    >
    </app-card-item>
  </mat-card-content>
</mat-card>
