<mat-card class="story-part-wrapper mat-card no-shadow">
  <div (click)="edit()" class="story-part-title">
    <h3>Links</h3>
    <mat-icon class="edit-icon" *ngIf="canEdit">create</mat-icon>
  </div>
  <mat-card-content class="container">
    <app-view-story-link
      class="tile"
      *ngFor="let embeddedLink of webLinks; trackBy: trackByFn"
      [label]="embeddedLink.label"
      [uri]="embeddedLink.uri"
      [type]="embeddedLink.type"
    ></app-view-story-link>
  </mat-card-content>
</mat-card>
