<mat-card class="activeFilterContainer" *ngIf="activeFilters.length !== 0">
  <mat-card-content>
    <div [hidden]="!isLoading || activeFilters.length !== 0">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row wrap" fxLayoutAlign="start center">
        <div [hidden]="isLoading" *ngFor="let filter of activeFilters">
          <div (click)="itemChanged(filter)" class="activeFilter">
            {{ filter.label }}
            <mat-icon role="img" class="" aria-hidden="true">clear</mat-icon>
          </div>
        </div>
      </div>
      <div>
        <button mat-button color="primary" (click)="clearFilters()">Clear All</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
