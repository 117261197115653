import { Component, Input, Output, EventEmitter } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { StorySearchClient } from 'src/app/shared/models/story-search-client';
import { StoryFilterFieldType } from './../../../../shared/models/story-filter-field-type';
import { StoryFilterGroupType } from './../../../../shared/models/story-filter-field-group-type';
import { IStoryFilterFieldGroupDto } from './../../../../shared/api/story-filter-field-group-dto';
import { StoriesService } from '../../../../story/services/stories.service';
import { CLIENTS_GROUP_LABEL } from '../story-clients-selection/story-clients-selection.component';

export class FilterFieldViewModel {
  constructor(
    public groupType: StoryFilterGroupType,
    public groupLabel: string,
    public fieldType: StoryFilterFieldType,
    public label: string,
    public value: string,
    public checked: boolean
  ) {}
}

export class FilterGroupViewModel {
  fields: FilterFieldViewModel[] = [];
  label: string;

  constructor(group: IStoryFilterFieldGroupDto) {
    this.label = group.label;
    this.fields = group.fields.map(
      (f) => new FilterFieldViewModel(group.groupType, group.label, f.fieldType, f.label, f.value, f.default)
    );
  }
}

@Component({
  selector: 'app-story-search-filter',
  templateUrl: './story-search-filter.component.html',
  styleUrls: ['./story-search-filter.component.scss'],
})
export class StorySearchFilterComponent {
  @Input() storySearchClients: StorySearchClient[];
  @Input() selectedClients: StorySearchClient[] = [];
  @Output() filtersChanged = new EventEmitter<FilterFieldViewModel[]>();

  allFilters: FilterGroupViewModel[] = [];
  isLoading = false;
  expandedFilters = ['Visibility'];
  defaultFilters: FilterFieldViewModel[] = [];

  constructor(private storiesService: StoriesService) {}

  public get checkedFilters(): FilterFieldViewModel[] {
    return this.allFilters.length
      ? this.allFilters.map((g) => g.fields.filter((f) => f.checked)).reduce((a, b) => a.concat(b))
      : [];
  }

  public get allFields(): FilterFieldViewModel[] {
    return this.allFilters.length ? this.allFilters.map((g) => g.fields).reduce((a, b) => a.concat(b)) : [];
  }

  public get allRegularFilters(): FilterGroupViewModel[] {
    return this.allFilters.length ? this.allFilters.filter((f) => f.label !== CLIENTS_GROUP_LABEL) : [];
  }

  initialize(callback: () => any = null): void {
    this.isLoading = true;
    this.allFilters = [];
    this.storiesService.getSearchFilters().subscribe((res) => {
      this.allFilters = res.map((r) => new FilterGroupViewModel(r));

      const clientsFilter = new FilterGroupViewModel({
        groupType: StoryFilterGroupType.ClientName,
        label: CLIENTS_GROUP_LABEL,
        fields: [],
      } as IStoryFilterFieldGroupDto);
      this.allFilters.push(clientsFilter);

      this.defaultFilters = this.checkedFilters;
      this.isLoading = false;

      if (callback) {
        callback();
      }
    });
  }

  public reset(raiseChangedEvent: boolean = true): void {
    this.allFields.forEach((f) => {
      f.checked = this.defaultFilters.indexOf(f) > -1;
    });

    if (raiseChangedEvent) {
      this.itemChanged();
    }
  }

  public itemChanged(): void {
    console.log(this.checkedFilters);
    this.filtersChanged.emit(this.checkedFilters);
  }

  public uncheckItem(filters: { groupLabel: string; label: string }[]): void {
    this.allFilters.forEach((g) =>
      g.fields.forEach(
        (f) => (f.checked = filters.some((filter) => f.label === filter.label && filter.groupLabel === g.label))
      )
    );
    this.selectedClients = [];
    filters.forEach((f) => {
      if (f.groupLabel === CLIENTS_GROUP_LABEL) {
        this.selectedClients.push({ name: f.label });
      }
    });
  }

  public onClientSelectionChanged(props: { client: StorySearchClient; isChecked: boolean }) {
    const clientsFilter = this.allFilters.find((f) => f.label === CLIENTS_GROUP_LABEL);
    if (props.isChecked) {
      clientsFilter.fields.push(
        new FilterFieldViewModel(
          StoryFilterGroupType.ClientName,
          CLIENTS_GROUP_LABEL,
          StoryFilterFieldType.Custom,
          props.client.name,
          props.client.name,
          true
        )
      );
    } else {
      const index = clientsFilter.fields.findIndex((f) => f.label === props.client.name);
      clientsFilter.fields.splice(index, 1);
    }
    console.log(this.checkedFilters);
    console.log(clientsFilter);
    this.filtersChanged.emit(this.checkedFilters);
  }
}
