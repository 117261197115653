<div id="about-container">
  <mat-card>
    <div id="about-message-container">
      <h1>Welcome to StoryHub</h1>
      <p>StoryHub is a tool that helps us share customer success stories across Telstra Purple.</p>
      <p>
        Each of us is a seller regardless of the role we’re undertaking - any time we have a discussion with a potential
        or current customer we are representing our company. As sellers, we are most effective if we engage our current
        and potential customers with real stories that spark their curiosity, inspire them with the art of the possible
        and fuel their desire to collaborate with us. We are Thought Leaders, we’re solving real business problems with
        people and purpose (along with some leading edge technology here and there!). We’re winning and delivering
        amazing projects, but so often we forget to capture and share the stories! That's where StoryHub comes in - a
        searchable repository of our sales and delivery stories.
      </p>
      <p>The objectives of StoryHub are:</p>
      <ul>
        <li>
          Put impactful stories of the great sales and delivery opportunities we win and deliver into the hands of our
          sales and delivery teams.
        </li>
        <li>Make them searchable, including by industry, capability, delivery location, technology/skill.</li>
        <li>Encourage broad participation across our entire business.</li>
        <li>Foster a culture of story telling and knowledge sharing within our company.</li>
        <li>Make it easy to track and find our public-facing stories.</li>
        <li>Encourage capture of the benefits realisation / measurement from the outcomes we deliver to customers.</li>
      </ul>
      <p>
        To learn more about the story behind StoryHub, check out the
        <a
          href="https://stories.purple.tech/view-story/9e3feda8-eb10-4e54-b25a-fc68975170e9"
          target="_blank"
          rel="noopener noreferrer"
          >story of StoryHub</a
        >
        on StoryHub (yep - you read that right!)
      </p>
      <p>
        To provide feedback and suggestions, please visit
        <a href="https://it.purple.tech" target="_blank" rel="noopener noreferrer">Zendesk</a>
        <mat-icon
          id="yammer-info-icon"
          matTooltip="If you're experiencing difficulties opening the link, please ensure you connect to the Telstra Yammer first."
          >info</mat-icon
        >
      </p>
    </div>
    <div id="release-notes-container">
      <h1>Release Notes</h1>
      <table *ngIf="releaseNotes.length > 0">
        <tr>
          <th>Name</th>
          <th>Author</th>
          <th>Created</th>
          <th>Content</th>
          <th><app-create-release-note-button></app-create-release-note-button></th>
        </tr>
        <tr *ngFor="let releaseNote of releaseNotes">
          <td>
            <b>{{ releaseNote.versionId }}</b>
            <br />{{ releaseNote.id }}
          </td>
          <td>
            Created by: {{ releaseNote.Creator.fullName }}
            <br />
            Implemented by: {{ releaseNote.Implementer.fullName }}
          </td>
          <td>
            Created: {{ releaseNote.Created }}
            <br />
            Last Updated: {{ releaseNote.Modified }}
          </td>
          <td>{{ releaseNote.Content }}</td>
          <td><app-create-release-note-button [releaseNoteId]="releaseNote.Id"></app-create-release-note-button></td>
        </tr>
      </table>
      <p *ngIf="releaseNotes.length == 0">
        No release notes has been created yet... If you're an admin, create one with the button below!
      </p>
      <hr />
      <app-create-release-note-button></app-create-release-note-button>
    </div>
  </mat-card>
</div>
