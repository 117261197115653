<div class="dialogContainer">
  <div class="dialogTitle">
    <h3 mat-dialog-title>Edit {{ editStoryPartData.title }}</h3>
    <button mat-button class="storyDialogButton" (click)="onCancel()"><mat-icon>clear</mat-icon></button>
  </div>
  <form [formGroup]="storyPartForm" *ngIf="!allowMarkdown">
    <mat-form-field class="storyField">
      <mat-label>{{ editStoryPartData.title }}</mat-label>
      <textarea
        matInput
        #input
        [formControl]="inputTextArea"
        formControlName="inputTextArea"
        cdkTextareaAutosize
        [maxlength]="storyPartCharacterLimit"
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="12"
        cdkAutosizeMaxRows="12"
        cdkFocusInitial
      ></textarea>
      <mat-error *ngIf="inputTextArea.invalid">{{ getInputErrorMessage() }}</mat-error>
      <mat-hint id="placeholderHint" align="start" [innerHtml]="editStoryPartData.placeholder"></mat-hint>
      <mat-hint id="charCount" align="end">{{ input.value?.length || 0 }} / {{ storyPartCharacterLimit }}</mat-hint>
    </mat-form-field>
  </form>
  <div class="dialogContent" *ngIf="allowMarkdown">
    <form [formGroup]="storyPartForm">
      <mat-form-field>
        <div class="dialogEditor">
          <app-mat-quill #matEditor [sanitize]="true" formControlName="inputTextArea" format="html" class="editor">
          </app-mat-quill>
        </div>
        <mat-error *ngIf="inputTextArea.invalid">{{ getInputErrorMessage() }}</mat-error>
        <mat-hint id="placeholderHint" align="start" [innerHtml]="editStoryPartData.placeholder"></mat-hint>
        <mat-hint id="charCount" align="end"
          >{{ matEditor.value?.length || 0 }} / {{ storyPartCharacterLimit }}</mat-hint
        >
      </mat-form-field>
    </form>
  </div>
  <div id="dialogButtonsContainer" mat-dialog-actions>
    <button
      class="storyDialogButton"
      mat-button
      color="primary"
      [disabled]="!storyPartForm.valid"
      (click)="updatePart()"
    >
      <span>SAVE</span>
    </button>
    <button class="storyDialogButton" mat-button (click)="onCancel()">
      <span>CANCEL</span>
    </button>
  </div>
</div>
