<div class="dialogTitle">
  <h3 mat-dialog-title>Edit Links</h3>
  <button mat-button class="storyDialogButton" (click)="cancel()"><mat-icon>close</mat-icon></button>
</div>

<div class="scrollable">
  <table>
    <tr *ngFor="let embeddedLink of embeddedLinks">
      <td>
        <app-icon
          [icon]="embeddedLinkTypeDescriptor[embeddedLink.type].Metadata.Icon"
          [title]="embeddedLinkTypeDescriptor[embeddedLink.type].Label"
        ></app-icon>
      </td>
      <td>
        <mat-form-field class="field">
          <input
            matInput
            placeholder="Title"
            [(ngModel)]="embeddedLink.label"
            (input)="onChange()"
          />
        </mat-form-field>
      </td>
      <td>
        <mat-form-field class="field">
          <input matInput placeholder="URL" [(ngModel)]="embeddedLink.uri" (input)="onChange()" />
        </mat-form-field>
      </td>
      <td>
        <button class="storyDialogButton" mat-button color="primary" (click)="deleteEmbeddedLink(embeddedLink)">
          <span>DELETE</span>
        </button>
      </td>
    </tr>
  </table>
</div>

<div class="buttons" mat-dialog-actions>
  <div mat-dialog-actions>
    <!-- add -->
    <button mat-fab color="primary" aria-label="Add Link" [matMenuTriggerFor]="menu">
      <mat-icon class="addButton">add</mat-icon>
    </button>

    <mat-menu #menu="matMenu" xPosition="before" yPosition="above">
      <button mat-menu-item *ngFor="let t of types" (click)="addEmbeddedLink(t)">
        <app-icon
          [title]="embeddedLinkTypeDescriptor[t].Label"
          [icon]="embeddedLinkTypeDescriptor[t].Metadata.Icon"
        ></app-icon>
        Add {{ embeddedLinkTypeDescriptor[t].Label }} Link
      </button>
    </mat-menu>
  </div>

  <!-- save / cancel -->
  <div mat-dialog-actions>
    <button class="storyDialogButton" mat-button color="primary" [disabled]="!hasChanges || !isValid || isSaving" (click)="save()">
      <span>SAVE</span>
    </button>
    <button class="storyDialogButton" mat-button (click)="cancel()">
      <span>CANCEL</span>
    </button>
  </div>
</div>
