<div>
  <mat-form-field>
    <p class="mat-hint">Visibility</p>
    <mat-select
      #visibilityInput
      cdkFocusInitial
      [value]="selectedVisibility"
      (selectionChange)="onVisibilityChange(visibilityInput.value)"
    >
    <mat-option *ngFor="let visibility of storyVisibilityOrdered" [value]="visibility" [matTooltip]="visibilityInfoMessages(visibility)">
      {{ storyVisibilityDescriptor[visibility].Label }}
    </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div [hidden]="!evidenceIsRequired()">
  <mat-checkbox #customerApprovedCheckBox color="primary">Confirm customer has approved this</mat-checkbox>
  <div [hidden]="!customerApprovedCheckBox.checked">
    <p class="inset">
      <span class="mat-small"
        >To share a story publicly (i.e. keep any reference to a customers name) you must obtain customer
        approval.</span
      >
    </p>
    <div class="evidence-upload">
      <p class="mat-hint">Upload evidence of customer approval</p>
      <input
        #evidenceFileInput
        type="file"
        accept=".txt, .pdf, .docx"
        (change)="onEvidenceFilesChange(evidenceFileInput.files)"
        style="display: none"
      />

      <mat-divider></mat-divider>
      <div class="evidence-file-select">
        <button mat-button class="browse-button" color="primary" (click)="onEvidenceBrowseClicked()">BROWSE...</button>
        <div class="file-information">
          <div class="file-name">{{ evidenceFileName() }}</div>
          <div class="file-status mat-hint">{{ evidenceUploadStatusText() }}</div>
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
  </div>
</div>
<div id="dialog-buttons-container" mat-dialog-actions>
  <button color="primary" [disabled]="saveDisabled()" mat-button (click)="onSaveClicked()">
    <span>SAVE</span>
  </button>
  <button mat-button (click)="cancel()">
    <span>CANCEL</span>
  </button>
</div>
