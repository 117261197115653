<div class="copy-button">
  <button *ngIf="loaded" mat-button (click)="copy()">Copy for excel</button>
  <mat-progress-spinner mode="indeterminate" [diameter]="30" *ngIf="!loaded"></mat-progress-spinner>
</div>
<table id="data-table" mat-table [dataSource]="stories" class="mat-elevation-z8 full-width">
  <ng-container matColumnDef="clientName">
    <th mat-header-cell *matHeaderCellDef>Client Name</th>
    <td mat-cell *matCellDef="let element">{{ element.clientName }}</td>
  </ng-container>
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Title</th>
    <td mat-cell *matCellDef="let element">{{ element.title }}</td>
  </ng-container>
  <ng-container matColumnDef="visibility">
    <th mat-header-cell *matHeaderCellDef>Visibility</th>
    <td mat-cell *matCellDef="let element">{{ visibility(element.visibility) }}</td>
  </ng-container>
  <ng-container matColumnDef="phase">
    <th mat-header-cell *matHeaderCellDef>Phase</th>
    <td mat-cell *matCellDef="let element">{{ phase(element.phase) }}</td>
  </ng-container>
  <ng-container matColumnDef="saleClosedDate">
    <th mat-header-cell class="date-width" *matHeaderCellDef>Sale Date</th>
    <td mat-cell *matCellDef="let element">{{ element.saleClosedDate | date: 'mediumDate' }}</td>
  </ng-container>
  <ng-container matColumnDef="deliveryStartDate">
    <th mat-header-cell class="date-width" *matHeaderCellDef>Delivery Start Date</th>
    <td mat-cell *matCellDef="let element">{{ element.deliveryStartDate | date: 'mediumDate' }}</td>
  </ng-container>
  <ng-container matColumnDef="deliveryCompletedDate">
    <th mat-header-cell class="date-width" *matHeaderCellDef>Delivery Completed Date</th>
    <td mat-cell *matCellDef="let element">{{ element.deliveryCompletedDate | date: 'mediumDate' }}</td>
  </ng-container>
  <ng-container matColumnDef="participationWillingness">
    <th mat-header-cell *matHeaderCellDef>Participation Willingness</th>
    <td mat-cell *matCellDef="let element" [ngClass]="{ red: !element.isCategoryNomination }">
      {{ nomination(element.caseStudyNomination) }}
    </td>
  </ng-container>
  <ng-container matColumnDef="goneToProductionDate">
    <th mat-header-cell class="date-width" *matHeaderCellDef>Release to Production Date</th>
    <td mat-cell *matCellDef="let element" [ngClass]="{green: element.threeMonthsAfterReleaseToProduction}">{{ element.goneToProductionDate | date: 'mediumDate' }}</td>
  </ng-container>
  <ng-container matColumnDef="nominatedBy">
    <th mat-header-cell *matHeaderCellDef>Nominated By</th>
    <td mat-cell *matCellDef="let element">
      <a href="mailto:{{ element.caseStudyNominatedBy.email }}">{{ element.caseStudyNominatedBy.fullName }}</a>
    </td>
  </ng-container>
  <ng-container matColumnDef="nominationDate">
    <th mat-header-cell class="date-width" *matHeaderCellDef>Nomination Date</th>
    <td mat-cell *matCellDef="let element">{{ element.caseStudyNominatedAt | date: 'mediumDate' }}</td>
  </ng-container>

  <ng-container matColumnDef="notes">
    <th mat-header-cell *matHeaderCellDef>Notes</th>
    <td mat-cell *matCellDef="let element">{{ element.caseStudyNominationNotes }}</td>
  </ng-container>
  <ng-container matColumnDef="owner">
    <th mat-header-cell *matHeaderCellDef>Owner</th>
    <td mat-cell *matCellDef="let element">
      <a href="mailto:{{ element.owner.email }}">{{ element.owner.fullName }}</a>
    </td>
  </ng-container>
  <ng-container matColumnDef="contributors">
    <th mat-header-cell *matHeaderCellDef>Contributors</th>
    <td mat-cell *matCellDef="let element">
      <span *ngFor="let c of element.contributors" class="contributor"
        ><a href="mailto:{{ c.email }}">{{ c.fullName }}</a></span
      >
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="navigate(row.id)"></tr>
</table>
