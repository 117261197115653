<div class="editTitleDialogWrapper">
  <div class="dialogTitle">
    <h3 mat-dialog-title><strong>Share Story</strong></h3>
    <button mat-button class="storyDialogButton" (click)="onCancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <h5 id="CopyInfoHeader" mat-dialog-title>Copy the url below to share:</h5>
  <div mat-dialog-content class="dialogContent">
    <form [formGroup]="storyTitleForm">
      <mat-form-field class="story-input-field">
        <mat-label>URL</mat-label>
        <input #storyUrl formControlName="title" matInput readonly />
        <span matSuffix>
          <div class="update-copy-buttons">
            <button mat-button cdkFocusInitial color="primary" (click)="copyUrl(storyUrl, $event)">
              Copy
            </button>
          </div>
        </span>
      </mat-form-field>
    </form>
  </div>
</div>
