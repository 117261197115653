<mat-form-field class="state-field">
  <mat-select [(value)]="selectedState">
    <mat-option *ngFor="let state of storyStateDescriptor.Keys" [value]="state" [disabled]="isOptionDisabled(state)">
      {{ storyStateDescriptor[state].Label }}
    </mat-option>
  </mat-select>
</mat-form-field>
<div class="highlight" *ngIf="showVisibilityWarning">
  Changing the story state to Draft will change the Visibility to Internal.
</div>
<div class="highlight" *ngIf="cantPublish">
  <mat-icon color="warn" matTooltip="{{ this.story.errorMessages.join('\n') }}">error</mat-icon>
  This story can't be published until the errors are fixed.
</div>
<div id="dialog-buttons-container" mat-dialog-actions>
  <button class="releaseNoteDialogButton" color="primary" [disabled]="saveDisabled" mat-button (click)="save()">
    <span>SAVE</span>
  </button>
  <button class="releaseNoteDialogButton" mat-button (click)="cancel()">
    <span>CANCEL</span>
  </button>
</div>
