import { Component, OnInit, Input, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  title = 'Confirmation';
  message = 'Are you sure?';
  okText = 'Ok';
  cancelText = 'Cancel';

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string;
      okText: string;
      cancelText: string;
    }
  ) {
    this.title = this.data.title || this.title;
    this.message = this.data.message || this.message;
    this.okText = this.data.okText || this.okText;
    this.cancelText = this.data.cancelText || this.cancelText;
  }

  ngOnInit() {}

  onConfirm(): void {
    this.dialogRef.close(ConfirmationDialogResult.Confirmed);
  }

  onCancel(): void {
    this.dialogRef.close(ConfirmationDialogResult.Cancelled);
  }
}

export enum ConfirmationDialogResult {
  Confirmed,
  Cancelled,
}
