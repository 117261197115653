<mat-card
  ><mat-card-title class="header">Story Strength</mat-card-title>
  <div class="strength-container">
    <h4 *ngFor="let point of strengthPoints" class="progress-label">
      {{ point.phase }} <span *ngIf="point.isPhaseActive">({{ point.points }}/{{ point.totalPoints }})</span>
    </h4>
  </div>
  <div class="strength-container">
    <ng-container *ngFor="let point of strengthPoints">
      <mat-progress-bar
        *ngIf="point.isPhaseActive"
        mode="determinate"
        value="{{ point.totalPointsPercentage }}"
        class="{{ point.phase | lowercase }}"
      ></mat-progress-bar>
      <div *ngIf="!point.isPhaseActive" class="progress-deactive"></div>
    </ng-container>
  </div>
</mat-card>
