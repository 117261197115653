import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TagListChangedEvent } from '../../story-tag/story-tag.component';
import { Story } from '../../../../shared/models/story';
import { StoriesService } from '../../../services/stories.service';
import { ClientMessageService } from '../../../../shared/services/client-message.service';

@Component({
  selector: 'app-tags-edit-mode',
  templateUrl: './tags-edit-mode.component.html',
  styleUrls: ['./tags-edit-mode.component.scss'],
})
export class TagsEditModeComponent implements OnInit {
  @Input()
  story: Story;

  @Output()
  switchToView = new EventEmitter();

  @Output()
  storyUpdated = new EventEmitter();

  hasTagChanges: boolean;
  private lastTagListChangedEvent: TagListChangedEvent;

  constructor(private storiesService: StoriesService, private errorService: ClientMessageService) {}

  ngOnInit(): void {}

  tagsChanged(event: TagListChangedEvent) {
    this.hasTagChanges = event.addedTags.length + event.removedTags.length > 0;
    this.lastTagListChangedEvent = event;
  }

  updateTags() {
    this.storiesService
      .updateTags(this.story.id, this.lastTagListChangedEvent.addedTags, this.lastTagListChangedEvent.removedTags)
      .subscribe(() => {
        this.switchToView.emit();
        this.storyUpdated.emit();
        this.errorService.showClientInfoMessage('The tags have been successfully updated');
      });
  }

  cancelTags() {
    this.hasTagChanges = false;
    this.lastTagListChangedEvent = null;
    this.switchToView.emit();
  }
}
