import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ClientMessageService } from 'src/app/shared/services/client-message.service';
import { ShareStoryUrl } from '../../../shared/models/share-story-url';

@Component({
  selector: 'app-share-story-dialog',
  templateUrl: './share-story-dialog.component.html',
  styleUrls: ['./share-story-dialog.component.scss'],
})
export class ShareStoryDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) private shareStoryUrl: ShareStoryUrl,
    private dialogRef: MatDialogRef<ShareStoryDialogComponent>,
    private messageService: ClientMessageService
  ) {}

  storyTitleForm = new UntypedFormGroup({
    title: new UntypedFormControl(`${window.location.origin}/view-story/${this.shareStoryUrl.id}`),
  });

  onCancel(): void {
    this.dialogRef.close();
  }

  copyUrl(storyUrl, event) {
    storyUrl.select();
    document.execCommand('copy');
    storyUrl.setSelectionRange(0, 0);
    event.target.innerText = 'Copied';
    this.messageService.showClientInfoMessage('URL copied to clipboard');
  }
}
