import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportHomeComponent } from './export-home/export-home.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';


@NgModule({
  declarations: [ExportHomeComponent],
  imports: [
    CommonModule,
    ClipboardModule,
    MatTableModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ]
})
export class ExportModule {
}
