<mat-table #table [dataSource]="releaseNotes" matSort>
  <ng-container matColumnDef="title">
    <mat-header-cell *matHeaderCellDef mat-sort-header class="release-notes-name"> Title </mat-header-cell>
    <mat-cell *matCellDef="let item">
      {{ item.title }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="versionId">
    <mat-header-cell *matHeaderCellDef mat-sort-header class="release-notes-versionId"> Version </mat-header-cell>
    <mat-cell *matCellDef="let item">
      {{ item.versionId }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="implementedBy">
    <mat-header-cell *matHeaderCellDef mat-sort-header class="release-notes-creationDate">
      Implemented By
    </mat-header-cell>
    <mat-cell *matCellDef="let item">
      {{ item.implementer }}
      <br />
      {{ item.dateCreated | date }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="lastUpdatedBy">
    <mat-header-cell *matHeaderCellDef mat-sort-header class="release-notes-creationDate">
      Last Updated
    </mat-header-cell>
    <mat-cell *matCellDef="let item">
      {{ item.lastUpdatedBy?.displayName }}
      <br />
      {{ item.dateLastUpdated | date }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="content">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Content </mat-header-cell>
    <mat-cell *matCellDef="let item" class="release-notes-content">
      {{ item.content }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="implementer">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Implemented By </mat-header-cell>
    <mat-cell *matCellDef="let item" class="release-notes-content">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let user of item.implementer" [removable]="false">
          {{ user.DisplayName }}
        </mat-chip>
      </mat-chip-list>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="delete">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element" class="delete-icon">
      <span>
        <mat-icon
          *ngIf="showDelete"
          matListIcon
          matTooltip="Delete Release Note"
          (click)="$event.stopPropagation(); onDeleteClicked(element.id)"
        >
          delete
        </mat-icon>
      </span>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns" (click)="onEditClicked(row)"> </mat-row>
</mat-table>
