import { Injectable } from '@angular/core';
import { IMapper } from '../mapper';
import { StoryPart } from './story-part';
import { IStoryRevisionCount } from '../api/story-revision-count';

export class RevisionCount {
  storyPart: StoryPart;
  count: number;
}

@Injectable({ providedIn: 'root' })
export class RevisionCountMapper implements IMapper<IStoryRevisionCount, RevisionCount> {
  map(source: IStoryRevisionCount): RevisionCount {
    const result = new RevisionCount();
    result.count = source.count;
    result.storyPart = source.storyPart;
    return result;
  }
}
