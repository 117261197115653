/*
 * Date and time formats for use with Moment.js DateAdapter
 *
 * https://momentjs.com/docs/#/parsing/string-format/
 *
 * WARNING: These formats are not compatible with Angular date pipe.
 */

import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MatDateFormats } from '@angular/material/core';

/**
 * StoryHub-wide formats for MomentDateAdapter.
 */
export const MOMENT_DATE_FORMATS: MatDateFormats = {
  display: { ...MAT_MOMENT_DATE_FORMATS.display, dateInput: 'DD/MM/YYYY' },
  parse: { ...MAT_MOMENT_DATE_FORMATS.parse },
};
