<app-card-item
  *ngIf="embeddedLinkTypeDescriptor[type]"
  [image]="image"
  [subtitle]="label"
  [iconTitle]="embeddedLinkTypeDescriptor[type].Label"
  [title]="embeddedLinkTypeDescriptor[type].Label"
  [icon]="embeddedLinkTypeDescriptor[type].Metadata.Icon"
  [loading]="loading"
  (action)="navigate()"
>
</app-card-item>
