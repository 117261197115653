<mat-label>
  To add new contributors enter in their work email below <strong>and then hit enter</strong>
  <br />
  <br />
  <mat-icon aria-hidden="false" aria-label="information">info</mat-icon>
  It is recommended to add a combination of sales and delivery people that are working on or will work on the
  engagement.
</mat-label>
<mat-form-field class="chip-list">
  <mat-chip-list #contributorChipList>
    <input
      [matChipInputFor]="contributorChipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="addContributor($event)"
    />
  </mat-chip-list>
</mat-form-field>
<mat-label *ngIf="contributorError" class="error">
  {{ contributorError }}
</mat-label>
<mat-spinner *ngIf="validating"></mat-spinner>
<mat-list role="list">
  <mat-list-item role="listitem" *ngFor="let newContributor of newContributors; let lst = last">
    <div class="avatar" mat-card-avatar>{{ newContributor.initials }}</div>
    {{ newContributor.fullName }}
    <span class="fill-remaining-space"></span>
    <button mat-icon-button (click)="removeContributor(newContributor)">
      <mat-icon>close</mat-icon>
    </button>
  </mat-list-item>
</mat-list>
<h5>Existing Contributors</h5>
<app-contributors-list
  [contributors]="existingContributors"
  [enableRemoveContributor]="true"
  (removeContributor)="removeContributor($event)"
>
</app-contributors-list>
<button
  [disabled]="!!contributorError || (newContributors.length === 0 && removedContributors.length === 0)"
  mat-button
  color="primary"
  (click)="updateContributors()"
>
  SAVE
</button>
<button mat-button (click)="cancelEditing()">CANCEL</button>
