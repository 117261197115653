import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Tag } from '../../models/tag';
import { TagCategoryService } from '../../services/tag-category.service';
import { TagCategory } from '../../models/tag-category';

@Component({
  selector: 'app-tag-replace-delete',
  templateUrl: './tag-replace-delete.component.html',
  styleUrls: ['./tag-replace-delete.component.scss'],
})
export class TagReplaceDeleteComponent implements OnInit {
  title: string;
  message: string;
  actionTitle: string;
  cancelTitle: string;
  replaceTagVisible: boolean;
  replaceMode: boolean;
  tagTitle: string;
  showReplace: boolean;
  categories: TagCategory[];
  selectedCategory: TagCategory;
  selectedTag: Tag;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: TagReplaceDeleteComponentData,
    private dialogRef: MatDialogRef<TagReplaceDeleteComponent>,
    private tagCategoryService: TagCategoryService
  ) {}

  ngOnInit() {
    this.tagTitle = this.data.tag.value;
    this.categories = this.data.categories;
    this.actionTitle = 'YES';
    this.cancelTitle = 'NO';
    if (this.data.formAction === FormAction.Replace) {
      this.title = 'Replace tag with categorised tag';
      this.message = `Replace the ${this.data.tag.value} tag with...`;
      this.actionTitle = 'SAVE';
      this.cancelTitle = 'CANCEL';
      this.replaceMode = true;
      this.showReplace = true;
    } else if (this.data.isUncategorisedTag) {
      this.title = 'Delete Uncategorised Tag';
      this.message = `The ${this.data.tag.value} tag will be deleted from all stories`;
      this.replaceTagVisible = true;
    } else {
      this.title = 'Delete Categorised Tag';
      this.message = `By deleting ${this.data.tag.value}, the tag will be removed from the tag list and also removed from all stories that are using this tag. Are you sure you want to delete?`;
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  toggleReplaceDelete($event: MatCheckboxChange) {
    this.showReplace = $event.checked;
  }

  replaceDelete() {
    if (this.data.formAction === FormAction.Replace) {
      this.tagCategoryService.replaceTag(this.data.tag, this.selectedTag).subscribe(() => this.dialogRef.close());
    } else {
      this.tagCategoryService.deleteTag(this.data.tag).subscribe(() => this.dialogRef.close());
    }
  }
}

export interface TagReplaceDeleteComponentData {
  tag: Tag;
  isUncategorisedTag: boolean;
  categories: TagCategory[];
  formAction: FormAction;
}

export enum FormAction {
  Replace = 1,
  Delete = 2,
}
