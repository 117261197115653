import { IStoryDto } from '../api/story-dto';
import { IMapper } from '../mapper';
import { TagService } from '../services/tag/tag.service';
import { Attachment, AttachmentMapper } from './attachment';
import { EmbeddedLink, EmbeddedLinkMapper } from './embedded-link';
import { RevisionCount, RevisionCountMapper } from './revision-count';
import { StoryCaseStudyNomination } from './story-case-study-nomination';
import { StoryPart, storyPartDescriptor } from './story-part';
import { StoryPhase, storyPhaseDescriptor } from './story-phase';
import { StoryState } from './story-state';
import { StoryVisibility } from './story-visibility';
import { TagMapper } from './tag';
import { TagWithCategory } from './tag-with-category';
import { User, UserMapper } from './user';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { attachmentTypeDescriptor } from './attachment-type';
import moment from 'moment';

export class Story {
  id: string;
  title: string;
  clientName: string;
  summary: string;
  background?: string;
  problem?: string;
  reason?: string;
  impact?: string;
  solution?: string;
  engagementFeedback?: string;
  benefitsRealisation?: string;
  approach?: string;
  value?: number;
  createdBy: User;
  phase: StoryPhase;
  contributors: User[];
  lastEdited: Date;
  lastEditedBy: User;
  state: StoryState;
  visibility: StoryVisibility;
  owner: User;
  revisionsCount: RevisionCount[];
  tags: TagWithCategory[];
  attachments: Attachment[];
  hasFeaturedImage: boolean;
  numberOfLikes: number;
  hasCurrentUserLiked: boolean;
  saleClosedDate: Date | null;
  deliveryStartDate: Date | null;
  goneToProductionDate: Date | null;
  deliveryCompletedDate: Date | null;
  embeddedLinks: EmbeddedLink[];
  caseStudyNomination: StoryCaseStudyNomination | null;
  caseStudyNominationNotes: string | null;
  caseStudyNominatedAt?: Date;
  caseStudyNominatedBy?: User;
  sharingConditions: string;

  readonly phaseMap = [
    {
      phase: StoryPhase.Sale,
      title: 'Sale',
      parts: [
        StoryPart.Summary,
        StoryPart.Background,
        StoryPart.Problem,
        StoryPart.Reason,
        StoryPart.Impact,
        StoryPart.Approach,
      ],
      strengthRules: {
        totalStrengthPoints: 12,
        strengthPointForeachPart: 1,
        featuredImagePoints: 1,
        phasePoints: 1,
        likeCount: 1,
        likePoints: 1,
        tagPoints: 0.5,
        maxTagPoints: 3,
      },
    },
    {
      phase: StoryPhase.Deliver,
      title: 'Delivery',
      parts: [StoryPart.Solution, StoryPart.EngagementFeedback],
      strengthRules: {
        totalStrengthPoints: 10,
        strengthPointForeachPart: 2,
        featuredImagePoints: 0,
        phasePoints: 1,
        likeCount: 3,
        likePoints: 2,
        tagPoints: 1,
        specificTag: 'Technology',
        maxTagPoints: 3,
      },
    },
    {
      phase: StoryPhase.Measure,
      title: 'Measurement',
      parts: [StoryPart.BenefitsRealisation],
      strengthRules: {
        totalStrengthPoints: 5,
        strengthPointForeachPart: 3,
        featuredImagePoints: 0,
        phasePoints: 2,
        likeCount: 0,
        likePoints: 0,
      },
    },
  ];

  // Equivalent backend validation rules can be found in
  // StoryHub.Api\Models\Validators\StoryMandatoryFieldsValidator.cs
  validationRules: { parts: Map<StoryPart, StoryPhase>; tags: Map<StoryPhase, Map<string, number>> } = {
    parts: new Map([
      [StoryPart.Title, StoryPhase.Sale],
      [StoryPart.Summary, StoryPhase.Sale],
      [StoryPart.Background, StoryPhase.Sale],
      [StoryPart.ClientName, StoryPhase.Sale],
      [StoryPart.Value, StoryPhase.Sale],
      [StoryPart.Approach, StoryPhase.Deliver],
      [StoryPart.Solution, StoryPhase.Measure],
      [StoryPart.EngagementFeedback, StoryPhase.Measure],
      [StoryPart.BenefitsRealisation, StoryPhase.Completed],
    ]),
    tags: new Map([
      [
        StoryPhase.Sale,
        new Map([
          ['Location', 1],
          ['Capability', 1],
          ['Delivered By', 1],
          ['Industry', 1],
        ]),
      ],
      [
        StoryPhase.Deliver,
        new Map([
          ['Location', 1],
          ['Capability', 1],
          ['Delivered By', 1],
          ['Industry', 1],
          ['Technology', 1],
        ]),
      ],
      [
        StoryPhase.Measure,
        new Map([
          ['Location', 1],
          ['Capability', 1],
          ['Delivered By', 1],
          ['Industry', 1],
          ['Technology', 2],
        ]),
      ],
      [
        StoryPhase.Completed,
        new Map([
          ['Location', 1],
          ['Capability', 1],
          ['Delivered By', 1],
          ['Industry', 1],
          ['Technology', 2],
        ]),
      ],
    ]),
  };

  get allContributors(): string {
    let result = [this.owner];
    if (this.contributors) {
      result = result.concat(this.contributors);
    }
    return result.map((x: User) => x.fullName).join(', ');
  }

  getStoryPartValue(storyPart: StoryPart): string {
    switch (storyPart) {
      case StoryPart.Title:
        return this.title;
      case StoryPart.ClientName:
        return this.clientName;
      case StoryPart.Value:
        return `${this.value}`;
      case StoryPart.Summary:
        return this.summary;
      case StoryPart.Background:
        return this.background;
      case StoryPart.Problem:
        return this.problem;
      case StoryPart.Reason:
        return this.reason;
      case StoryPart.Impact:
        return this.impact;
      case StoryPart.Solution:
        return this.solution;
      case StoryPart.EngagementFeedback:
        return this.engagementFeedback;
      case StoryPart.BenefitsRealisation:
        return this.benefitsRealisation;
      case StoryPart.Approach:
        return this.approach;
      case StoryPart.SharingConditions:
        return this.sharingConditions;
    }
  }

  hasValues(phase: StoryPhase): boolean {
    const parts = this.phaseMap.filter((p) => p.phase === phase)[0].parts;
    const values = parts.map((p) => this.getStoryPartValue(p));
    const hasValues = values.filter((v) => v);
    return hasValues.length > 0;
  }

  getStoryPartValidationMessage(storyPart: StoryPart): string | undefined {
    const value = this.getStoryPartValue(storyPart);
    const errorMessage = (phase: StoryPhase, part: StoryPart) =>
      `Story must have a value for ${storyPartDescriptor[part].Label} when at ${storyPhaseDescriptor[phase].Label} phase`;
    return (!value || !/\S+/.test(value)) && this.phase >= this.validationRules.parts.get(storyPart)
      ? errorMessage(this.phase, storyPart)
      : undefined;
  }

  public partsAreValid(parts?: StoryPart[]): boolean {
    const toBeCheckedParts = parts ? parts : storyPartDescriptor.Keys;
    return toBeCheckedParts.filter((part) => part).every((part) => !this.getStoryPartValidationMessage(part));
  }

  get errorMessages(): string[] {
    return (this.hasFeaturedImage ? [] : ['Story image is required'])
      .concat(storyPartDescriptor.Keys.map((p) => this.getStoryPartValidationMessage(p)))
      .concat(this.tagErrors)
      .filter((err) => err);
  }

  get tagErrors(): string[] {
    const storyTagsGroupedByCategory = this.tags.reduce((m, tagsWithCategory) => {
      if (!m.has(tagsWithCategory.category)) {
        m.set(tagsWithCategory.category, []);
      }
      m.get(tagsWithCategory.category).push(tagsWithCategory);
      return m;
    }, new Map<string, TagWithCategory[]>());
    const requiredTags = this.validationRules.tags.get(this.phase) || [];
    const errors = [...requiredTags].reduce(
      (errorMessages, [tagCategory, minCount]) => {
        if (
          !storyTagsGroupedByCategory.has(tagCategory) ||
          storyTagsGroupedByCategory.get(tagCategory).length < minCount
        ) {
          errorMessages.push(`Should have at least ${minCount} tag${minCount > 1 ? 's' : ''} of type ${tagCategory}`);
        }
        return errorMessages;
      },
      ['']
    );
    return errors;
  }

  get tagErrorMessages(): string {
    return this.tagErrors.slice(1).join('\n').trim();
  }

  get isValid(): boolean {
    return this.partsAreValid() && this.hasFeaturedImage && !this.tagErrorMessages;
  }

  public clone(): Story {
    const s = new Story();
    Object.assign(s, this);
    return s;
  }

  get hasAttachmentsOrEmbeddedLinks() {
    return (
      (this.embeddedLinks && this.embeddedLinks.length > 0) ||
      this.attachments.map((a) => attachmentTypeDescriptor[a.attachmentType]).filter((b) => b.Metadata).length > 0
    );
  }

  get isCategoryNomination() {
    return (
      this.caseStudyNomination >= StoryCaseStudyNomination.Category1 &&
      this.caseStudyNomination <= StoryCaseStudyNomination.Category3
    );
  }

  get threeMonthsAfterReleaseToProduction() {
    return this.goneToProductionDate && moment().diff(moment(this.goneToProductionDate), 'days') >= 90;
  }
}

@Injectable({ providedIn: 'root' })
export class StoryMapper implements IMapper<IStoryDto, Observable<Story>> {
  constructor(
    private userMapper: UserMapper,
    private revisionCountMapper: RevisionCountMapper,
    private tagMapper: TagMapper,
    private attachmentMapper: AttachmentMapper,
    private embeddedLinksMapper: EmbeddedLinkMapper,
    private tagService: TagService
  ) {}

  map(item: IStoryDto, fetchTags = true): Observable<Story> {
    return (fetchTags ? this.tagService.getAllTags() : of([])).pipe(
      map((tagsWithCategories) => {
        const storyTags = item.tags.map(
          (i) =>
            tagsWithCategories.find((tagWithCategory) => tagWithCategory.value === i.value) ||
            ({ value: i.value } as TagWithCategory)
        );
        const result = new Story();

        result.id = item.id;
        result.title = item.title;
        result.clientName = item.clientName;
        result.summary = item.summary;
        result.background = item.background;
        result.problem = item.problem;
        result.reason = item.reason;
        result.impact = item.impact;
        result.solution = item.solution;
        result.engagementFeedback = item.engagementFeedback;
        result.benefitsRealisation = item.benefitsRealisation;
        result.approach = item.approach;
        result.value = item.value;
        result.createdBy = this.userMapper.map(item.createdBy);
        result.phase = item.phase;
        result.contributors = item.contributors && item.contributors.map(this.userMapper.map);
        result.lastEdited = new Date(item.lastEdited);
        result.lastEditedBy = item.lastEditedBy && this.userMapper.map(item.lastEditedBy);
        result.state = item.state || StoryState.Draft;
        result.visibility = item.visibility || StoryVisibility.InternalStory;
        result.owner = this.userMapper.map(item.owner);
        result.revisionsCount = item.revisionsCount && item.revisionsCount.map(this.revisionCountMapper.map);
        result.tags = storyTags;
        result.attachments = item.attachments && item.attachments.map(this.attachmentMapper.map);
        result.hasFeaturedImage = item.hasFeaturedImage;
        result.numberOfLikes = item.numberOfLikes;
        result.hasCurrentUserLiked = item.hasCurrentUserLiked;
        result.saleClosedDate = item.saleClosedDate ? new Date(item.saleClosedDate) : null;
        result.deliveryStartDate = item.deliveryStartDate ? new Date(item.deliveryStartDate) : null;
        result.deliveryCompletedDate = item.deliveryCompletedDate ? new Date(item.deliveryCompletedDate) : null;
        result.goneToProductionDate = item.goneToProductionDate ? new Date(item.goneToProductionDate) : null;
        result.caseStudyNomination = item.caseStudyNomination;
        result.caseStudyNominationNotes = item.caseStudyNominationNotes;
        result.caseStudyNominatedBy = this.userMapper.map(item.caseStudyNominatedBy);
        result.caseStudyNominatedAt = item.caseStudyNominatedAt ? new Date(item.caseStudyNominatedAt) : null;
        result.sharingConditions = item.sharingConditions;
        result.embeddedLinks = item.embeddedLinks && item.embeddedLinks.map(this.embeddedLinksMapper.map);

        return result;
      })
    );
  }
}
