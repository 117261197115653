import { HttpParams } from '@angular/common/http';
import { Story } from 'src/app/shared//models/story';
import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StoriesService } from '../../services/stories.service';
import { UserService } from '../../../shared/services/user/user.service';
import { User } from '../../../shared/models/user';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EditStoryTitleDialogComponent } from '../edit-story-title/edit-story-title-dialog.component';
import { ViewStoryPartComponent } from '../view-story-part/view-story-part.component';
import {
  ChangeRevisionDialogComponent,
  ChangeRevisionDialogData,
} from '../change-revision/change-revision-dialog.component';
import { Revision } from '../../../shared/models/revision';
import { StoryPart, storyPartDescriptor } from '../../../shared/models/story-part';
import { StoryPhase } from '../../../shared/models/story-phase';
import { FileUploadService } from '../../services/file-upload.service';
import { FeaturedImageService } from '../../../shared/services/featured-images/featured-image.service';
import { ClientMessageService } from '../../../shared/services/client-message.service';
import { StoryLike } from '../../../shared/models/story-like';
import { ViewLikesDialogComponent, ViewLikesDialogComponentData } from '../view-likes/view-likes-dialog.component';
import { finalize } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { TemporaryStateService } from '../../services/temporary-state.service';
import { searchTextSliceLimit } from '../../../shared/constants';
import { RouterExtService } from 'src/app/shared/services/router-ext-service/router-ext.service';
import { StoryStrengthComponent } from '../story-strength/story-strength.component';
import { StoryState } from 'src/app/shared/models/story-state';
import { StoryVisibility } from 'src/app/shared/models/story-visibility';
import { UpdateStoryTitle } from 'src/app/shared/models/update-story-title';
import { DeleteStoryDialogComponent } from '../delete-story-dialog/delete-story-dialog.component';

const editStoryTitleDialogHeight = '420px';
const editStoryTitleDialogWidth = '600px';
const deleteDialogHeight = '300px';
const deleteDialogWidth = '650px';

@Component({
  selector: 'app-view-story',
  templateUrl: './view-story.component.html',
  styleUrls: ['./view-story.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewStoryComponent implements OnInit {
  salePoint = 0;
  deliveryPoint = 0;
  measurementPoint = 0;
  loading = true;
  likeSpinner = false;
  featureImageLoadedAtLeastOnce = false;
  story: Story;
  contextUser: User;
  contextUserIsStoryOwner = false;
  contextUserIsContributor = false;
  allowSwitchReadOnly = false;
  isInReadMode = true;
  searchText = '';
  navigateBack = false;
  searchTextSliceLimit = searchTextSliceLimit;
  @Input()
  isInstigatedViaViewingStory: boolean;

  @ViewChild('file') imageFileControl;
  readonly storyPartDescriptor = storyPartDescriptor;
  readonly StoryPhase = StoryPhase;
  readonly StoryPart = StoryPart;

  defaultImage = '../../../../assets/default-story-photo.PNG';

  featuredImage: ArrayBuffer;
  get featuredImageUrl(): string {
    return `url(${this.featuredImage})`;
  }

  imageIsLoading: boolean;
  imageToUpload: File;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private routerService: RouterExtService,
    private dialog: MatDialog,
    private featureImageService: FeaturedImageService,
    private fileUploadService: FileUploadService,
    private storiesService: StoriesService,
    private userService: UserService,
    private clientMessageService: ClientMessageService,
    private titleService: Title,
    private temporaryStateService: TemporaryStateService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogModel: { id: string }
  ) {}

  ngOnInit() {
    this.loading = true;
    this.route.url.subscribe((url) => {
      if (url[0].path === 'view-story') {
        this.isInstigatedViaViewingStory = true;
        this.isInReadMode = true;
      } else if (url[0].path === 'story') {
        this.isInstigatedViaViewingStory = false;
        this.isInReadMode = false;
      }
    });

    if (this.dialogModel) {
      this.fetchStoryFromApi(this.dialogModel.id);
    } else {
      this.route.params.subscribe((params) => {
        if (params['id']) {
          this.fetchStoryFromApi(params['id']);
        }
      });
      this.route.queryParams.subscribe((params) => {
        const term = params['term'];
        if (term) {
          this.searchText = term;
        }
      });
    }
    this.navigateBack = this.routerService.getPreviousUrl() !== this.routerService.getCurrentUrl();
  }

  // This method gets executed when a new story is fetched from the api
  private storyViewModelUpdated(storyResult: Story) {
    this.story = storyResult;
    this.userService.getUser().subscribe((user: User) => {
      this.contextUser = user;
      this.contextUserIsStoryOwner = this.contextUser.id === this.story.owner.id || this.contextUser.isAdmin;
      this.contextUserIsContributor = this.story.contributors.findIndex((u) => u.id === this.contextUser.id) >= 0;
      // contributor/owner and admin can switch editing mode if it is a read only route
      this.allowSwitchReadOnly =
        (this.contextUserIsStoryOwner || this.contextUserIsContributor) && this.isInstigatedViaViewingStory;
    });

    if (storyResult.hasFeaturedImage && !this.featureImageLoadedAtLeastOnce) {
      this.populateFeaturedImage(storyResult.id);
    }

    const title = `${this.story.title} - ${this.story.clientName} - StoryHub`;
    this.titleService.setTitle(title);
  }

  fetchStoryFromApi(storyId: string): void {
    this.storiesService.getStoryById(storyId, this.isInstigatedViaViewingStory).subscribe(
      (result) => {
        if (result) {
          this.temporaryStateService.restorePhaseInfo(result);
          this.storyViewModelUpdated(result as Story);
          this.loading = false;
        }
      },
      (error) => {
        // if a non-contributor/owner user is trying to view editor's url,
        // redirect them to the view only route
        if (error.status === 401) {
          this.router.navigate(['/view-story', storyId]);
        }
      }
    );
  }

  editStoryTitle(): void {
    const editStoryTitleDialogRef = this.dialog.open(EditStoryTitleDialogComponent, {
      width: editStoryTitleDialogWidth,
      height: editStoryTitleDialogHeight,
      data: {
        id: this.story.id,
        title: this.story.title,
        clientName: this.story.clientName,
        value: this.story.value,
      } as UpdateStoryTitle,
    });

    editStoryTitleDialogRef.afterClosed().subscribe(() => {
      this.fetchStoryFromApi(this.story.id);
    });
  }

  openLikesDialog(storyId: string): void {
    this.dialog.open(ViewLikesDialogComponent, {
      height: '550px',
      width: '400px',
      data: {
        storyId: storyId,
      } as ViewLikesDialogComponentData,
    });
  }

  changeLike(storyId: string, hasCurrentUserLiked: boolean) {
    this.likeSpinner = true;
    const likeObj: StoryLike = { storyId: storyId, likeIntention: hasCurrentUserLiked };
    this.storiesService
      .updateStoryLike(likeObj)
      .pipe(finalize(() => (this.likeSpinner = false)))
      .subscribe(() => {
        this.story.hasCurrentUserLiked = !this.story.hasCurrentUserLiked;
        this.story.hasCurrentUserLiked ? (this.story.numberOfLikes += 1) : (this.story.numberOfLikes -= 1);
      });
  }

  copyLink(): void {
    const client = this.story.clientName;
    const storyTitle = this.story.title;

    function listener(e: any) {
      const pasteText = client + ' - ' + storyTitle + ' [StoryHub]';
      e.clipboardData.setData('text/html', "<a href='" + window.location.href + "'>" + pasteText + '</a>');
      e.clipboardData.setData('text/plain', window.location.href);
      e.preventDefault();
    }

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);

    this.clientMessageService.showClientInfoMessage(
      `URL for this story has been copied to the clipboard. ${this.shouldShowToolTip ? this.toolTipText : ''}`
    );
  }

  imageClick() {
    this.imageFileControl.nativeElement.click();
  }

  onFeatureImageAdded(): void {
    const files: { [key: string]: File } = this.imageFileControl.nativeElement.files;
    this.imageIsLoading = true;
    this.imageToUpload = files[0];

    this.fileUploadService.uploadFeaturedImage(this.imageToUpload, this.story.id).subscribe(
      () => {
        this.populateFeaturedImage(this.story.id);
        this.fetchStoryFromApi(this.story.id);
        this.clientMessageService.showClientInfoMessage('Successfully uploaded feature image.');
      },
      (error: any) => {
        if (error.name === 'filesize' || error.status === 413) {
          this.clientMessageService.showClientErrorMessageCustom(
            'Image is too large. The featured image upload limit is 6 MB',
            'Dismiss',
            6000
          );
        } else if (error.status === 415) {
          this.clientMessageService.showClientErrorMessageCustom(
            'Incorrect file format. The supported file types are .png and .jpg',
            'Dismiss',
            6000
          );
        } else if (error.status === 428) {
          this.clientMessageService.showClientErrorMessageCustom(
            'Invalid image dimensions. Minimum supported image dimensions are 548x316',
            'Dismiss',
            6000
          );
        }
        this.imageIsLoading = false;
      }
    );
  }

  populateFeaturedImage(storyId: string) {
    this.imageIsLoading = true;
    this.featureImageService.getFeaturedImageForStory(storyId).subscribe(
      (blobResult) => {
        this.createImageFromBlob(blobResult);
        this.featureImageLoadedAtLeastOnce = true;
        this.imageIsLoading = false;
      },
      () => {
        this.imageIsLoading = false;
      }
    );
  }

  createImageFromBlob(featuredImage: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.featuredImage = reader.result as ArrayBuffer;
      },
      false
    );
    if (featuredImage) {
      reader.readAsDataURL(featuredImage);
    }
  }

  onSwitchReadOnly() {
    this.isInReadMode = !this.isInReadMode;
  }

  get canEdit() {
    return (this.contextUserIsStoryOwner || this.contextUserIsContributor) && !this.isInReadMode;
  }

  showDelete(): boolean {
    if (
      this.contextUser &&
      this.story &&
      ((this.story.owner && this.contextUser.id === this.story.owner.id) || this.contextUser.isAdmin)
    ) {
      return true;
    }
    return false;
  }

  canDelete(): boolean {
    return this.story.state === StoryState.Draft;
  }

  onStoryDelete() {
    const deleteStoryDialogRef = this.dialog.open(DeleteStoryDialogComponent, {
      height: deleteDialogHeight,
      width: deleteDialogWidth,
    });
    deleteStoryDialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.storiesService.deleteStoryById(this.story.id).subscribe(() => {
          this.storiesService.deleteStory();
          this.router.navigate(['/']);
          this.clientMessageService.showClientInfoMessage('Draft story deleted successfully.');
        });
      }
    });
  }

  getBackButtonText(tooltip) {
    if (this.searchText === '') return 'Back';

    const isLongText = tooltip ? false : this.searchText.length > searchTextSliceLimit;
    return (
      'Back To "' +
      (isLongText ? this.searchText.slice(0, searchTextSliceLimit) + '...' : this.searchText) +
      '" Search Results'
    );
  }

  backClicked() {
    this.location.back();
  }

  get shouldShowToolTip() {
    return this.story.visibility === StoryVisibility.InternalStory || this.story.sharingConditions;
  }

  get toolTipText() {
    return this.story.visibility === StoryVisibility.InternalStory && !this.story.sharingConditions
      ? 'Only share internally.'
      : 'Please refer to the sharing conditions below.';
  }
}
