import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ReleaseNote } from 'src/app/shared/models/release-note';
import { ReleaseNoteEditorComponent } from '../release-note-editor/release-note-editor.component';
import { ReleaseNotesService } from '../../services/release-note.service';
import { ClientMessageService } from 'src/app/shared/services/client-message.service';

@Component({
  templateUrl: 'edit-release-notes.dialog.html',
})
export class EditReleaseNotesDialogComponent {
  public existingReleaseNote: ReleaseNote | null;

  constructor(
    private releaseNotesService: ReleaseNotesService,
    private messageService: ClientMessageService,
    public dialogRef: MatDialogRef<ReleaseNoteEditorComponent>,
    @Inject(MAT_DIALOG_DATA) data?
  ) {
    this.existingReleaseNote = data.releaseNote;
  }

  onSubmit(releaseNote: ReleaseNote) {
    this.releaseNotesService.updateReleaseNote(releaseNote).subscribe(() => {
      this.messageService.showClientInfoMessage('The release note has been successfully updated');
      this.dialogRef.close(releaseNote);
    });
  }

  cancel(isDirty: boolean) {
    if (isDirty) {
      if (confirm('You have unsaved changes. Are you sure?')) {
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }
}
