import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LandingUrlKey } from '../login/login.component';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit {
  landingUrl: string;

  constructor(private router: Router) {}

  ngOnInit() {
    this.landingUrl = localStorage.getItem(LandingUrlKey);

    if (this.landingUrl) {
      localStorage.removeItem(LandingUrlKey);
      window.location.href = this.landingUrl;
    } else {
      localStorage.removeItem(LandingUrlKey);
      this.router.navigate(['/']);
    }
  }
}
