import { Story } from '../../../../shared/models/story';
import { StoryCaseStudyNomination, storyCaseStudyNominationDescriptor } from '../../../../shared/models/story-case-study-nomination';
import { ClientMessageService } from '../../../../shared/services/client-message.service';
import { StoriesService } from '../../../services/stories.service';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-case-study-nomination-edit-mode',
  templateUrl: './case-study-nomination-edit-mode.component.html',
  styleUrls: ['./case-study-nomination-edit-mode.component.scss'],
})
export class CaseStudyNominationEditModeComponent implements OnChanges {
  public readonly caseStudyNominationDescriptor = storyCaseStudyNominationDescriptor;
  @Output()
  public readonly switchToView = new EventEmitter();

  public caseStudyNominated: boolean;
  public caseStudyNomination: StoryCaseStudyNomination;
  public caseStudyNominationNotes: string;
  @Input()
  public story: Story;
  @Output()
  public storyUpdated = new EventEmitter();

  constructor(private storiesService: StoriesService, private messageService: ClientMessageService) {}

  public get saveDisabled() {
    return (
      !this.story ||
      (this.story.caseStudyNomination === this.caseStudyNomination &&
        this.story.caseStudyNominationNotes === this.caseStudyNominationNotes)
    );
  }

  public cancel() {
    this.switchToView.emit();

    this.caseStudyNomination = this.story.caseStudyNomination;
    this.caseStudyNominationNotes = this.story.caseStudyNominationNotes;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.story) {
      this.caseStudyNominated = this.story.caseStudyNomination != null;
      this.caseStudyNomination = this.story.caseStudyNomination || null;
      this.caseStudyNominationNotes = this.story.caseStudyNominationNotes || null;
    }
  }

  public ngOnInit(): void {}

  public onCaseStudyNominatedChanged(checked: boolean) {
    if (!checked) {
      this.caseStudyNomination = null;
      this.caseStudyNominationNotes = null;
    }
  }

  public async save(): Promise<any> {
    try {
      await this.storiesService.updateStoryCaseStudyNomination(
        this.story.id,
        this.caseStudyNomination,
        this.caseStudyNominationNotes
      );

      this.switchToView.emit();
      this.storyUpdated.emit();
    } catch (error) {
      if (error.status === 428 && error.error && error.error.detail) {
        this.messageService.showClientErrorMessage(
          'Received error saving the story case study nomination. ' + error.error.detail
        );
      } else {
        this.messageService.showClientErrorMessage('Received error saving the story case study nomination');
      }
    }
  }
}
