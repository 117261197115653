import { Injectable } from '@angular/core';
import { Story } from '../../shared/models/story';

@Injectable({
  providedIn: 'root',
})
export class TemporaryStateService {
  private _story: Story;

  get story() {
    return this._story;
  }

  set story(value: Story) {
    this._story = value;
  }
  constructor() {}

  public reset() {
    this.story = undefined;
  }
  public restorePhaseInfo(storyToBeStored: Story) {
    if (this.story) {
      storyToBeStored.phase = this.story.phase;
      storyToBeStored.saleClosedDate = this.story.saleClosedDate;
      storyToBeStored.deliveryStartDate = this.story.deliveryStartDate;
      storyToBeStored.deliveryCompletedDate = this.story.deliveryCompletedDate;
    }
  }
}
