import { Component, Output, EventEmitter, Input } from '@angular/core';
import { StorySearchClient } from 'src/app/shared/models/story-search-client';

export const CLIENTS_GROUP_LABEL = 'ClientName';

export interface FilterViewModel {
  groupLabel: string;
  label: string;
}

@Component({
  selector: 'app-story-clients-selection',
  templateUrl: './story-clients-selection.component.html',
  styleUrls: ['./story-clients-selection.component.scss'],
})
export class StoryClientsSelectionComponent {
  @Input() clients: StorySearchClient[];
  @Input() selectedClients: StorySearchClient[] = [];
  @Output() clientSelectionChanged = new EventEmitter<{ client: StorySearchClient; isChecked }>();

  itemChanged(client: StorySearchClient, isChecked: boolean) {
    this.clientSelectionChanged.emit({ client, isChecked });
  }

  isChecked(client: StorySearchClient) {
    return this.selectedClients.some((c) => c.name.toLowerCase() === client.name.toLowerCase());
  }
}
