import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NewStoryDialogComponent } from '../new-story/new-story-dialog.component';

const newStoryDialogHeight = '500px';
const newStoryDialogWidth = '600px';

@Component({
  selector: 'app-create-story-button',
  templateUrl: './create-story-button.component.html',
})
export class CreateStoryButtonComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  openNewStory(): void {
    const newComponentDialogRef = this.dialog.open(NewStoryDialogComponent, {
      width: newStoryDialogWidth,
      height: newStoryDialogHeight,
    });
  }
}
