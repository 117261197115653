import { Story } from '../../../shared/models/story';
import { StoryPhase, storyPhaseDescriptor } from '../../../shared/models/story-phase';
import { StoryState, storyStateDescriptor } from '../../../shared/models/story-state';
import { StoryVisibility, storyVisibilityDescriptor } from '../../../shared/models/story-visibility';
import { User } from '../../../shared/models/user';
import { UserService } from '../../../shared/services/user/user.service';
import { UploadMode } from '../file-upload/file-upload.component';
import { StoryTagComponent } from './../story-tag/story-tag.component';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Tag } from 'src/app/shared/models/tag';

@Component({
  selector: 'app-story-meta',
  templateUrl: './story-meta.component.html',
  styleUrls: ['./story-meta.component.scss'],
})
export class StoryMetaComponent implements OnInit, OnChanges {
  @Input() story: Story;
  @Input() isReadOnly: boolean;
  @Output() storyUpdated = new EventEmitter();

  @ViewChild('storyTags', { static: true }) storyTagComponent: StoryTagComponent;

  constructor(private userService: UserService) {}

  currentUser: User;
  currentUserIsStoryOwner: boolean;

  caseStudyNominationEditMode = false;
  currentUserCanEditVisibility = false;
  contributorsEditMode = false;
  ownerEditMode = false;
  tagsEditMode = false;
  phaseEditMode = false;
  stateEditMode = false;
  visibilityEditMode = false;
  sharingConditionsEditMode = false;

  storyStateDescriptor = storyStateDescriptor;
  storyPhaseDescriptor = storyPhaseDescriptor;
  storyVisibilityDescriptor = storyVisibilityDescriptor;

  tags: Tag[] = [];

  readonly StoryPhase = StoryPhase;
  readonly UploadMode = UploadMode;
  readonly StoryState = StoryState;

  get tagsErrorMessages(): string | undefined {
    return this.story.tagErrorMessages && this.currentUserIsStoryOwner ? this.story.tagErrorMessages : undefined;
  }

  visibilityInfoMessages(visibility: StoryVisibility): string {
    switch(visibility){
      case StoryVisibility.InternalStory:{
        return "Do not share with customers"
      }
      case StoryVisibility.PrivateStory:{
        return "You can share privately with customers (per any noted sharing conditions)"
      }
      case StoryVisibility.PublicStory:{
        return "You can post about this publicly on our websites, socials etc (per any noted sharing conditions)"
      }
      case StoryVisibility.CaseStudy:{
        return "This story has a published document / video that you can post publicly on our websites, socials etc (per any noted sharing conditions)"
      }
    }
  }

  ngOnInit() {
    this.bindStoryModel(this.story);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['story']) {
      this.bindStoryModel(this.story);
    }
  }

  refreshStory() {
    this.storyUpdated.emit();
  }

  bindStoryModel(story: Story) {
    this.story = story;

    this.tags = this.story && this.story.tags.sort((a, b) => a.value.localeCompare(b.value));

    this.userService.getUser().subscribe((user: User) => {
      this.currentUser = user;
      this.currentUserIsStoryOwner = this.currentUser.id === this.story.owner.id || this.currentUser.isAdmin;
      this.currentUserCanEditVisibility =
        this.currentUserIsStoryOwner &&
        (this.story.phase === StoryPhase.Measure || this.story.phase === StoryPhase.Completed);
    });
  }

  manageOwner() {
    this.ownerEditMode = true;
  }

  cancelOwner() {
    this.ownerEditMode = false;
  }
}
