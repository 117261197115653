import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Story } from 'src/app/shared/models/story';
import { Attachment } from 'src/app/shared/models/attachment';
import { StoryState } from 'src/app/shared/models/story-state';
import { AttachmentType } from 'src/app/shared/models/attachment-type';

export class DeleteAttachmentDialogData {
  story: Story;
  attachment: Attachment;
}

export enum DeleteAttachmentDialogResult {
  Proceed,
  Cancel,
}

@Component({
  selector: 'app-delete-attachment-dialog',
  templateUrl: './delete-attachment-dialog.component.html',
  styleUrls: ['./delete-attachment-dialog.component.scss'],
})
export class DeleteAttachmentDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: DeleteAttachmentDialogData,
    private dialogRef: MatDialogRef<DeleteAttachmentDialogComponent>
  ) {}

  ngOnInit() {}

  showPublishWarning() {
    return this.dialogData.story.state !== StoryState.Draft;
  }

  showConsentWarning() {
    return this.dialogData.attachment.attachmentType === AttachmentType.PublishConsent;
  }

  onCancelClicked(): void {
    this.dialogRef.close(DeleteAttachmentDialogResult.Cancel);
  }

  onProceedClicked(): void {
    this.dialogRef.close(DeleteAttachmentDialogResult.Proceed);
  }
}
