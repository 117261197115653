<div class="container" *ngIf="all">
  <div class="header">
    <h3 mat-dialog-title>{{ dialogData.title }}</h3>
    <button mat-button class="releaseNoteDialogButton" (click)="onCancel()"><mat-icon>clear</mat-icon></button>
  </div>
  <ul mat-dialog-content class="selector">
    <li class="current-version">
      <div class="column-title">Current version</div>
      <div class="column-author revision-author">
        <div class="author-initials">{{ current.createdBy.initials }}</div>
        <div class="author-text">
          {{ current.createdBy.fullName }}<br />@{{ current.dateCreated | date: DATE_TIME_FORMAT }}
          <span *ngIf="current.restoredFrom" class="restored-from"
            ><br />Restored from: {{ current.restoredFrom.dateCreated | date: DATE_TIME_FORMAT }}</span
          >
        </div>
      </div>
      <div class="column-content">{{ current.value }}</div>
    </li>
    <li class="previous-version">
      <div class="column-title">Previous version</div>
      <div class="column-author revision-author previous-version">
        <div class="author-initials">{{ selected.createdBy.initials }}</div>
        <div class="author-text">
          {{ selected.createdBy.fullName }}<br />@{{ selected.dateCreated | date: DATE_TIME_FORMAT }}
        </div>
      </div>
      <div class="column-content">{{ selected.value }}</div>
      <div class="column-footer">
        <button cdkFocusInitial mat-flat-button (click)="revertToRevision(selected)">RESTORE THIS VERSION</button>
      </div>
    </li>
    <li class="all-versions">
      <div class="column-title">All revisions</div>
      <ul class="all-revisions">
        <li
          *ngFor="let revision of all"
          (click)="selectRevision(revision)"
          [ngClass]="{ selected: revision == selected }"
        >
          <div class="revision-author previous-version">
            <div class="author-initials">{{ revision.createdBy.initials }}</div>
            <div class="author-text">
              {{ revision.createdBy.fullName }}<br />@{{ revision.dateCreated | date: DATE_TIME_FORMAT }}
            </div>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>
