import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Story } from '../../../../shared/models/story';
import { StoryPhase, storyPhaseDescriptor } from '../../../../shared/models/story-phase';
import moment from 'moment';
import { Moment } from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { UpdateStoryPhase } from '../../../../shared/models/update-story-phase.model';
import { StoriesService } from '../../../services/stories.service';
import { ClientMessageService } from '../../../../shared/services/client-message.service';
import { StoryState } from '../../../../shared/models/story-state';
import { StoryVisibility } from 'src/app/shared/models/story-visibility';
import { TemporaryStateService } from '../../../services/temporary-state.service';

@Component({
  selector: 'app-phase-edit-mode',
  templateUrl: './phase-edit-mode.component.html',
  styleUrls: ['./phase-edit-mode.component.scss'],
})
export class PhaseEditModeComponent implements OnInit {
  @Input()
  story: Story;

  @Output()
  switchToView = new EventEmitter();

  @Output()
  storyUpdated = new EventEmitter<Story>();

  readonly storyPhaseDescriptor = storyPhaseDescriptor;

  selectedStoryPhase: StoryPhase;
  selectedSaleClosedDate: Moment;
  selectedDeliveryStartDate: Moment;
  selectedDeliveryCompletedDate: Moment;
  selectedGoneToProductionDate: Moment;

  constructor(
    private storiesService: StoriesService,
    private messageService: ClientMessageService,
    private temporaryStateService: TemporaryStateService
  ) {}

  ngOnInit(): void {
    this.selectedStoryPhase = this.story.phase;
    this.selectedDeliveryCompletedDate = this.story.deliveryCompletedDate
      ? moment(this.story.deliveryCompletedDate)
      : undefined;
    this.selectedSaleClosedDate = this.story.saleClosedDate ? moment(this.story.saleClosedDate) : undefined;
    this.selectedDeliveryStartDate = this.story.deliveryStartDate ? moment(this.story.deliveryStartDate) : undefined;
    this.selectedGoneToProductionDate = this.story.goneToProductionDate
      ? moment(this.story.goneToProductionDate)
      : undefined;
  }

  get deliveryStarted(): boolean {
    return this.selectedStoryPhase >= StoryPhase.Deliver;
  }

  get deliveryCompleted(): boolean {
    return this.selectedStoryPhase >= StoryPhase.Measure;
  }

  get saveDisabled() {
    if (this.showValidationWarningMsg) {
      return true;
    }
    if (this.deliveryCompleted) {
      return !this.selectedSaleClosedDate || !this.selectedDeliveryStartDate || !this.selectedDeliveryCompletedDate;
    }
    if (this.deliveryStarted) {
      return !this.selectedSaleClosedDate || !this.selectedDeliveryStartDate;
    }
    return false;
  }

  get showVisibilityWarningMsg(): boolean {
    return (
      this.story.visibility !== StoryVisibility.InternalStory &&
      (this.selectedStoryPhase === StoryPhase.Sale || this.selectedStoryPhase === StoryPhase.Deliver)
    );
  }

  get showValidationWarningMsg(): boolean {
    return this.story.state === StoryState.Published && !this.cloneAndModifyStory().isValid;
  }

  cloneAndModifyStory(): Story {
    const clonedStory = this.story.clone();

    clonedStory.phase = this.selectedStoryPhase;
    clonedStory.saleClosedDate = this.selectedSaleClosedDate ? this.selectedSaleClosedDate.toDate() : undefined;
    clonedStory.deliveryStartDate = this.selectedDeliveryStartDate
      ? this.selectedDeliveryStartDate.toDate()
      : undefined;
    clonedStory.deliveryCompletedDate = this.selectedDeliveryStartDate
      ? this.selectedDeliveryStartDate.toDate()
      : undefined;
    clonedStory.goneToProductionDate = this.selectedGoneToProductionDate
      ? this.selectedGoneToProductionDate.toDate()
      : undefined;

    return clonedStory;
  }

  saleClosedDateChanged($event: MatDatepickerInputEvent<any>) {
    this.selectedSaleClosedDate = $event.value;
  }

  deliveryStartDateChanged($event: MatDatepickerInputEvent<any>) {
    this.selectedDeliveryStartDate = $event.value;
  }

  deliveryCompletedDateChanged($event: MatDatepickerInputEvent<any>) {
    this.selectedDeliveryCompletedDate = $event.value;
  }

  goneToProductionDateChanged($event: MatDatepickerInputEvent<any>) {
    this.selectedGoneToProductionDate = $event.value;
  }

  updatePhase(): void {
    const updateStoryPhase: UpdateStoryPhase = {
      deliveryCompletedDate: this.selectedDeliveryCompletedDate,
      deliveryStartDate: this.selectedDeliveryStartDate,
      id: this.story.id,
      phase: this.selectedStoryPhase,
      saleClosedDate: this.selectedSaleClosedDate,
      goneToProductionDate: this.selectedGoneToProductionDate,
    };
    this.storiesService.updateStoryPhaseField(updateStoryPhase).subscribe(
      () => {
        if (this.showVisibilityWarningMsg) {
          this.messageService.showClientInfoMessage('Story visibility has been reverted back to Internal');
        } else if (
          this.selectedStoryPhase === StoryPhase.Completed &&
          this.story.visibility === StoryVisibility.InternalStory
        ) {
          this.messageService.showClientInfoMessageCustom(
            "Congratulations on completing the story! Please consider taking action to change this story's visibility to allow us to share the story more broadly as part of helping grow the usefulness of StoryHub.",
            'OK',
            30000
          );
        }
        this.temporaryStateService.reset();
        this.storyUpdated.emit();
        this.switchToView.emit();
      },
      (error) => {
        if (error.status === 428 && error.error && error.error.detail) {
          this.messageService.showClientErrorMessage('Received error saving the story state. ' + error.error.detail);
        } else {
          this.messageService.showClientErrorMessage('Received error saving the story state');
        }
      }
    );
  }

  onSelectionChange() {
    this.temporaryStateService.story = this.cloneAndModifyStory();
    this.storyUpdated.emit();
  }

  cancel() {
    this.switchToView.emit();
    this.storyUpdated.emit();
    this.temporaryStateService.reset();
  }
}
